
import { useContext, useEffect, useState } from "react";
import SubjectCard from "../../../components/Home/SubjectCard/SubjectCard";
import DataContext from "../../../context/DataContext";
import SearchContext from "../../../context/SearchContext/SearchConext";
import { useNavigate } from "react-router-dom";



const Subjects = () => {

    const navigate = useNavigate();

    const {levels, settings} = useContext(DataContext);

    const {grade, setGrade, subject, setSubject} = useContext(SearchContext);

    const [activeLevel, setActiveLevel] = useState({subjects: []});

    useEffect(() => {
        if(grade.id !== undefined){
            setActiveLevel(grade);
        }
        else if(levels[0] !== undefined){
            setActiveLevel(levels[0]);
            setGrade(levels[0]);
        }
    }, [levels, grade, setGrade]);


    const changeActiveLevel = (level) => {
        setActiveLevel(level);
        setGrade(level);
        if(window.innerWidth < 768){ // MOBILE ONLY
        //    document.getElementById("selectGrade").scrollIntoView({behavior: "smooth"});
        }
    }

    const selectSubject = (item) => {
        setSubject(item);
        navigate(`/search?grade=${grade.slug}&subject=${item.slug}`);
    }  

    if(levels[1] !== undefined){
        return(
            <div className="sm:mt-8">
                <div className="mb-4 mt-10 md:mt-0 flex flex-col">
                    <div id="selectGrade" className="front_level_container flex flex-nowrap overflow-x-auto">
                        {levels.map((level, index) => {
                            return(
                                <button key = {index} onClick = {() => changeActiveLevel(level)} 
                                    className={`front_level mb-6 sm:mb-4 home_subject border-b-2 border-[#f5f7ff] text-[#0C1135] text-[14px] font-[500] tracking-[0.05em] leading-4 mr-4 xl:mr-6 whitespace-nowrap pb-1.5 ${activeLevel.id === level.id ? 'active' : ''}`} >
                                    {index ? level.name:level.name.split(" ")[0]}
                                </button>
                            )
                        })}
                        {settings.homeTabs && settings.homeTabs.length && settings.homeTabs.map((tab, index) => {
                            return (
                                <button key = {index} className={`mb-6 sm:mb-4 home_subject border-b-2 border-[#f5f7ff] text-[#0C1135] text-[14px] font-[500] tracking-[0.05em] whitespace-nowrap leading-4 mr-4 xl:mr-6 pb-1.5`}
                                        onClick = {() => navigate(`/${tab.endpoint}`)}>
                                    {tab.label}
                                </button>
                            )
                        })}
                        
                    </div>

                    <div  className="flex w-full justify-center mt-4">
                        <div className = 'flex overflow-x-auto pb-4 lg:w-full grid md:grid-cols-3 gap-4 grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 sm:gap-8'>
                            {activeLevel.subjects.map((item, index) => {
                                if(item.id !== 0){
                                    return(
                                        <SubjectCard key={index} subject={item} onClick = {() => selectSubject(item)} active = {subject === item ? true:false} />
                                    )
                                }
                                else return null
                            })}
                            {activeLevel.subjects.map((item, index) => {
                                if(item.id === 0){
                                    return( // THIS ELEMENT NEEDS TO BE SHOW AFTER ALL SUBJECTS without id=0
                                        <SubjectCard key={index} subject={item} onClick = {() => selectSubject(item)} active = {subject === item ? true:false} extraClass='allSubjects' />
                                    )
                                }
                                else return null
                            })}
                            
                        </div>
                    </div>
                </div>
            </div>
            

        )
    }
}

export default Subjects;