

// import Dropdown from "../../../components/Dropdown/Dropdown";
import { useContext, useEffect } from "react";
import LanguageContext from "../../../context/LanguageContext/LanguageContext";
import SearchContext from "../../../context/SearchContext/SearchConext";
import useQuery from "../../../hooks/useQuery";
import { useNavigate } from "react-router-dom";

const MoreFilters = () => {

    const query = useQuery();
    const navigate = useNavigate();
    const {langContent} = useContext(LanguageContext);
    const {
        // partner,
        setPartner,
        // area,
        setArea,
        // keel,
        setKeel,
        // contentType,
        setContentType,
        // courseType,
        setCourseType,
        moreFiltersDropdownOptions,
        // supportsSpecialEducationalNeeds,
        setSupportsSpecialEducationalNeeds,
        reviewed,
        setReviewed,
        testsOnly,   
        setTestsOnly
        } = useContext(SearchContext);
    

    const onApplyClick = () => {
        document.querySelector('.more_filters').classList.remove('show');
        document.querySelector('.App').classList.remove('no_scroll');
        // query.set('partner', partner.slug);
        // query.set('area', area.slug);
        // query.set('keel', keel.slug);
        // query.set('contentType', contentType.slug);
        // query.set('courseType', courseType.slug);
        // query.set('supportsSpecialEducationalNeeds', supportsSpecialEducationalNeeds);
        query.set('reviewed', reviewed);
        query.set('testsOnly', testsOnly); 
        query.set('page', 1); 
        navigate('/search?' + query.toString());
    }

    const hideMoreFilters = () => {
        document.querySelector('.more_filters').classList.remove('show');
        document.querySelector('.App').classList.remove('no_scroll');
    }

    useEffect(() => {
        let qpartner = query.get('partner');
        let qarea = query.get('area');
        let qkeel = query.get('keel');
        let qcontentType = query.get('contentType');
        let qcourseType = query.get('courseType');
        let qsupportsSpecialEducationalNeeds = query.get('supportsSpecialEducationalNeeds');
        let qreviewed = query.get('reviewed');
        let qtestsOnly = query.get('testsOnly');

         // partner filter and set area
         let filter = moreFiltersDropdownOptions.filter(o => o.slug === qpartner);
         if(filter.length > 0){
             setPartner(filter[0])
         }
         else setPartner(moreFiltersDropdownOptions[0]);

         
        //area filter and set area
        filter = moreFiltersDropdownOptions.filter(o => o.slug === qarea);
        if(filter.length > 0){
            setArea(filter[0])
        }
        else setArea(moreFiltersDropdownOptions[0]);

        //keel filter and set keel
        filter = moreFiltersDropdownOptions.filter(o => o.slug === qkeel);
        if(filter.length > 0){
            setKeel(filter[0])
        }
        else setKeel(moreFiltersDropdownOptions[0]);

        //contentType filter and set contentType
        filter = moreFiltersDropdownOptions.filter(o => o.slug === qcontentType);
        if(filter.length > 0){
            setContentType(filter[0])
        }
        else setContentType(moreFiltersDropdownOptions[0]);

        // courseType Filter and set courseType
        filter = moreFiltersDropdownOptions.filter(o => o.slug === qcourseType);
        if(filter.length > 0){
            setCourseType(filter[0])
        }
        else setCourseType(moreFiltersDropdownOptions[0]);

        // supportsSpecialEducationalNeeds Filter and set supportsSpecialEducationalNeeds
        if(qsupportsSpecialEducationalNeeds === 'true'){
            setSupportsSpecialEducationalNeeds(true);
        }
        else{
            setSupportsSpecialEducationalNeeds(false);
        }

        // reviewed Filter and set reviewed
        if(qreviewed === 'true'){
            setReviewed(true);
        }
        else{
            setReviewed(false);
        }

        if(qtestsOnly === 'true'){
            setTestsOnly(true);
        }
        else{
            setTestsOnly(false);
        }

    }, [
        query, setPartner, setKeel, setArea, setContentType, setCourseType, setSupportsSpecialEducationalNeeds, setReviewed, setTestsOnly, moreFiltersDropdownOptions
    ])

    return(
        <>
            
            <div className={`more_filters z-[9999] pt-20 bg-white text-[#767676] p-5 h-full w-full max-w-full translate-x-[100%] sm:max-w-[400px] sm:translate-x-[400px] fixed top-0 right-0
                transition-all ease-in-out duration-300 opacity-0 shadow-shadow8`}>
 
                <button onClick = {hideMoreFilters} type="button" className="absolute right-[16px] sm:left-[16px] top-[16px] flex items-center justify-center w-[56px] h-[56px] rounded-[50%] bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" clipPath="url(#a)"><path d="M.6 19.4 19.4.6M19.4 19.4.6.6"/></g><defs><clipPath id="a"><path fill="currentcolor" d="M0 0h20v20H0z"/></clipPath></defs></svg>
                </button>

                <div className="w-full flex flex-col mt-4">
                    <div className="flex flex-col sm:mr-10 md:mr-16 lg:mr-20 pt-8">
                        {/*
                        <div className="mb-5">
                            <p className="flex items-center mb-2 font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.partner?langContent.list.moreFilters.partner : 'Partner/bränd/kirjastus'}
                            </p>
                            <Dropdown items = {moreFiltersDropdownOptions} activeItem = {partner} setActiveItem = {setPartner}/>
                        </div>
                         <div className="mb-5">
                            <p className="flex items-center mb-2 font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.area?langContent.list.moreFilters.area:'Valdkond'}
                            </p>
                            <Dropdown items = {moreFiltersDropdownOptions} activeItem = {area} setActiveItem = {setArea}/>
                        </div> 
                        <div className="mb-5">
                            <p className="flex items-center mb-2 font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.keel?langContent.list.moreFilters.keel:'Keel'}
                            </p>
                            <Dropdown items = {moreFiltersDropdownOptions} activeItem = {keel} setActiveItem = {setKeel}/>
                        </div>}
                    </div>

                    <div className="flex flex-col">
                        <div className="mb-5">
                            <p className="flex items-center mb-2 font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.contentType?langContent.list.moreFilters.contentType:'Sisu tüüp'}
                            </p>
                            <Dropdown items = {moreFiltersDropdownOptions} activeItem = {contentType} setActiveItem = {setContentType}/>
                        </div>
                        {/* <div className="mb-5">
                            <p className="flex items-center mb-2 font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.courseType?langContent.list.moreFilters.courseType:'Kursuse tüüp'}
                            </p>
                            <Dropdown items = {moreFiltersDropdownOptions} activeItem = {courseType} setActiveItem = {setCourseType}/>
                        </div> */}

                        <div className="mb-5 flex items-center">
                            <label htmlFor = 'testsOnly_check' className="checkbox-container testsOnly_check flex items-center font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.testsOnly?langContent.list.moreFilters.testsOnly:'Näita ainult teste'}
                                <input type = 'checkbox' checked={testsOnly?true:false} onChange={()=>setTestsOnly(prev=>!prev)}
                                className="mr-2 mb-1" id = 'testsOnly_check'/>
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        {/*
                        <div className="mb-5 flex items-center">
                            <label htmlFor = 'reviewed_check' className="checkbox-container testsOnly_check flex items-center font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.reviewed?langContent.list.moreFilters.reviewed:'Retsenseeritud digimaterjal'}
                                <input type = 'checkbox' checked={reviewed?true:false} onChange={()=>setReviewed(prev=>!prev)}
                                className="mr-2 mb-1" id = 'reviewed_check'/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        */}
                        
                        {/*
                        <div className="mb-5 flex items-center">
                            <input type = 'checkbox' checked={supportsSpecialEducationalNeeds?true:false} onChange={()=>setSupportsSpecialEducationalNeeds(prev=>!prev)}
                                className="mr-2 mb-1" id = 'supportsSpecialChildrenNeedsCheck' />
                            <label htmlFor = 'supportsSpecialChildrenNeedsCheck' className="flex items-center font-[500] text-[14px] sm:text-base leading-4 tracking-[0.05em]">
                                {langContent.list.moreFilters.SupportsSpecialEducationalNeeds?langContent.list.moreFilters.SupportsSpecialEducationalNeeds:'Toetab hariduslikku erivajadust'}
                            </label>
                        </div>
                        */}
                       
                        <div className="w-full flex">
                            <button className='mr-4 transition-colors px-6 py-3 rounded-[24px] text-primary bg-[#ece9fc] hover:bg-primary hover:text-white border-[2px] border-transparent font-[500] tracking-[0.05em] leading-[1rem]'
                                onClick={onApplyClick}>
                                {langContent.list.moreFilters.apply?langContent.list.moreFilters.apply:'Rakenda'}
                            </button>
                        </div>

                            
                    </div>

                </div>

            </div>
        </>
    )
}

export default MoreFilters;