import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

// const api = 'https://taskutark.test/wp-json/tt/v1';

const API = {
  // Gets all books
  getTypes: (language) => {
    return axios.get(`${api}/types2?lang=${language}`);
    // return axios.get(`${api}/types2?lang=${language}`); // TEMP EBOOKS ENABLE
  },

  getLevels: (language) => {
    return axios.get(`${api}/levels?lang=${language}`);
  },

  getSearchResults: (searchquery) => {
    return axios.get(`${api}/search?${searchquery}`);
  },

  getExtraOptions: () => {
    return axios.get(`${api}/options/all`);
  },

  getCounts: (searchquery) => {
    return axios.get(`${api}/counts?${searchquery}`);
  },

  getAutocompleteSuggestions: (keyword, language) => {
    return axios.get(`${api}/autocomplete/?q=${keyword}&lang=${language}`);
  },

  getTopic: (topicSlug, language) => {
    return axios.get(`${api}/topics?slug=${topicSlug}&lang=${language}`);
  },

  getBanners: (query, language) => {
    return axios.get(`${api}/banners/?banner-type=${query}&lang=${language}`);
  },

  getSingle: (singleSlug) => {
    return axios.get(`${api}/content?slug=${singleSlug}`);
  },

  getEbook: (id) => {
    return axios.get(`${api}/ebook/${id}`);    
  },

  getEpub: (id, preview) => {
    return axios.get(`${api}/epub/${id}?preview=${preview}`);    
  },

  getUser : () => {
    return axios.get(`${api}/user`);
  },

  getTranslation: (language) => {
    return axios.get(`${api}/translations?lang=${language}`);
  },

  saveBookmark: (bookid, bookmark, userid) => {
    return axios.post(`${api}/ebook/${bookid}/bookmarks`, {body: {
      userid: userid,
      bookmark: bookmark
    }});
  },

  logOutUser: () => {
    return axios.get(`${api}/userLogout`);
  }

}

export default API;