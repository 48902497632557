import { useState } from "react";

const getTopbarShow = () => {
    let topbarTime = localStorage.getItem('topbarTime');
    if(topbarTime){
        if(Date.now() - parseInt(topbarTime) >= 86400000){
            return true;
        }
        else{
            // document.querySelector('.navbar_container').style.top = '0';
            return true;
        }
    }
}

const Topbar = ({link, text}) => {

    const [showLocalStorage, setShowLocalStorage] = useState(getTopbarShow());

    const onCrossClick = () => {
        localStorage.setItem('topbarTime', Date.now());
        setShowLocalStorage(true);
    }

    if(!showLocalStorage){
        return (
            <div className="hidden md:flex justify-between items-center py-2 mx-auto max-w-[1440px]">
                <a href={link} className="text-[12px] lg:text-[14px] text-[#0C1135] lg:leading-6 ">{text}</a>
                <button onClick={onCrossClick} aria-label={text}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.375 12.6245L12.625 1.37451" stroke="#0C1033" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.625 12.6245L1.375 1.37451" stroke="#0C1033" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        )
    }
    else return null;
}

export default Topbar;