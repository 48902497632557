
import HomeLayout from '../../layouts/Home/Home';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import Footer from '../../components/Footer/Footer';


const Home = () => {


    return(
        <>
            <ScrollToTopOnMount />
            <HomeLayout />
            <Footer />
        </>
        
  
    )
}

export default Home;