
import { useContext, useEffect, useState } from "react";
import API from "../../../API/API";
import Banner from "../../../components/List/Banner/Banner";
import LanguageContext from "../../../context/LanguageContext/LanguageContext";
import useQuery from "../../../hooks/useQuery";

const Banners = () => {
    const [banners, setBanners] = useState([]);
    const query = useQuery();
    const {language} = useContext(LanguageContext);

    useEffect(() => {
        API.getBanners(`search&${query.toString()}`, language)
        .then(res => {
            if(res.data === ''){
                setBanners([])
            }
            else{
                setBanners(res.data)
            }
        })
        .catch(err => console.log(err))
    }, [query, language])

    return(
        <div className="flex flex-col">
            {banners.map((item, index) => {
                return <Banner banner = {item} key={index}/>
            })}    
        </div>
    )
}

export default Banners;