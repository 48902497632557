import { useRef, useState } from "react";
import Slider from "react-slick";
import ContentCard5
 from "../../components/List/ContentCard5/ContentCard5";

const RelatedBooksSlider = ({ebook}) => {

    const sliderRef = useRef(null)
    // const [currentPage, setCurrentPage] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: false,
        swipeToSlide: false,
        responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
      };


    const onNextClick = () => {
        sliderRef.current.slickNext()
        // if(currentPage < 3){
        //     setCurrentPage(currentPage + 1)
        // }
    }
    const onPrevClick = () => {
        sliderRef.current.slickPrev()
        // if(currentPage > 0){
        //     setCurrentPage(currentPage - 1)
        // }
    }

    return(
        <div className="relative px-3 lg:px-0">
            <Slider {...settings} ref={sliderRef}>
                {ebook.related_ebooks.items.map((item, index) => {
                    return(
                        <ContentCard5 key={index} content = {item} />
                    )
                })}
            </Slider>

            <button className="absolute left-[-2%] md:left-[-1.5%] top-[100px] md:top-[140px] mb-[15px] text-[#938FA8] hover:text-[#6D53E4]" onClick={onPrevClick}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[20px] h-[30px]" viewBox="0 0 256 512">
                    <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"/></svg>
            </button>
        
        
            <button className="absolute right-[-2%] md:right-[-1.5%] top-[100px] md:top-[140px] mb-[15px] text-[#938FA8] hover:text-[#6D53E4]" onClick={onNextClick}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[20px] h-[30px]" viewBox="0 0 256 512">
                    <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"/>
                </svg>
            </button>
            
        </div>
    )
}

export default RelatedBooksSlider;