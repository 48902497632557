import {motion} from 'framer-motion'
import CheckIcon from '../../components/Icons/CheckIcon';
import CrossIcon from '../../components/Icons/CrossIcon';
import EbookThemeContext from "../../context/EbookThemeContent/EbookThemeContext";
import { useContext, useEffect } from 'react';

const sideVariants = {
    open: {
        translateX: 0,
        transition: {duration: 0.5, ease: [0.6, 0.05, -0.01, 0.9]}
    },
    closed: {
        translateX: '100%',
        transition: {duration: 0.5, ease: [0.6, 0.05, -0.01, 0.9]}
    }
}


const Sidebar = ({book, toc, bookmarks, rendition, currentLocation, open, setOpen, activeTab, changeSection, currentTopic}) => {

    const { theme, fontSize, lineHeight, letterSpacing, setTheme, setFontSize, setLineHeight, setLetterSpacing, setFontFamily, setFontWeight, setPageView } = useContext(EbookThemeContext);

    const goToCfi = (cfi) => {
        setOpen(false)
        rendition.display(cfi)
    }
    useEffect(() => {
        var content = document.querySelector('.TOC-content'),
        wrapper = document.querySelector('.TOC-wrapper'),
        shadowTop = document.querySelector('.shadow--top'),
        shadowBottom = document.querySelector('.shadow--bottom'),
        contentScrollHeight = content.scrollHeight - wrapper.offsetHeight;
        if(shadowTop && shadowBottom){
            content.addEventListener('scroll', function(){
                var currentScroll = this.scrollTop / (contentScrollHeight);  
                shadowTop.style.opacity = currentScroll;
                shadowBottom.style.opacity = 1 - currentScroll;
            });
        }
    })

    return(
        <div className='overflow-hidden text-black'>

            <motion.aside 
            initial="closed"
            animate= {open ? "open" : "closed"}
            variants={sideVariants}
            className={`h-[100vh] w-[100%] min-w-[400px] md:w-[30%] xl:w-[30%] fixed top-0 lg:top-[120px] right-0 px-4 md:pl-4 md:pr-0 pt-[16px] lg:pt-0 z-[2] bg-white ${theme === 'dark' && 'bg-[#2e2e2e]'}`}
            >

                {activeTab === 'toc' ? (
                    <div className='flex flex-col'>
                        <div className='h-[100px] lg:h-auto mb-4 md:mb-0 flex flex-col'>
                            <button onClick={()=>setOpen(false)} className = {`block md:hidden ml-auto w-fit text-[#fffff] hover:text-[#6D53E4] flex items-center px-4 py-1 border-[1px] border-[#E2DDFA] hover:border-[#6D53E4] rounded-[64px] ${theme === 'dark' && 'text-[#ffffff]'}`}>                               
                                <CrossIcon className={`w-[16px] h-[17px] text-[#0C1135] ${theme === 'dark' && 'text-[#ffffff]'}`} />
                                <p className={`ml-3 mt-[2px] text-[#0C1135] font-medium tracking-wider leading-[20px] text-[14px] ${theme === 'dark'&&'text-[#ffffff]'}`}>Sisukord</p>
                            </button>
                            <h2 className={`mt-auto text-[#0C1135] text-[24px] font-[500] ${theme === 'dark' && 'text-[#ffffff]'}`}>{book &&book.packaging && book.packaging.metadata.title}</h2>
                        </div>
                        <div className="TOC-wrapper">
                            {
                                theme !== 'dark' &&
                                <>
                                <div className="shadowOverflow shadow--top"></div><div className="shadowOverflow shadow--bottom"></div>
                                </>
                            }
                            <div className="flex flex-col items-start overflow-y-auto pr-4 TOC-content" style={{height: 'calc(100vh - 180px)'}}>
                                {toc && toc.map((toc, index) => {
                                    return (
                                        <button key = {toc.href} className={`text-left mb-2 hover:text-[#6D53E4] flex items-center ${index === currentTopic ? 'text-[#6D53E4]' : 'text-[#0C1135]'} ${theme === 'dark' && 'text-[#ffffff]'}`}
                                         onClick={() => changeSection(toc.href, index)}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {toc.label}
                                            {index === currentTopic && <CheckIcon className={'w-[13px] h-[12px] ml-2'} />}
                                        </button>   
                                    )
                                })}
                                
                            </div>
                        </div>
                    </div>
                ): activeTab === 'bookmarks' ? (
                    <div className='flex flex-col'>
                        <div className='h-[20vh] mb-4 flex flex-col'>
                            <button onClick={()=>setOpen(false)} className = 'mb-[10vh] w-fit text-[#938FA8] hover:text-[#6D53E4] flex items-center py-2'>                               
                                <CrossIcon className={'w-[16px] h-[17px]'} />
                            </button>
                            <h2 className='mt-auto text-[#0C1135] text-[24px] font-[500]'>Bookmarks</h2>
                        </div>
                        <div className="flex flex-col items-start overflow-y-auto" style={{height: 'calc(80vh - 16px)'}}>
                            {bookmarks && bookmarks.map(bookmark => {
                                return (
                                    <button key = {bookmark.cfi} className="text-left mb-2" onClick={() => goToCfi(bookmark.cfi)}>{bookmark.label}</button>   
                                )
                            })}
                        </div>
                    </div>
                ):(
                    <div className='flex flex-col'>
                        <button onClick={()=>setOpen(false)} className = 'mb-16 w-fit text-[#938FA8] hover:text-[#6D53E4] flex items-center py-2'>                               
                            <CrossIcon className={'w-[16px] h-[17px]'} />
                        </button>
                        <h2 className='text-[#0C1135] text-[24px] font-[500] mb-4'>Settings</h2>
                        <div className="flex flex-col items-start px-4 h-[92vh] overflow-y-auto">
                            <div className = 'flex justify-between w-full flex-wrap mb-5'>
                                <p className="mb-2">Font Size: </p>
                                <input type="range" min="10" max="30" step="1" value={fontSize} onChange={(e) => setFontSize(e.target.value)} className="w-full"/>
                            </div>
                            <div className = 'flex justify-between w-full flex-wrap mb-5'>
                                <p className="mb-2">Line Height: </p>
                                <input type="range" min="1" max="3" step="0.1" value={lineHeight} onChange={(e) => setLineHeight(e.target.value)} className="w-full" />
                            </div>
                            <div className = 'flex justify-between w-full flex-wrap mb-5'>
                                <p className="mb-2">Letter Spacing: </p>
                                <input type="range" min="0" max="2" step="0.1" value={letterSpacing} onChange={(e) => setLetterSpacing(e.target.value)} className="w-full" />
                            </div>
                            <div className = 'flex justify-between w-full flex-wrap mb-5'>
                                <p className="mb-2">Font Weight: </p>
                                <select className = 'w-full border-[1px] border-gray-400' onChange={(e) => setFontWeight(e.target.value)}>
                                    <option value="400">Normal</option>
                                    <option value="600">Bold</option>
                                </select>
                            </div>
                            <div className = 'flex justify-between w-full flex-wrap mb-5'>
                                <p className="mb-2">Font Family: </p>
                                <select className = 'w-full border-[1px] border-gray-400' onChange={(e) => setFontFamily(e.target.value)}>
                                    <option value="sans-serif">Roboto</option>
                                    <option value="sans-serif">Sans Serif</option>
                                    <option value="monospace">Monospace</option>
                                </select>
                            </div>
                            <div className = 'flex justify-between w-full flex-wrap mb-5'>
                                <p className="mb-2">Theme: </p>
                                <select className = 'w-full border-[1px] border-gray-400' onChange={(e) => setTheme(e.target.value)}>
                                    <option value="light">Light</option>
                                    <option value="dark">Dark</option>
                                </select>
                            </div>
                            <div className = 'flex justify-between w-full flex-wrap mb-5'>
                                <p className="mb-2">Page View: </p>
                                <select className = 'w-full border-[1px] border-gray-400' onChange={(e) => setPageView(e.target.value)}>
                                    <option value="spread">Spread</option>
                                    <option value="single">Single Page</option>
                                </select>
                            </div>
                        </div>
                    </div>
                )}
                
            </motion.aside>
        </div> 
    )
}

export default Sidebar;