
import ContentCard from "../../../components/List/ContentCard/ContentCard";
import ContentCard2 from "../../../components/List/ContentCard2/ContentCard2";
import ContentCard3 from "../../../components/List/ContentCard3/ContentCard3";
import ContentCard4 from "../../../components/List/ContentCard4/ContentCard4";



const Content = ({items}) => {
    
    return(
        <>
            {items && items.map((item, index) => {
                if(item.bg_image !== undefined) {
                    return <ContentCard3 content = {item} key={index} />
                }
                else if(item.book_image !== undefined){
                    return <ContentCard2 content = {item} key={index} />
                }
                else if(item.image !== undefined){
                    return <ContentCard4 content = {item} key = {index} />
                }
                else{
                    return(
                        <ContentCard content = {item} key={index} />
                    )   
                }
                
            })}
        </>
    )
}

export default Content;