

import '../../Home/BannerCard/BannerCard.css';
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import  { useContext } from "react";
import DataContext from "../../../context/DataContext";
import SearchContext from "../../../context/SearchContext/SearchConext";
import { useNavigate } from "react-router-dom";
import useQuery from '../../../hooks/useQuery';


const Banner = ({banner}) => {

    const query = useQuery();
    const navigate = useNavigate();
    const {extraOptions} = useContext(DataContext);
    const {type, setActiveExtraOption1} = useContext(SearchContext);

    const onExtra1OptionChange = (option) => {
        setActiveExtraOption1(option);
        query.set(extraOptions[type.slug][0].param, option.slug);
        navigate(`/search?${query.toString()}`);
    }

    return(
        <>
        {banner.linkType === 'internal' ? (
            <Link to = {banner.link} className={`banner_card w-full m-0 md:w-[350px] xl:w-full min-h-[300px] 
            p-5 sm:p-9 flex flex-col justify-between text-white rounded-[8px] cursor-pointer mb-4 md:mt-0 ${banner.overlay && 'overlay'}`}
            style={{backgroundImage: `url(${banner.image})`}}
            >
                <div>
                    <h2 className="font-[500] text-[24px] md:text-[28px] leading-tight mb-4">{banner.title}</h2>
                    <p className="mb-4 text-sm md:text-base">{banner.text}</p>
                </div>
                <div className = 'banner_card_link bg-white w-12 h-12 rounded-[50%] flex justify-center items-center shrink-0'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.1222 3.33331L9.85689 4.5902L12.6018 7.33509H0V9.10931H12.6018L9.85689 11.8542L11.1222 13.1111L16 8.2222L11.1222 3.33331Z" fill="#6D53E4"/>
                    </svg>
                </div>

            </Link>
        ):(
            <>
            {banner.linkType === 'external' ?
                <a href = {banner.link} target={banner.new_window ? '_blank' : '_self'} rel="noreferrer" className={`banner_card w-full m-0 md:w-[350px] xl:w-full min-h-[300px] bg-[#EBEEFE]
                p-5 sm:p-9 flex flex-col justify-between text-white rounded-[8px] cursor-pointer md:mb-4 md:mt-0 ${banner.overlay && 'overlay'}`}
                    style={{backgroundImage: `url(${banner.image})`}}
                >
                    <div>
                        <h2 className="font-[500] text-[24px] md:text-[28px] leading-tight mb-4">{banner.title}</h2>
                        <p className="mb-4 text-sm md:text-base">{Parser(banner.text)}</p>
                    </div>
                    <div className = 'banner_card_link bg-white w-12 h-12 rounded-[50%] flex justify-center items-center shrink-0'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1222 3.33331L9.85689 4.5902L12.6018 7.33509H0V9.10931H12.6018L9.85689 11.8542L11.1222 13.1111L16 8.2222L11.1222 3.33331Z" fill="#6D53E4"/>
                        </svg>
                    </div>

                </a>
            :
                <div className={`banner_card w-full m-0 md:w-[350px] xl:w-full min-h-[300px] bg-[#EBEEFE]
                p-5 sm:p-9 flex flex-col justify-between text-[#0C1135] rounded-[8px] md:mb-4 md:mt-0`}>
                    <div>
                        <h2 className="font-[500] text-[28px] md:text-[32px] leading-tight mb-4">{banner.title}</h2>
                        {banner.links ?
                            <p className="mb-4 mt-6 text-sm md:text-base bannerContentStyle links">
                                {banner.links && banner.links.map((item, index) => {
                                    return(
                                        <button key = {index} onClick={() => onExtra1OptionChange(item)} className= ''>
                                            {item.name}<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none"><path stroke="#6D53E4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1.6 7.1h10.5M7.1 12.4 12.4 7 7 2"/></svg>
                                        </button>
                                    )
                                })}
                            </p>
                        :
                            <p className="mb-4 mt-6 text-sm md:text-base bannerContentStyle">{Parser(banner.text)}</p>
                        }
                    </div>
                </div>
            }
            </>
        )}
        </>
    )
}

export default Banner;