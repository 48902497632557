import { useContext } from "react";
import LanguageContext from '../../context/LanguageContext/LanguageContext';
import DataContext from "../../context/DataContext";

const MobileHeader = () => {

    const {language, langContent, changeLanguage} = useContext(LanguageContext);
    const {settings, user} = useContext(DataContext);

    const closeMenu = () => {
        document.querySelector('.mobile_menu').classList.remove('show_mobile_menu');
        document.querySelector('.App').classList.remove('no_scroll');
    }

    return(
        <div className="fixed h-[100vh]">
            <div className="mobile_menu fixed w-full opacity-0 max-w-full translate-x-[100%] sm:max-w-[400px] sm:translate-x-[400px] shadow-shadow7 right-0 top-0 bg-white h-full text-[#0C1135] pt-16 px-3">
                <button onClick = {closeMenu} type="button" className="absolute right-[16px] sm:left-[16px] top-[16px] flex items-center justify-center w-[56px] h-[56px] rounded-[50%] bg-primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" clipPath="url(#a)"><path d="M.6 19.4 19.4.6M19.4 19.4.6.6"/></g><defs><clipPath id="a"><path fill="currentcolor" d="M0 0h20v20H0z"/></clipPath></defs></svg>
                </button>
                <ul className="flex flex-col mt-4 text-[16px] font-[500] tracking-[0.05em] leading-[1rem]">
                {settings.navHeader &&
                    <>
                    <li className='flex items-center mb-4  justify-center'>
                        <a href={settings.navHeader[0].linkTarget} className = 'px-2 md:py-2 mr-10 hover:underline'>
                            {langContent.header.school ? langContent.header.school :'Koolidele'}
                        </a>
                    </li>
                    </>
                    }
                    {
                    user.loggedIn ? (
                        <>
                        <li className='flex items-center mb-4 md:mb-0'>
                            <div className={`flex sm:flex-col justify-center ml-3 text-[#0C1135]`}>
                                <button className='hidden mr-4 mb-2 px-6 py-3 rounded-[24px] bg-white text-[] text-[16px] font-[500] border-[1px] border-[#ECE9FC] tracking-[0.05em] leading-[1rem] hover:bg-primary hover:text-white'>
                                    {langContent.header.desktop?langContent.header.desktop:'Töölaud'}
                                </button>
                                <div className="flex items-center justify-center">
                                    <p className="mr-2 text-[16px] font-[500] tracking-[0.05em] leading-[1rem] justify-center">{user.name}</p>
                                    <div className="hidden mr-2 bg-[#28BF73] rounded-[40px] flex justify-center items-center px-2 py-1">
                                        <p className="text-[14px] text-white font-[500] leading-4 tracking-[0.05em]">{user.notifications}</p>
                                    </div>
                                    <div className='hidden relative flex items-center'>
                                        <button className='flex items-center py-3'>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.875 1.625L6.875 12.125" stroke={'#0C1135'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.625 7.125L6.875 12.375L12.125 7.125" stroke={'#0C1135'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                        <div className='hidden absolute right-0 top-[30px] w-[90px] py-2 bg-white shadow-shadow4 text-[#0C1135] rounded-[8px]'>
                                            <button className='w-full px-3'>{langContent.header.logout?langContent.header.logout:'Logi välja'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        </>
                    ):(
                        <>
                            <li className='flex items-center mb-4  justify-center'>
                                <a href="https://www.taskutark.ee/auth.php" className='mr-4 px-6 py-3 rounded-[24px] bg-[#EBEEFE] border-[2px] border-transparent font-[500] text-primary tracking-[0.05em] leading-[1rem] hover:bg-primary hover:text-white hover:border-primary'>
                                    {langContent.header.login ? langContent.header.login : 'Logi sisse'}
                                </a>
                            </li>
                            <li className = "nav-item flex items-center flag-dropdown-anchor hidden md:flex">
                                <div className = "nav-link p-0 mx-2 flex items-center" >
                                    {language === 'et' ? (
                                        <button>
                                            <svg className = 'mr-1' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32 0H0V23.895H32V0Z" fill="#0C1135"/>
                                            <path d="M32 16.035H0V24H32V16.035Z" fill="white"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 0H32V7.965H0V0Z" fill="#1291FF"/>
                                            </svg>
                                        </button>
                                    ):language ==='en' ? (
                                        <button className='flex items-center'>
                                            <span className="en-flag mr-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" fill="none"><path fill="#012169" d="M0 0h32v24H0V0Z"/><path fill="#fff" d="m3.8 0 12.1 9 12.2-9H32v3.1l-12 9 12 8.8V24h-4l-12-9-12 9H0v-3l12-8.9L0 3.2V0h3.8Z"/><path fill="#C8102E" d="M21.2 14 32 22v2L18.4 14h2.8ZM12 15l.3 1.8L2.7 24H0l12-9ZM32 0v.1L19.6 9.7V7.3L29.5 0H32ZM0 0l12 8.8H9L0 2.1V0Z"/><path fill="#fff" d="M12 0v24h8V0h-8ZM0 8v8h32V8H0Z"/><path fill="#C8102E" d="M0 9.7v4.8h32V9.6H0ZM13.7 0v24h4.8V0h-4.8Z"/></svg>
                                            </span>
                                        </button>
                                    ):(
                                        <button className='flex items-center'>
                                            <span className="ru-flag mr-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" fill="none"><path fill="#fff" d="M32 0H0v12h32V0Z"/><path fill="#D52B1E" d="M32 12H0v12h32V12Z"/><path fill="#0039A6" d="M32 8H0v8h32V8Z"/></svg>
                                            </span>
                                        </button>
                                    )}
                                   
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.875 1.625L6.875 12.125" stroke={'#0C1135'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.625 7.125L6.875 12.375L12.125 7.125" stroke={'#0C1135'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>

                                </div> 
                                <div className = "flag-dropdown">
                                    {language !== 'et' && 
                                        <button onClick={() => changeLanguage('et')}>
                                            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32 0H0V23.895H32V0Z" fill="#0C1135"/>
                                            <path d="M32 16.035H0V24H32V16.035Z" fill="white"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 0H32V7.965H0V0Z" fill="#1291FF"/>
                                            </svg>
                                        </button>
                                    }
                                    {language !== 'en' && 
                                        <button onClick={() => changeLanguage('en')}>
                                            <span className="en-flag">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" fill="none"><path fill="#012169" d="M0 0h32v24H0V0Z"/><path fill="#fff" d="m3.8 0 12.1 9 12.2-9H32v3.1l-12 9 12 8.8V24h-4l-12-9-12 9H0v-3l12-8.9L0 3.2V0h3.8Z"/><path fill="#C8102E" d="M21.2 14 32 22v2L18.4 14h2.8ZM12 15l.3 1.8L2.7 24H0l12-9ZM32 0v.1L19.6 9.7V7.3L29.5 0H32ZM0 0l12 8.8H9L0 2.1V0Z"/><path fill="#fff" d="M12 0v24h8V0h-8ZM0 8v8h32V8H0Z"/><path fill="#C8102E" d="M0 9.7v4.8h32V9.6H0ZM13.7 0v24h4.8V0h-4.8Z"/></svg>
                                            </span>
                                        </button>
                                    }
                                    {language !== 'ru' && 
                                        <button onClick={() => changeLanguage('ru')}>
                                            <span className="ru-flag">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" fill="none"><path fill="#fff" d="M32 0H0v12h32V0Z"/><path fill="#D52B1E" d="M32 12H0v12h32V12Z"/><path fill="#0039A6" d="M32 8H0v8h32V8Z"/></svg>
                                            </span>
                                        </button>
                                    }
                                    
                                </div>
                            </li> 
                        </>
                    )
                    }
                               
                </ul>
            </div>
        </div>
    )
}

export default MobileHeader;