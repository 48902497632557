import React, { useCallback, useEffect, useState } from "react";
import SearchContext from "./SearchConext";
import API from "../../API/API";
import useQuery from "../../hooks/useQuery";
import { useLocation } from "react-router-dom";

function SearchContextProvider(props) {
  const query = useQuery();
  const location = useLocation();
  const [type, setType] = useState({name: '', slug: ''});
  const [searchInput, setSearchInput] = useState('');
  const [grade, setGrade] = useState({name: '', slug: ''});
  const [subject, setSubject] = useState({name: '', slug: ''});
  const [searchResults, setSearchResults] = useState({items: []});
  const [searchResultCounts, setSearchResultCounts] = useState([]);
  const [partner, setPartner] = useState('');
  const [area, setArea] = useState('');
  const [keel, setKeel] = useState('');
  const [contentType, setContentType] = useState('');
  const [courseType, setCourseType] = useState('');
  const [moreFiltersDropdownOptions] = useState([{id: 0, name: 'Small', slug : 'small'}, {id: 1, name: 'Medium', slug : 'medium'}, {id: 2, name: 'Large', slug : 'large'}]);
  const [supportsSpecialEducationalNeeds, setSupportsSpecialEducationalNeeds] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [testsOnly, setTestsOnly] = useState(false);
  const [activeExtraOption1, setActiveExtraOption1] = useState({});
  const [activeExtraOption2, setActiveExtraOption2] = useState({});

  const [loading, setLoading] = useState(true);


  const getSearchResults = useCallback(() => {
    setLoading(true);

    //API call to get search Results
    API.getSearchResults(query.toString())
    .then(res => {
      if(res.data === ''){
        setSearchResults({items: []});
        setLoading(false);
      }
      else{
        setSearchResults(res.data);
        setLoading(false);
      }
    })
    .catch(err => console.log(err));

  }, [query]);

  const getSearchResultCounts = useCallback(() => {
    //API call to get counts
    API.getCounts(query.toString())
    .then(res => {
      if(res.data === ''){
        setSearchResultCounts([]);
      }
      else{
        setSearchResultCounts(res.data);
      }
    })
    .catch(err => console.log(err));
  }, [query]);

  useEffect(() => {

    if(location.pathname === '/search'){
      getSearchResults();
      if(location.state !== 'dontloadtypecounts'){
        getSearchResultCounts();
      }
    }
  } , [location, getSearchResults, getSearchResultCounts]);


  return (
    <SearchContext.Provider
      value={{
        type,
        setType,
        searchInput,
        setSearchInput,
        grade,
        setGrade,
        subject,
        setSubject,
        searchResults, 
        getSearchResults,
        searchResultCounts,
        partner,
        setPartner,
        area,
        setArea,
        keel,
        setKeel,
        contentType,
        setContentType,
        courseType,
        setCourseType,
        moreFiltersDropdownOptions,
        supportsSpecialEducationalNeeds,
        setSupportsSpecialEducationalNeeds,
        reviewed,
        setReviewed,
        testsOnly,   
        setTestsOnly,
        activeExtraOption1,
        setActiveExtraOption1,
        activeExtraOption2,
        setActiveExtraOption2,
        loading,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
}

export default SearchContextProvider;
