



export const ReplaceText = (text, keyword) => {
    if (text && keyword) {
        let result = text.replace(new RegExp(keyword, 'gi'), (match) => `<span className="highlight">${match}</span>`); 
        return result;
    }
    else return text;
}