import Parser from "html-react-parser";

const TypeButton = ({type, typeCount, onClick, active}) => {
    return(
        <button onClick = {onClick} className={`p-1.5 md:p-3 mr-2 sm:mr-3 mb-3 flex justify-center items-center rounded-[8px] leading-6 tracking-[0.05em] shrink-0 type-${type.slug}
                ${active ? 'text-[#6D53E4] border-[2px] border-[#6D53E4]' : 'border-[2px] border-[#ECE9FC] text-[#0C1135] hover:border-[#BCB7D7]'}`}>
            {type.icon !== undefined && type.icon !== null && <div className={`mr-2 type_button_svg ${active && 'active'} `}>{Parser(type.icon)}</div>}
            <p className={`text-[12px] sm:text-[14px] font-[500] mr-2 leading-[5px] text-inherit nowrap ${typeCount === null && 'text-[#0C1135]'} `}>{type.name}</p>
            {typeCount === null ? (
                <p className={`text-[12px] font-[500] px-[6px] py-1 rounded-[24px] text-[#0C1135] leading-3 tracking-[0.05em] w-[42px] ${active ? 'bg-[#6D53E4] text-white': 'bg-[#ECE9FC]'}`}>0</p>   
            ):(
                <p className={`text-[12px] font-[500] px-[6px] py-1 rounded-[24px] text-[#0C1135] leading-3 tracking-[0.05em] w-[42px] ${active ? 'bg-[#6D53E4] text-white': 'bg-[#ECE9FC]'}`}>
                    {typeCount}
                </p>
            )}
   
        </button>
    )
}

export default TypeButton;