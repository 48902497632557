

const SearchButton = ({onClick, className}) => {
    return(
        <button aria-label="Search" onClick={onClick} className={`bg-[#6D53E4] text-white h-full md:w-[76px] flex justify-center items-center ${className} hover:opacity-90`}>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.10179 9.60543C2.6927 11.0045 3.81518 12.1115 5.2223 12.683C6.62942 13.2544 8.20591 13.2435 9.60498 12.6526C11.004 12.0617 12.1111 10.9392 12.6825 9.53208C13.254 8.12496 13.243 6.54847 12.6521 5.14941C12.0612 3.75034 10.9387 2.64332 9.53163 2.07187C8.12451 1.50041 6.54801 1.51134 5.14895 2.10225C3.74989 2.69315 2.64286 3.81563 2.07141 5.22275C1.49996 6.62987 1.51089 8.20637 2.10179 9.60543V9.60543Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.4167 11.4352L15.848 15.887" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
        </button>
    )
}

export default SearchButton;