import SingleEbookView from "../../layouts/Ebook/SingleEbookView";
import Footer from '../../components/Footer/Footer';
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

const SingleEbookViewPage = () => {
    return(
        <>
            <ScrollToTopOnMount />
            <SingleEbookView />
            <Footer />
        </>
    )
}

export default SingleEbookViewPage;