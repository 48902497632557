import { useRef, useState, useEffect } from "react";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

const Dropdown = ({items, activeItem, setActiveItem, className='', label = ''}) => {

    // console.log(activeItem)

    const dropdownRef = useRef(null);
    const dropdownitemsRef = useRef(null);
    const [groupedLevels, setGroupedLevels] = useState([]);

    const toggleDropdown = () => {
        dropdownitemsRef.current.classList.toggle('hidden');
    }
    const hideDropdown = () => {
        dropdownitemsRef.current.classList.add('hidden');
    }
    useOutsideAlerter(dropdownRef, hideDropdown);

    const changeActiveItem = (item) => {
        setActiveItem(item);
        dropdownitemsRef.current.classList.add('hidden');
    }


    useEffect(() => {
        // console.log(activeItem);
        const levelsMap = [...items];
            const gl = [];
            while(levelsMap.length) {
                gl.push(levelsMap.splice(0, 12));
            }
            setGroupedLevels(gl);
    }, [items, activeItem])

    return (
        <div ref={dropdownRef} className={`border-[1px] rounded-[8px] relative w-full md:w-[180px] ${className}`}>
            <div className="flex justify-between px-4 py-3 items-center text-sm  font-[500] border-0 select_container cursor-pointer" 
                onClick={toggleDropdown}>
                <p className="text-[#0C1135] font-[500] text-[14px] leading-[16px] tracking-[0.05em]">{activeItem.name === 'Näita kõiki' ? label : activeItem.name}</p>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div ref = {dropdownitemsRef} className="z-[2] dropdownItems xl:flex-row select_options hidden rounded-b-[8px] shadow-shadow7 flex flex-col absolute left-0 top-[43px] bg-white w-auto overflow-auto max-h-[360px] xl:overflow-hidden xl:max-h-fit p-2 sm:min-w-[300px]">
                {groupedLevels && groupedLevels.map((groupLevel, index) => {
                    return (
                        <div key={index} className={`col ${groupedLevels.length > 1 ? 'xl:w-[200px]' : 'xl:w-full'}`}>
                            {groupLevel.map((item, index) => {
                                
                                    return(
                                        <button key = {index} onClick={() => changeActiveItem(item)} className = {`w-full text-left px-5 py-4 sm:px-4 sm:py-2 text-[#57546d] hover:text-[#6d53e4] hover:bg-[#ebeefe] text-[14px] leading-[16px] tracking-[0.05em] leading-tight ${item.slug === 'all' && 'font-[500] text-black'} ${activeItem.slug === item.slug && 'hidden'}`}>
                                            {item.name}
                                        </button>
                                    )
                                
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Dropdown;