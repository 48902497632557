import './CardsLoading.css'

const CardsLoading = ({width, height, n, type}) => {

    const generateCards = () => {
        const cards = [];
        // var n = (type == 'ebook')? 8 : n;
        for(let i = 0; i < n; i++){
            if(type != 'ebook'){
            cards.push(
                <div key={i} className="card_loading w-full h-full rounded-[8px] p-6 mb-4">
                        <div className="flex items-center mb-4">
                            <div className="flex flex-col w-full">
                                <p className='shimmerBG w-[100px] h-[25px] mb-2 rounded-[8px]'></p>
                                <p className="shimmerBG w-[45%] h-[40px]"></p>
                            </div>
                        </div>
                        <div className="shimmerBG w-full h-[80px]"></div>      
                </div>
            );
            }else{
                cards.push(
                    <div key={i} className="card_loading w-full h-full rounded-[8px] p-6 mb-4">
                        <div className="shimmerBG w-full h-[200px]"></div>      
                            <div className="flex items-center mt-6">
                                <div className="flex flex-col w-full">
                                    <p className='shimmerBG w-full h-[20px] mb-2'></p>
                                    <p className="shimmerBG w-[65%] h-[20px]"></p>
                                </div>
                            </div>
                    </div>
                );
            }
        }
        return cards;
    }
   

    if(type !== 'ebook'){
        return (
            <>
                <div className={`flex flex-col col-span-4`}>
                    {generateCards()}
                </div>
            </>
        )
    }else{
        return generateCards()
    }

}

export default CardsLoading;