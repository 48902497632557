import { useEffect, useContext } from "react";
import EbookThemeContext from "../../context/EbookThemeContent/EbookThemeContext";

const useThemeSettings = (rendition) => {

    const { theme, fontSize, lineHeight, letterSpacing, fontFamily, fontWeight, pageView, } = useContext(EbookThemeContext);

    useEffect(() => {
        if(rendition){
            // rendition.hooks.content.register(applyTheme);
            // rendition.hooks.content.trigger(applyTheme);
            rendition.themes.register('custom', {
                h1: {
                    'font-family': `${fontFamily} !important`,
                    'color': `${theme==='light' ? '#000' : '#fff'} !important`
                },
                h3: {
                    'font-family': `${fontFamily} !important`,
                    'color': `${theme==='light' ? 'maroon' : '#ff8585'} !important`
                },
                a: {
                    'color': `${theme==='light' ? '#6D53E4' : '#fff'} !important`,
                    'text-decoration': `underline !important`,
                },
                h2: {
                    'font-size': `${fontSize}px !important`,
                    'line-height': `${lineHeight} !important`,
                    'letter-spacing': `${letterSpacing}px !important`,
                    'font-weight': `${fontWeight} !important`,
                    'font-family': `${fontFamily} !important`,
                    'color': `${theme==='light' ? '#000' : '#e5e5e5'} !important`
                },
                p: {
                    'font-size': `${fontSize}px !important`,
                    'line-height': `${lineHeight} !important`,
                    'letter-spacing': `${letterSpacing}px !important`,
                    'font-weight': `${fontWeight} !important`,
                    'font-family': `${fontFamily} !important`,
                    'color': `${theme==='light' ? '#000' : '#e5e5e5'} !important`
                },
                img:{
                    'background-color': 'white'
                }
              });
            rendition.themes.select('custom');
            document.querySelector('.ebookcontainer').style.backgroundColor = theme==='light' ? '#fff' : '#2e2e2e';
            document.querySelector('.ebookcontainer').style.color = theme==='light' ? '#0C1135' : '#fff';

            
        }
    }, [theme, fontSize, lineHeight, letterSpacing, fontFamily, fontWeight]);

    useEffect(() => {
        if(rendition){
            if(pageView==='spread'){
                rendition.spread('always')
            }
            else{
                rendition.spread('none')
            }
            rendition.resize('100%', '100%');
        }
    }, [pageView]);

}

export default useThemeSettings;