
import { useContext, useEffect, useRef, useState } from "react";
import SearchButton from "../../../components/Home/SearchButton/SearchButton";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import SearchContext from "../../../context/SearchContext/SearchConext";
import LanguageContext from '../../../context/LanguageContext/LanguageContext';
import { motion } from "framer-motion";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import API from "../../../API/API";
import './Cover.css'

const searchbarVariant = {
    hidden: {
        y: 0,
    },
    visible: {
        y: 0,
    },
    exit: {
        maxWidth: '1080px',
        y: '-250%',
        transition: {duration: 0.1}
    }
}

const introVariant = {
    exit: {
        opacity: 0,
        transition: {duration: 0.1}
    }
}

const Cover = () => {

    const navigate = useNavigate();
    const {types, levels} = useContext(DataContext);
    const {searchInput, setSearchInput, type, setType, grade, subject, setGrade, setSubject} = useContext(SearchContext);
    const {language, langContent} = useContext(LanguageContext);
    const [searchAutocomplete, setSearchAutocomplete] = useState([]);
    const [activeAutocomplete, setActiveAutocomplete] = useState(-1);
    const topicDropdownRef = useRef(null);
    const searchInputRef = useRef(null);

    useEffect(() => {
        if(types[0] !== undefined){
            setType(types[0]);
        }
        if(levels[0] !== undefined){
            setGrade(levels[0]);
        }
        setSubject({slug: ''});

    }, [types, levels, setType, setGrade, setSubject]);

    const toggleSearchOptions = () => {
        document.querySelector('.select_options').classList.toggle('hidden');
        document.querySelector('.select_container').classList.toggle('active');
    }

    const hideSearchOptions = (ref) => {
        ref.current.children[1].classList.add('hidden');
        ref.current.children[0].classList.remove('active');
    }
    useOutsideAlerter(topicDropdownRef, hideSearchOptions);

    const changeSearchType = (type) => {
        setType(type);
        hideSearchOptions(topicDropdownRef);
    }

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value);
        setActiveAutocomplete(-1);
        // if(event.target.value.length > 2){
        //     API.getAutocompleteSuggestions(event.target.value)
        //     .then(res => setSearchAutocomplete(res.data))
        //     .catch(err => console.log(err));
        // }
        // else{
        //     setSearchAutocomplete([])
        // }
    }
    const handleSearch = () => {
        const qtype = type.slug ? type.slug : 'all';
        const qgrade = grade.slug ? grade.slug : 'all';
        const qsubject = subject.slug ? subject.slug : 'all';
        navigate(`/search?q=${searchInput}&type=${qtype}&grade=${qgrade}&subject=${qsubject}&lang=${language}`);
    }

    const handleAutocompleteSearch = (input) => {
        document.querySelector('.autocomple_dialog').classList.add('hidden');
        setSearchInput(input);
        const qtype = type.slug ? type.slug : 'all';
        const qgrade = grade.slug ? grade.slug : 'all';
        const qsubject = subject.slug ? subject.slug : 'all';
        navigate(`/search?q=${input}&type=${qtype}&grade=${qgrade}&subject=${qsubject}&lang=${language}`);
    }

    //this use effect will listen to the keydown event and changes the search input value accordingly
    useEffect(() => {
        if(activeAutocomplete !== -1 && searchAutocomplete.length !== undefined && searchInputRef !== null){ 
            searchInputRef.current.value = searchAutocomplete[activeAutocomplete];
            setSearchInput(searchAutocomplete[activeAutocomplete]);
        }
        
    }, [activeAutocomplete, searchAutocomplete, setSearchInput])

    const handleSearchKeydownPress = (e) => {
        if(searchAutocomplete.length !== 0 && searchAutocomplete.length !== undefined){
            if(e.key === 'ArrowDown'){
                if(activeAutocomplete >= searchAutocomplete.length - 1){
                    setActiveAutocomplete(0);
                }
                else{
                    setActiveAutocomplete(prev => prev + 1);
                }
            }
            else if(e.key === 'ArrowUp'){
                if(activeAutocomplete <= 0){
                    setActiveAutocomplete(searchAutocomplete.length - 1);
                }
                else{
                    setActiveAutocomplete(prev => prev - 1);
                }
            }
        }
    }

    useEffect(() => {
        const keyDownHandler = event => {    
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, [handleSearch]);

    return(
        <div className="w-full h-[406px] md:h-[456px] flex flex-col heightFixCoverParent">

            <div className="flex flex-col flex-grow justify-center">
                <motion.div variants={introVariant} exit="exit" className="mb-10 md:mb-12 mt-24 md:mt-24 text-white max-w-[625px] heightFixCover">
                    <h1 className="mb-3 font-[500] text-3xl sm:text-4xl md:text-[40px]">{langContent.home.title?langContent.home.title:'TaskuTark on õppimisel abiks.'}</h1>
                    <p className="text-sm sm:text-base max-w-[63ch]">{langContent.home.intro?langContent.home.intro:'Haridusportaal õpilasele, õpetajale ja täiendõppijale.'}</p>
                </motion.div>

                {/* Core Search Section */}
                <motion.div className = "flex flex-col-reverse sm:flex-row bg-white rounded-[6px] items-center mb-6 max-w-[625px]"
                    variants={searchbarVariant} initial="hidden" animate="visible" exit="exit"
                    >
                        <motion.div variants={introVariant} exit="exit" className="flex w-full sm:w-fit justify-between">
                            {/* Type */}
                            <div ref = {topicDropdownRef} className="w-full border-t-[1px] sm:border-t-0 sm:border-r-[1px] relative">
                                <div className="p-4 flex justify-between px-4 md:py-6 sm:py-4 items-center text-sm w-full sm:w-[180px] font-[500] border-0 select_container select_container_types cursor-pointer" onClick={toggleSearchOptions}>
                                    <p className="text-[#0C1135] font-[500] text-[14px] leading-[16px] tracking-[0.05em] pointer-events-none">{type.name}</p>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="z-[2] select_options_types select_options hidden rounded-b-[8px] shadow-shadow3 flex flex-col absolute left-0 top-[45px] sm:top-[50px] md:top-[60px] bg-white w-full">
                                    {types.map((item, index) => {
                                        if(type.id !== item.id){
                                            return(
                                                <button key = {index} onClick={() => changeSearchType(item)} className = 'text-[#57546d] w-full text-left px-5 py-4 sm:px-4 sm:py-3 hover:text-[#6d53e4] hover:bg-[#ebeefe] font-[500] text-[14px] leading-[16px] tracking-[0.05em]'>
                                                    {item.name}
                                                </button>
                                            )
                                        }
                                        else return null;
                                    })}
                                </div>
                            </div>
                            {/* Search Button */}
                            <SearchButton className='flex sm:hidden rounded-br-[6px] w-[64px] h-[50px] md:h-[66px]' onClick={handleSearch}/>
                        </motion.div>

                        {/* Search Input */}
                        <div className = 'md:px-4 w-full sm:w-fit flex-grow relative' onKeyDown = {handleSearchKeydownPress}>
                        <input type="search" ref={searchInputRef} className="rounded-t p-4 w-full md:py-6 text-[#0C1135] border-0 custom_placeholder font-[500] text-[14px] leading-[16px] tracking-[0.05em]" placeholder={langContent.search.searchPlaceholder? langContent.search.searchPlaceholder:"Sisesta otsingusõna"}
                                value = {searchInput} onChange = {handleSearchInput} />
                            {searchAutocomplete.length !== 0 && searchAutocomplete.length &&
                                <div className="absolute autocomple_dialog left-0 top-[64px] rounded-b-[8px] bg-white flex flex-col w-full shadow-shadow3">
                                    {searchAutocomplete.map((item, index) => {
                                        return (
                                            <button key={index} onClick = {() => handleAutocompleteSearch(item)} className={`${activeAutocomplete === index && 'bg-[#ebeefe] text-[#6d53e4]'} px-2 sm:px-4 py-3 text-[#0C1135] text-left font-[500] text-[14px] leading-[16px] tracking-[0.05em] hover:opacity-80 ${index===searchAutocomplete.length-1 && 'rounded-b-[8px]'}`}>
                                                {item}
                                            </button>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                        {/* Search Button */}
                        <SearchButton className='hidden sm:flex rounded-r-[6px]' onClick={handleSearch}/>
                </motion.div>

            </div>      

        </div>
    )
}

export default Cover;