
import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";


import DataContextProvider from './context/DataContextProvider';
import SearchContextProvider from './context/SearchContext/SearchContextProvider';
import AnimatedRoutes from './AnimatedRoutes';
import LanguageContextProvider from './context/LanguageContext/LanguageContextProvider';
import {HelmetProvider} from 'react-helmet-async';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import EbookThemeContextProvider from './context/EbookThemeContent/EbookThemeContextProvider';

import TagManager from 'react-gtm-module'
import { useEffect, useRef } from 'react';


function App() {

  // fetch('https://api.aegarstile.ee/doctor_timestamps?clinic_id=1')
  // .then(res => res.json()
  // .then(data => console.log(data)))
  // .catch(err => console.log(err));

  const isRunned = useRef(false);

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }

  useEffect(() => {
    if(!isRunned.current){
      TagManager.initialize(tagManagerArgs)
    }
    return () => {
        isRunned.current = true;
    };
  }, [])

  return (
    <HelmetProvider>
      <div className="App">
        <Router>  
          <LanguageContextProvider>
            <DataContextProvider>
              <SearchContextProvider>
                  <EbookThemeContextProvider>
                    <AnimatedRoutes />
                  </EbookThemeContextProvider>
              </SearchContextProvider>
            </DataContextProvider>
          </LanguageContextProvider>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
