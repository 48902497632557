
import { useState, useContext, useEffect, useRef } from "react";
import SearchButton from "../../../components/Home/SearchButton/SearchButton";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import SearchContext from "../../../context/SearchContext/SearchConext";
import useQuery from '../../../hooks/useQuery';
import TypeButton from "../../../components/List/TypeButton";
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import TypesLoading from "../../../components/TypesLoading/TypesLoading";
import API from "../../../API/API";
import LanguageContext from "../../../context/LanguageContext/LanguageContext";
import Dropdown from "../../../components/Dropdown/Dropdown";
import DropdownEbookCats from "../../../components/Dropdown/DropdownEbookCategories";

const Cover = () => {

    const query = useQuery();
    const navigate = useNavigate();
    const {types, levels, typesLoading, extraOptions} = useContext(DataContext);
    const {searchInput, setSearchInput, type, setType, grade, testsOnly, reviewed, setGrade, subject, 
        setSubject, searchResultCounts, activeExtraOption1, activeExtraOption2, setActiveExtraOption1, setActiveExtraOption2, setTestsOnly} = useContext(SearchContext);
    const {language, langContent} = useContext(LanguageContext);
    const [activeLevel, setActiveLevel] = useState({subjects: []});
    const [searchAutocomplete, setSearchAutocomplete] = useState([]);
    const [activeAutocomplete, setActiveAutocomplete] = useState(-1);
    const [groupedSubjects, setGroupedSubjects] = useState([]);
    const [groupedLevels, setGroupedLevels] = useState([]);
    let labelExtra1 = false;

    const subjectDropdownRef = useRef(null);
    const gradesDropdownRef = useRef(null);
    const searchInputRef = useRef(null);
    const searchAutocompleteRef = useRef(null);

    if(type.slug === 'ebook'){
        labelExtra1 = (langContent.list.filterEbooks)? langContent.list.filterEbooks : 'Filtreeri e-raamatuid'
    }else if(type.slug === 'bite'){
        labelExtra1 = (langContent.list.filterBites)? langContent.list.filterBites : 'Filtreeri õpiampse'
    }
    useEffect(() => {
        
        if(type.slug !== '' && Object.keys(extraOptions).length !== 0){
            if(extraOptions[type.slug]){
                let extra1 = query.get(extraOptions[type.slug][0].param)
                let extra2 = (extraOptions[type.slug][1]?.param)? query.get(extraOptions[type.slug][1].param) : false; 
                if(extra1){
                    let activeExtra = extraOptions[type.slug][0].items.filter(item => String(item.slug) === extra1)[0]
                    if(activeExtra){
                        setActiveExtraOption1(activeExtra)
                    }
                    else{
                        setActiveExtraOption1(extraOptions[type.slug][0].items.filter(item => item.id === 0)[0]);
                    }
                }
                else{
                    setActiveExtraOption1(extraOptions[type.slug][0].items.filter(item => item.id === 0)[0]);
                }
                if(extra2){
                    let activeExtra = extraOptions[type.slug][1].items.filter(item => String(item.slug) === extra1)[0]
                    if(activeExtra){
                        setActiveExtraOption2(activeExtra)
                    }
                    else{
                        setActiveExtraOption2(extraOptions[type.slug][1].items.filter(item => item.id === 0)[0]);     
                    }
                }
                // else{
                //     setActiveExtraOption2(extraOptions[type.slug][1].items.filter(item => item.id === 0)[0]);
                // }
                // setActiveExtraOption2(extraOptions[type.slug][1].items.filter(item => item.id === 0)[0]);
            }
            else{
                setActiveExtraOption1({});
                setActiveExtraOption2({});
                query.delete('ebookPackage');
                query.delete('ebookCat');
                query.delete('biteCat');
                navigate(`/search?${query.toString()}`);
            }
        }

    }, [type, extraOptions, query, setActiveExtraOption1, setActiveExtraOption2, navigate])

    useEffect(() => {
        
        //we will set the Search Context with this useEffect, if the user changes search parameters on url.
        //clearing the histroy state because we want to load new search results and counts if page is reloaded.
        window.history.replaceState({}, document.title)

        let q = query.get("q");
        let type = query.get("type");
        let grade = query.get("grade");
        let subject = query.get("subject");
        let qtestsOnly = query.get('testsOnly');

        // ADVANCED FILTERS?
        // let testsOnly = query.get("testsOnly");
        // let reviewed = query.get("reviewed");

        //set searchInput
        if(q !== undefined && q !== 'undefined'){
            setSearchInput(q);
        }
        if(q === null || q === undefined){
            setSearchInput('');
        }
        
        //type filter and set type
        const typefilter = types.filter(t => t.slug === type);
        if(typefilter.length > 0){
            setType(typefilter[0])
        }
        // else setType(types[0]);

        if(qtestsOnly === 'true'){
            setTestsOnly(true);
        }
        else{
            setTestsOnly(false);
        }

        
        //gradefilter and subject filter
        const gradefilter = levels.filter(l => l.slug === grade);
        if(gradefilter.length > 0){
            setGrade(gradefilter[0]);
            setActiveLevel(gradefilter[0]);
            
            // subjectfilter
            const subjectfilter = gradefilter[0].subjects.filter(s => s.slug === subject);
            if(subjectfilter.length > 0){
                setSubject(subjectfilter[0]);
            }
            else setSubject(gradefilter[0].subjects[0]);
            // else setSubject(subject);
        }
        else{
            setGrade(levels[0]);
            setActiveLevel(levels[0]);
            // subjectfilter
            const subjectfilter = levels[0].subjects.filter(s => s.slug === subject);
            if(subjectfilter.length > 0){
                setSubject(subjectfilter[0]);
            }
            else setSubject(levels[0].subjects[0]);
        }
    
        
    }, [types, levels, query, testsOnly, reviewed, setGrade, setSearchInput, setSubject, setType, setTestsOnly, navigate]);


    useEffect(() => {
        const subjectsMap = JSON.parse(JSON.stringify(activeLevel.subjects));
        const gs = [];
        while(subjectsMap.length) {
            gs.push(subjectsMap.splice(0, 6));
        }
        setGroupedSubjects(gs);
        
        const levelsMap = [...levels];
        const gl = [];
        gl[0] = levelsMap.splice(0, 1);
        while(levelsMap.length) {
            gl.push(levelsMap.splice(0, 3));
        }
        setGroupedLevels(gl);

    }, [levels, activeLevel])

    const showAdvSearch = false;

    const toggleSearchOptions = (className) => {
        document.querySelector(`.select_options_${className}`).classList.toggle('hidden');
        document.querySelector(`.select_container_${className}`).classList.toggle('active');
    }
    const hideSearchOptions = (ref) => {
        ref.current.children[1].classList.add('hidden');
        ref.current.children[0].classList.remove('active');
    }
    useOutsideAlerter(gradesDropdownRef, hideSearchOptions);
    useOutsideAlerter(subjectDropdownRef, hideSearchOptions);

    const changeSearchType = (type) => {
        setType(type);
        query.set('type', type.slug);
        query.set('page', 1);
        navigate(`/search?${query.toString()}`);
    }

    const changeTestsOnly = () => {
        setTestsOnly(!testsOnly);
        if(!testsOnly){
            query.set('type', 'loetesti');
        }else{
            query.set('type', 'all');
        }
        query.set('testsOnly', !testsOnly); 
        query.set('page', 1);
        navigate(`/search?${query.toString()}`);
    }

    const changeActiveLevel = (level) => {
        setActiveLevel(level);
        setGrade(level);
        hideSearchOptions(gradesDropdownRef);

        query.set('grade', level.slug);
        query.set('page', 1);
        navigate(`/search?${query.toString()}`);
    }

    const changeSubject = (subject) => {
        setSubject(subject)
        hideSearchOptions(subjectDropdownRef);

        query.set('subject', subject.slug);
        query.set('page', 1);
        navigate(`/search?${query.toString()}`);
    }

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value);
        setActiveAutocomplete(-1);
        if(event.target.value.length > 2){
            API.getAutocompleteSuggestions(event.target.value)
            .then(res => {
                setSearchAutocomplete(res.data)
                searchAutocompleteRef && searchAutocompleteRef.current.classList.remove('hidden');
            })
            .catch(err => console.log(err));
        }
        else{
            setSearchAutocomplete([])
        }
    }
    const handleSearch = () => {
        query.set('q', searchInput);
        query.set('type', type.slug);
        query.set('grade', grade.slug);
        query.set('subject', subject.slug);
        query.set('lang', language);
        query.set('page', 1);
        navigate(`/search?${query.toString()}`);
    }

    const handleAutocompleteSearch = (input) => {
        document.querySelector('.autocomple_dialog').classList.add('hidden');
        setSearchInput(input);
        navigate(`/search?q=${input}&type=${type.slug}&grade=${grade.slug}&subject=${subject.slug}&lang=${language}`);
    }

    //this use effect will listen to the keydown event and changes the search input value accordingly
    useEffect(() => {
        if(activeAutocomplete !== -1 && searchAutocomplete.length !== undefined && searchInputRef !== null){ 
            searchInputRef.current.value = searchAutocomplete[activeAutocomplete];
            setSearchInput(searchAutocomplete[activeAutocomplete]);
        }
        
    }, [activeAutocomplete, searchAutocomplete, setSearchInput])

    const handleSearchKeydownPress = (e) => {
        if(searchAutocomplete.length !== 0 && searchAutocomplete.length !== undefined){
            if(e.key === 'ArrowDown'){
                if(activeAutocomplete >= searchAutocomplete.length - 1){
                    setActiveAutocomplete(0);
                }
                else{
                    setActiveAutocomplete(prev => prev + 1);
                }
            }
            else if(e.key === 'ArrowUp'){
                if(activeAutocomplete <= 0){
                    setActiveAutocomplete(searchAutocomplete.length - 1);
                }
                else{
                    setActiveAutocomplete(prev => prev - 1);
                }
            }
        }
    }
    const hideSearchAutocomplete = () => {
        searchAutocompleteRef.current.classList.add('hidden');
    }
    useOutsideAlerter(searchAutocompleteRef, hideSearchAutocomplete);


    const showMoreFilters = () => {
        document.querySelector('.more_filters').classList.add('show');
        document.querySelector('.App').classList.add('no_scroll');
    }

    const onExtra1OptionChange = (option) => {
        setActiveExtraOption1(option);
        query.set('page', 1);
        query.set(extraOptions[type.slug][0].param, option.slug);
        navigate(`/search?${query.toString()}`);
    }
    const onExtra2OptionChange = (option) => {
        setActiveExtraOption2(option);
        query.set('page', 1);
        query.set(extraOptions[type.slug][1].param, option.slug);
        navigate(`/search?${query.toString()}`);
    }


    let advSearchCountNum = 0;
    let advSearchCount;
    if(reviewed === true){
        advSearchCountNum++;
    }
    if(testsOnly){
        advSearchCountNum++;
    }
    advSearchCount = (advSearchCountNum > 0)? ' ('+advSearchCountNum+')' : false;

    return(

        <div className="w-full flex flex-col">

            <div className="flex flex-col flex-grow justify-center">
                
                    {/* Core Search Section */}
                    {/* <div className = "flex flex-col md:flex-row bg-white rounded-[6px] md:items-center border-[#ECE9FC] border-[1px]"> */}
                    <div className = "flex flex-col md:flex-row bg-white rounded-[6px] md:items-center border-[#ced1e1] border-[1px]">
                        {/* Search Input */}
                        <div className = 'relative z-[1] flex-grow rounded-l-[6px] border-r-[1px] border-b-[1px] border-[#ced1e1] md:border-b-0' onKeyDown = {handleSearchKeydownPress}>
                            <input type="search" ref={searchInputRef} className="w-full rounded-l-[6px] p-4 md:py-6 md:pl-6 pr-3 font-[500] text-[14px] leading-[16px] tracking-[0.05em] text-[#0C1135] border-0 custom_placeholder" placeholder={langContent.search.searchPlaceholder ? langContent.search.searchPlaceholder : "Sisesta otsingusõna"}
                                value = {searchInput} onChange = {handleSearchInput} onKeyDown = {(e) => e.key === 'Enter' && handleSearch()}/>
                                {searchAutocomplete.length !== 0 && searchAutocomplete.length &&
                                    <div ref={searchAutocompleteRef} className="absolute autocomple_dialog left-0 top-[64px] rounded-b-[8px] bg-white flex flex-col w-full shadow-shadow3">
                                        {searchAutocomplete.map((item, index) => {
                                            return (
                                                <button key={index} onClick = {() => handleAutocompleteSearch(item)} className={`${activeAutocomplete === index && 'bg-[#ebeefe] text-[#6d53e4]'} px-2 sm:px-4 py-3 text-[#0C1135] text-left font-[500] text-[14px] leading-[16px] tracking-[0.05em] hover:opacity-80 ${index===searchAutocomplete.length-1 && 'rounded-b-[8px]'}`}>
                                                    {item}
                                                </button>
                                            )
                                        })}
                                    </div>
                                }
                        </div>

                        <div className="flex h-full items-center">

                            {/* Grades */}
                            <div ref = {gradesDropdownRef} className="border-r-[1px] relative w-[45%] md:w-fit border-[#ced1e1]">
                                <div className="flex justify-between p-4 md:px-4 md:py-6 items-center text-sm w-full md:w-[180px] xl:w-[250px] font-[500] border-0 select_container select_container_grades cursor-pointer" 
                                    onClick={() => toggleSearchOptions('grades')}>
                                    <p className="text-[#0C1135] font-[500] text-[12px] sm:text-[14px] leading-[16px] tracking-[0.05em]">{activeLevel.name}</p>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="z-[2] select_options_grades select_options hidden rounded-b-[8px] shadow-shadow3 xl:py-4 sm:px-1 absolute right-0 top-[45px] sm:top-[50px] md:top-[65px] bg-white w-auto  overflow-auto max-h-[360px] xl:overflow-hidden xl:max-h-fit ">
                                    <div className="dropdownItems flex flex-col xl:flex-row">
                                        {groupedLevels.map((group, index) => {
                                            return (
                                                <div key={index} className="col">
                                                    {group.map((level, index) => {
                                                            return(
                                                                <button key = {index} onClick = {() => changeActiveLevel(level)} className = {`w-full text-left px-5 py-4 sm:px-4 sm:py-3 text-[#57546d] hover:text-[#6d53e4] font-[500] hover:bg-[#ebeefe] text-[14px] leading-[16px] tracking-[0.05em] sm:whitespace-nowrap ${level.slug === 'all' && 'text-black'}
                                                                ${activeLevel.slug === level.slug && 'text-[#6d53e4]'}
                                                                ${level.slug === 'all' && activeLevel.slug === level.slug && 'hidden'}
                                                                `}>
                                                                    {level.name}
                                                                </button>         
                                                            )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            {/* Subjects */}
                            {subject && 
                                <div ref={subjectDropdownRef} className="border-r-[1px] relative w-[45%] md:w-fit">
                                    <div className="flex justify-between p-4 md:px-4 md:py-6 items-center text-sm w-full md:w-[180px] xl:w-[250px] font-[500] border-0 select_container select_container_subjects cursor-pointer" 
                                        onClick={() => toggleSearchOptions('subjects')}>
                                        <p className="text-[#0C1135] font-[500] text-[12px] sm:text-[14px] leading-[16px] tracking-[0.05em]">{subject.name}</p>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="z-[2] select_options_subjects select_options hidden rounded-b-[8px] shadow-shadow3 xl:py-4 sm:px-1 absolute right-0 top-[45px] sm:top-[50px] md:top-[65px] bg-white w-auto overflow-auto max-h-[360px] xl:overflow-hidden xl:max-h-fit">
                                        
                                        <div className="dropdownItems flex flex-col xl:flex-row">
                                            {groupedSubjects && groupedSubjects.map((groupSubjects, index) => {
                                                return (
                                                    <div key={index} className="col">
                                                        {groupSubjects.map((item, index) => {
                                                                return(
                                                                    <button key = {index} onClick={() => changeSubject(item)} className = {`w-full text-left px-5 py-4 sm:px-4 sm:py-3 text-[#57546d] hover:text-[#6d53e4] font-[500] hover:bg-[#ebeefe] text-[14px] leading-[16px] tracking-[0.05em] sm:whitespace-nowrap ${item.slug === 'all' && 'text-black'} 
                                                                    ${subject.slug === item.slug && 'text-[#6d53e4]'}
                                                                    ${item.slug === 'all' && subject.slug === item.slug && 'hidden'}`}>
                                                                        {item.name}
                                                                    </button>
                                                                )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="h-[50px] md:h-[65px] w-[10%] md:w-auto">
                                {/* Search Button */}
                                <SearchButton  className='rounded-br-[6px] md:rounded-r-[6px] w-full h-full' onClick={handleSearch}/>
                            </div>
                           
                        </div>

                    </div>

                    <div className="flex mt-6">
                        {typesLoading ? <TypesLoading className = '' n = {7} /> 
                        :(
                        <div className="flex md:flex-wrap w-full overflow-x-auto">
                            {types.map((t, index) => {
                                return <TypeButton key = {index} type = {t} typeCount = {searchResultCounts[index] ? searchResultCounts[index]:null} active = {t.id === type.id ? true : false} onClick={() => changeSearchType(t)} />
                            })}

                            {/* ADVANCED SEARCH */}
                             { showAdvSearch && <div className="flex-grow flex justify-end p-2 md:p-3 md:pr-0 mb-3">
                                <button className="flex items-center text-[#0C1135] font-[500] text-[14px] leading-4 tracking-[0.05em]" onClick={showMoreFilters}>
                                    <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V14.5C0.5 15.0523 0.947715 15.5 1.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V1.5C15.5 0.947715 15.0523 0.5 14.5 0.5Z" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.66669 10.5H12.5" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M3.5 10.5H5.33333" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.66665 10.5C8.66665 10.8296 8.5689 11.1519 8.38576 11.426C8.20263 11.7 7.94233 11.9137 7.63779 12.0398C7.33324 12.166 6.99813 12.199 6.67483 12.1347C6.35153 12.0703 6.05456 11.9116 5.82147 11.6785C5.58838 11.4454 5.42965 11.1485 5.36534 10.8252C5.30103 10.5019 5.33404 10.1667 5.46018 9.8622C5.58633 9.55766 5.79995 9.29736 6.07403 9.11423C6.34811 8.93109 6.67035 8.83334 6.99998 8.83334C7.44201 8.83334 7.86593 9.00894 8.17849 9.3215C8.49105 9.63406 8.66665 10.058 8.66665 10.5V10.5Z" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.33333 5.5H3.5" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6666 5.50001C12.6666 5.17038 12.5689 4.84814 12.3858 4.57406C12.2026 4.29998 11.9423 4.08636 11.6378 3.96021C11.3332 3.83407 10.9981 3.80106 10.6748 3.86537C10.3515 3.92968 10.0546 4.08841 9.82147 4.3215C9.58838 4.55459 9.42965 4.85156 9.36534 5.17486C9.30103 5.49816 9.33404 5.83327 9.46018 6.13782C9.58633 6.44236 9.79995 6.70266 10.074 6.88579C10.3481 7.06893 10.6703 7.16668 11 7.16668C11.442 7.16668 11.8659 6.99108 12.1785 6.67852C12.4911 6.36596 12.6666 5.94204 12.6666 5.50001Z" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <span>{langContent.list.morefiltersText?langContent.list.morefiltersText:'Täpsem otsing'}{advSearchCount}</span>
                                </button>
                            </div>
                            } 
                        </div>
                        )}
                        
                    </div>
                    { // FILTER FOR EBOOKS and BITES
                    extraOptions && extraOptions[type.slug] &&
                        <div className=" flex mt-2 pt-6 border-t-white border-t items-center gap-3 flex-wrap">
                            <span className="text-[#57546D] text-sm tracking-wider">
                                {labelExtra1}
                            </span>
{<Dropdown items = {extraOptions[type.slug][0].items} activeItem = {activeExtraOption1} setActiveItem = {onExtra1OptionChange} label = {extraOptions[type.slug][0].label} className='sm:min-w-[300px] border-[#BCB7D7]'/>}

                            {/* {type.slug !== 'ebook' && ?
                                <Dropdown items = {extraOptions[type.slug][0].items} activeItem = {activeExtraOption1} setActiveItem = {onExtra1OptionChange} label = {extraOptions[type.slug][0].label} className='sm:min-w-[300px] border-[#BCB7D7]'/>
                            :
                                <DropdownEbookCats items = {extraOptions[type.slug][0].items} activeItem = {activeExtraOption1} setActiveItem = {onExtra1OptionChange} label = {extraOptions[type.slug][0].label} className='sm:min-w-[300px] border-[#BCB7D7]'/>
                            } */}


                            {/* <Dropdown items = {extraOptions[type.slug][1].items} activeItem = {activeExtraOption2} setActiveItem = {onExtra2OptionChange} label = {extraOptions[type.slug][1].label} className='sm:min-w-[300px] border-[#BCB7D7]'/> */}

                        </div>
                    }


            </div>      

        </div>
    )
}

export default Cover;