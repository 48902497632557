import React, { useContext, useEffect, useState } from "react";

import DataContext from "./DataContext";
import API from "../API/API";
import settingsjson from '../data/settings.json';
import LanguageContext from "./LanguageContext/LanguageContext";

function DataContextProvider(props) {
  const settings = settingsjson;
  const [types, setTypes] = useState([{name: '', slug: '', id: '', icon: '<div/>'}]);
  const [levels, setLevels] = useState([{name:'', slug: '', subjects: [{name: '', slug: '', icon: '<div/>'}]}]);
  const [extraOptions, setExtraOptions] = useState({});

  const [typesLoading, setTypesLoading] = useState(true);
  const [linkCopied, setLinkCopied] = useState(false);
  const {language} = useContext(LanguageContext);
  //const [user, setUser] = useState({"loggedIn" : true, "loaded" : true, "notifications": 9, "name": "John Doe", "avatar": "https://randomuser.me/api/portraits/"});
  const [user, setUser] = useState({"loaded" : false, "loggedIn" : null});

  useEffect(() => {
    setTypesLoading(true);
    API.getTypes(language)
      .then(res => setTypes(res.data))
      .catch(err => console.log(err));

    API.getExtraOptions()
      .then(res => {
        setExtraOptions(res.data)
      })
      .catch(err => console.log(err));

    API.getLevels(language)
    .then(res => {
      setLevels(res.data)
      setTypesLoading(false);
    })
    .catch(err => console.log(err));

    API.getUser()
    .then(res => setUser(res.data))
    .catch(err => console.log(err));

  }, [language]);


  const showLinkCopied = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  }

  return (
    <DataContext.Provider
      value={{
        types,
        setTypes,
        levels,
        setLevels,
        typesLoading,
        settings,
        user,
        linkCopied,
        showLinkCopied,
        extraOptions
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}

export default DataContextProvider;
