import Header from '../../components/Header/Header'
import Topbar from '../../components/Topbar/Topbar';
import BookInfo from './BookInfo';
import {Helmet} from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useContext, useState, useRef } from 'react';
import API from '../../API/API';
import './singleebookview.css'
import RelatedBooksSlider from './RelatedBooksSlider';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';
import BookInfoLoading from './BookInfoLoading';
import useFullScreen from '../../hooks/useFullScreen';
import DataContext from "../../context/DataContext";
import useQuery from '../../hooks/useQuery';

const SingleEbookView = () => {

    const isRunned = useRef(false);
    const [ebook, setEbook] = useState({related_ebooks: {items:[]}});
    const {id} = useParams()
    const [ebookLoading, setEbookLoading] = useState(true)
    const [epubInfo, setEpubInfo] = useState(false)
    const {settings} = useContext(DataContext);
    const query = useQuery();

    const {cancelFullScreen} = useFullScreen();

    useEffect(() => {
        if(!isRunned.current){
            cancelFullScreen()
            API.getEbook(id).then(res => {
                setEbook(res.data)
                setEbookLoading(false)
                let localStorageEpubInfo = localStorage.getItem('epubInfo')
                if(localStorageEpubInfo !== null)setEpubInfo(true)
                localStorage.removeItem('epubInfo')
            })
            .catch(err => console.log(err))

        }
        return () => {
            isRunned.current = true;
        };
    }, [id, cancelFullScreen])

    // let metaTitle = langContent.ebook.metaTitle ? langContent.ebook.metaTitle : 'TaskuTark';
    let metaTitle = 'TaskuTark';
    let metaTitle2 = 'E-raamat';
    let orderSuccess = (query.get('success') == 'order')? true : false;

    if(ebook.book_id === false){
        return <NotFoundPage />
    }
    else{
        return(
            <>
                <Helmet>
                    <title>{!ebookLoading ? ebook.title + " - " + metaTitle2 + " - " + metaTitle : metaTitle }</title>
                </Helmet>
                {settings.showTopGreenBar === true && <>
                    <div className="bg-[#28BF73]">
                        <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                            <Topbar link= {settings.showTopGreenBar_link} text = {settings.showTopGreenBar_label} />
                        </div>
                    </div>
                </>}
                <div className="relative singleEbookView_container">
                    <div className="absolute z-[99] w-full navbar_container bg-transparent text-[#EBEEFE]"
                        > 
                        <div className="max-w-[1504px] mx-auto w-full">
                            <Header type = 'Topic' className='type_2 w-full sm:py-3 md:bg-transparent text-black mx-auto px-[3vw] lg:px-[32px] xl:px-[32px]' />
                        </div>
                    </div>
                    <div className="pt-20 md:pt-32 pb-14"> 
                        <div className="max-w-[1504px] mx-auto w-full">
                            <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full mb-12">
                                {ebookLoading ? <BookInfoLoading />
                                :<>
                                    {orderSuccess && <div className="max-w-[1200px] text-[#0C1135] mx-auto p-4 bg-[#EAFBF2] mb-4 rounded-lg border border-[#28BF73] text-normal mb-8"><h4 className="text-xl font-medium mb-2">Tellimus tehtud!</h4>Alusta raamatu lugemist klõpsates nupul "Loe raamatut".</div>}
                                    {epubInfo && <div className="max-w-[1200px] mx-auto p-4 bg-[#FFF5E5] text-[#0C1135] mb-4 rounded-lg border border-[#FFA012] text-normal mb-8">Ligipääs puudub. Palun <a className="text-[#6D53E4] hover:underline" href={ebook.buy}>laenuta raamat</a>, et alustada lugemist.</div>}
                                    <BookInfo book={ebook} />
                                </>}
                            </div>
                            <div className='px-[3vw] lg:px-[32px] xl:px-[32px]'>
                                <div className='md:flex justify-between items-center mb-6'>
                                    <h2 className='mb-2 md:md-0 font-[500] text-[20px] leading-[140%]'>
                                        Sulle võiks veel huvi pakkuda
                                    </h2>
                                    <Link to="/search?type=ebook" className='text-[14px] font-[500] leading-4 tracking-[0.05em] text-[#6D53E4] flex items-center'>
                                        <span className='mr-2'>Kõik e-raamatud</span>
                                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.625 6.125L12.125 6.125" stroke="#6D53E4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.125 11.375L12.375 6.125L7.125 0.875" stroke="#6D53E4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                </div>
                                {ebookLoading ? (
                                    <div className='flex justify-center items-center overflow-hidden w-full'>
                                        <div className='min-w-[300px] h-[300px] mr-3 shimmerBG' />
                                        <div className='min-w-[300px] h-[300px] mr-3 shimmerBG' />
                                        <div className='min-w-[300px] h-[300px] mr-3 shimmerBG' />
                                        <div className='min-w-[300px] h-[300px] mr-3 shimmerBG' />
                                        <div className='min-w-[300px] h-[300px] shimmerBG' />
                                    </div>
                                ):(             
                                    <RelatedBooksSlider ebook={ebook} loading = {ebookLoading}/>    
                                )}
                            
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SingleEbookView;