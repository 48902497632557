

import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import LanguageContext from '../../context/LanguageContext/LanguageContext';
import SearchContext from "../../context/SearchContext/SearchConext";
import API from "../../API/API";
import DataContext from '../../context/DataContext';
import MobileHeader from './MobileHeader';
import useOutsideAlerter from "../../hooks/useOutsideAlerter";


const Navbar = ({className, type, active_level, active_subject}) => {

    const navigate = useNavigate();
    const {types, levels, settings, user, setUser} = useContext(DataContext);
    const {langContent, language} = useContext(LanguageContext);
    const {searchInput, setSearchInput, grade, setGrade, subject, setSubject} = useContext(SearchContext);
    const [activeLevel, setActiveLevel] = useState({subjects: []});
    const subjectDropdownRef = useRef(null);
    const gradesDropdownRef = useRef(null);
    const userDropdownRef = useRef(null);
    const headerType = type;
    const [userDropVisible, setUserDropVisible] = useState(false);

    const handleScroll = useCallback(() => {
        const navbar = document.querySelector('.navbar');
        const navbarContainer = document.querySelector('.navbar_container');
        if(navbarContainer && navbarContainer.length > 0){
            if (window.scrollY > 10) {
            navbar.classList.add('navbar_scrolled');
            navbarContainer.classList.add('navbar_container_scrolled');
            document.body.classList.add('content_scrolled');
            }
            else{
                navbar.classList.remove('navbar_scrolled');
                navbarContainer.classList.remove('navbar_container_scrolled');
                document.body.classList.remove('content_scrolled');
            } 
        }
    }, []);

    var lastScrollTop = 0;
    window.addEventListener("scroll", function () {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
        document.body.classList.remove("scrolled_up");
        document.body.classList.add("scrolled_down");
    } else {
        document.body.classList.remove("scrolled_down");
        document.body.classList.add("scrolled_up");
    }
    lastScrollTop = st <= 0 ? 0 : st;
    }, false);

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

        //we will set the Search Context with this useEffect, if the user changes search parameters on url. 

        //set searchInput
        setSearchInput("")
        
        //gradefilter and subject filter
        const gradefilter = levels.filter(l => l.slug === active_level);
        if(gradefilter.length > 0){
            setGrade(gradefilter[0]);
            setActiveLevel(gradefilter[0]);
            
            // subjectfilter
            const subjectfilter = gradefilter[0].subjects.filter(s => s.slug === active_subject);
            if(subjectfilter.length > 0){
                setSubject(subjectfilter[0]);
            }
            else setSubject(gradefilter[0].subjects[0]);
        }
        else{
            setGrade(levels[0]);
            setActiveLevel(levels[0]);
            setSubject(levels[0].subjects[0]);
        }
    }, [handleScroll, types, headerType, levels, active_level, active_subject, setGrade, setSearchInput, setSubject]);

    const openMenu = () => {
        document.querySelector('.mobile_menu').classList.add('show_mobile_menu');
        document.querySelector('.App').classList.add('no_scroll');
    }

    const toggleSearchOptions = (className) => {
        document.querySelector(`.select_options_${className}`).classList.toggle('hidden');
    }

    const hideSearchOptions = (ref) => {
        ref.current.children[1].classList.add('hidden');
    }
    const hideUserDrop = (ref) => {
        setUserDropVisible(false);
    }
    useOutsideAlerter(gradesDropdownRef, hideSearchOptions);
    useOutsideAlerter(subjectDropdownRef, hideSearchOptions);
    useOutsideAlerter(userDropdownRef, hideUserDrop);

    const changeActiveLevel = (level) => {
        setActiveLevel(level);
        setGrade(level);
        hideSearchOptions(gradesDropdownRef);
    }

    const changeSubject = (subject) => {
        setSubject(subject)
        hideSearchOptions(subjectDropdownRef);
    }


    const handleSearch = () => {
        navigate(`/search?q=${searchInput}&grade=${grade.slug}&subject=${subject.slug}&lang=${language}`);
    }
    
    const showUserDrop = event => {
        setUserDropVisible(current => !current);
      };

    const handleLogOut = () => {
        API.logOutUser()
        .then(res => {
            // todo > refresh HEADER
            window.location.href = 'https://www.taskutark.ee/auth.php?loggedout=true'; 
            return null;
        })
        .catch(err => console.log(err));
    }    

    return(
        <nav className={`navbar border-gray-200 py-2 mx-auto ${className}`}>
            <div className="flex flex-wrap justify-between items-center mx-auto">
                <Link to="/" className="flex items-center w-[40px] h-[40px] mr-3" aria-label="TaskuTark on õppimisel abiks">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.5918 35.5919C39.5918 37.796 37.8775 39.5919 35.7551 39.5919H4.24489C2.12244 39.5919 0.408157 37.796 0.408157 35.5919V4.4082C0.408157 2.20412 2.12244 0.408203 4.24489 0.408203H35.7551C37.8775 0.408203 39.5918 2.20412 39.5918 4.4082V35.5919Z" fill="#28BF73"/>
                        <path d="M35.7551 40H4.2449C1.91837 40 0 38.0408 0 35.5918V4.40816C0 1.95918 1.91837 0 4.2449 0H35.7551C38.0816 0 40 1.95918 40 4.40816V35.5918C40 38.0408 38.0816 40 35.7551 40ZM4.2449 0.816327C2.36735 0.816327 0.816327 2.40816 0.816327 4.40816V35.5918C0.816327 37.551 2.36735 39.1837 4.2449 39.1837H35.7551C37.6327 39.1837 39.1837 37.5918 39.1837 35.5918V4.40816C39.1837 2.40816 37.6327 0.816327 35.7551 0.816327H4.2449Z" fill="#187747"/>
                        <path d="M26 7.06128L23.6734 7.26536C23.551 7.26536 23.5102 7.34699 23.5102 7.46944C23.4694 8.1225 22.9388 8.69393 22.2857 8.73475C21.5918 8.77557 21.0204 8.32659 20.8571 7.67352C20.8163 7.59189 20.7347 7.51026 20.653 7.51026L18.3265 7.71434C18.2041 7.71434 18.1632 7.79597 18.1632 7.91842C18.1224 8.57148 17.5918 9.14291 16.9388 9.18373C16.2449 9.22454 15.6734 8.77557 15.5102 8.1225C15.4694 8.04087 15.3877 7.95924 15.3061 7.95924L12.9796 8.16332C12.8979 8.16332 12.7755 8.24495 12.7755 8.3674C12.7347 8.97965 12.3265 9.51026 11.7143 9.63271C11.6734 9.63271 11.5918 9.67352 11.551 9.67352L12.8163 25.2654L28.9796 23.9592L27.7143 8.3674C27.0204 8.40822 26.449 7.95924 26.2857 7.30618C26.1632 7.14291 26.0816 7.06128 26 7.06128Z" fill="white"/>
                        <path d="M10.2857 12.6123V26.245C10.2857 26.4899 10.4082 26.7348 10.6123 26.8572L18.6939 32.6939C19.4694 33.2654 20.4898 33.2654 21.2653 32.6939L29.3878 26.8572C29.5919 26.7348 29.7143 26.4899 29.7143 26.245V12.6123H10.2857Z" fill="#28BF73"/>
                        <path d="M20 33.7143C19.4286 33.7143 18.8572 33.551 18.3674 33.1837L10.2449 27.3469C9.87757 27.102 9.67349 26.6939 9.67349 26.2449V12.6122C9.67349 12.449 9.75512 12.2857 9.83676 12.1633C9.91839 12.0408 10.1225 12 10.2857 12H29.7143C30.0408 12 30.3265 12.2857 30.3265 12.6122V26.2449C30.3265 26.6939 30.1225 27.102 29.7551 27.3469L21.6327 33.1837C21.1429 33.551 20.5714 33.7143 20 33.7143ZM10.898 13.2245V26.2449C10.898 26.2857 10.9388 26.3265 10.9388 26.3673L19.0204 32.2041C19.5919 32.6122 20.3266 32.6122 20.898 32.2041L29.0204 26.3673C29.0612 26.3265 29.0612 26.2857 29.0612 26.2449V13.2245H10.898Z" fill="#187747"/>
                        <path d="M28.3265 17.9591C28.1224 17.9591 27.9184 17.8775 27.7959 17.6734C27.5918 17.3877 27.6735 17.0203 27.9592 16.8163C28.8163 16.2448 29.3469 15.9591 29.3877 15.9183C29.6735 15.755 30.0408 15.8367 30.2041 16.1632C30.3673 16.4489 30.2857 16.8163 29.9592 16.9795C29.9592 16.9795 29.4286 17.2652 28.6122 17.8367C28.5714 17.9183 28.449 17.9591 28.3265 17.9591Z" fill="#187747"/>
                        <path d="M23.755 21.3878C23.5917 21.3878 23.3876 21.3062 23.306 21.1837C23.1019 20.9388 23.1019 20.5306 23.3876 20.3266C24.0407 19.796 24.6938 19.2653 25.3876 18.7347C25.6734 18.5306 26.0407 18.5715 26.2448 18.8572C26.4489 19.1429 26.408 19.5102 26.1223 19.7143C25.4693 20.2041 24.7754 20.7347 24.1631 21.2653C24.0407 21.3062 23.8774 21.3878 23.755 21.3878Z" fill="#187747"/>
                        <path d="M20.2449 24.6529H19.7551C19.5918 24.6529 19.4285 24.5713 19.3061 24.4488C18.9388 24.0815 18.5714 23.6733 18.1632 23.306C17.9183 23.0611 17.9183 22.6937 18.1632 22.4488C18.4081 22.2039 18.7755 22.2039 19.0204 22.4488C19.3469 22.7753 19.6734 23.1019 20 23.4284C20.3265 23.1019 20.653 22.7753 20.9796 22.4488C21.2245 22.2039 21.5918 22.2039 21.8367 22.4488C22.0816 22.6937 22.0816 23.0611 21.8367 23.306C21.4285 23.6733 21.0612 24.0815 20.6939 24.4488C20.5714 24.5713 20.4081 24.6529 20.2449 24.6529Z" fill="#187747"/>
                        <path d="M16.2448 21.3876C16.1223 21.3876 15.9591 21.3468 15.8366 21.2243C15.2244 20.6937 14.5305 20.1631 13.8774 19.6733C13.5917 19.4692 13.5509 19.1019 13.755 18.8162C13.9591 18.5304 14.3264 18.4896 14.6121 18.6937C15.306 19.2243 15.9591 19.7549 16.6121 20.2855C16.857 20.4896 16.8978 20.8978 16.6938 21.1427C16.6121 21.306 16.4081 21.3876 16.2448 21.3876Z" fill="#187747"/>
                        <path d="M11.6734 17.9591C11.551 17.9591 11.4285 17.9183 11.3469 17.8775C10.5306 17.3469 9.99994 17.0203 9.99994 17.0203C9.71423 16.8571 9.59178 16.4897 9.75504 16.204C9.91831 15.9183 10.2857 15.7958 10.5714 15.9591C10.6122 15.9591 11.102 16.2856 11.9999 16.8571C12.2857 17.0612 12.3673 17.4285 12.1632 17.7142C12.0816 17.8775 11.8775 17.9591 11.6734 17.9591Z" fill="#187747"/>
                        <path d="M39.5 35.55C39.5 37.75 37.8 39.5 35.65 39.5H4.35C2.2 39.5 0.5 37.7 0.5 35.55V4.45C0.5 2.3 2.2 0.5 4.35 0.5H35.7C37.8 0.5 39.5 2.3 39.5 4.45V35.55Z" fill="#28BF73"/>
                        <path d="M35.65 40H4.35C1.95 40 0 38 0 35.55V4.45C0 2 1.95 0 4.35 0H35.7C38.05 0 40 2 40 4.45V35.5C40 38 38.05 40 35.65 40ZM4.35 1C2.5 1 1 2.55 1 4.45V35.5C1 37.45 2.5 39 4.35 39H35.7C37.55 39 39.05 37.45 39.05 35.55V4.45C39 2.55 37.5 1 35.65 1H4.35Z" fill="#187747"/>
                        <path d="M28.5 8.8501C28.25 8.8501 28 8.7501 27.8 8.5501C27.65 8.4001 27.55 8.2001 27.45 8.0001C27.45 7.9001 27.35 7.8501 27.25 7.8501L25.35 8.0501C25.25 8.0501 25.2 8.1501 25.15 8.2501C25.15 8.8001 24.7 9.2501 24.15 9.3001C24.1 9.3001 24.05 9.3001 23.95 9.3001C23.9 9.3001 23.9 9.3001 23.85 9.3001C23.8 9.3001 23.8 9.3001 23.75 9.3001C23.7 9.3001 23.7 9.3001 23.65 9.2501C23.6 9.2501 23.6 9.2501 23.55 9.2001C23.5 9.2001 23.5 9.1501 23.45 9.1501C23.4 9.1501 23.4 9.1001 23.35 9.1001C23.3 9.1001 23.3 9.0501 23.3 9.0501C23.25 9.0501 23.25 9.0001 23.2 9.0001L23.15 8.9501C23.1 8.9001 23.1 8.9001 23.05 8.8501L23 8.8001C23 8.7501 22.95 8.7501 22.95 8.7001V8.6501C23 8.6001 23 8.5501 23 8.5001V8.4501C22.95 8.4001 22.9 8.4001 22.85 8.4001L21 8.6001C20.9 8.6001 20.85 8.7001 20.8 8.8001C20.8 9.3501 20.35 9.8001 19.8 9.8501C19.65 9.8501 19.5 9.8501 19.4 9.8001C19.15 9.7501 18.9 9.5501 18.75 9.3501C18.65 9.2501 18.6 9.1001 18.6 9.0001C18.6 8.9001 18.5 8.8501 18.4 8.8501L16.45 9.0001H16.4C16.35 9.0501 16.3 9.1001 16.3 9.1501C16.3 9.2001 16.3 9.3001 16.3 9.3501V9.4001C16.3 9.4501 16.3 9.5001 16.25 9.5001C16.25 9.5001 16.25 9.5501 16.2 9.5501C16.2 9.6001 16.15 9.6001 16.15 9.6501L16.1 9.7001C16.1 9.7501 16.05 9.7501 16.05 9.8001L16 9.8501C16 9.8501 15.95 9.9001 15.9 9.9001L15.85 9.9501C15.8 9.9501 15.8 10.0001 15.75 10.0001C15.7 10.0001 15.7 10.0501 15.65 10.0501C15.6 10.0501 15.6 10.1001 15.55 10.1001C15.5 10.1001 15.5 10.1001 15.45 10.1501C15.4 10.1501 15.35 10.2001 15.25 10.2001C14.7 10.2501 14.2 9.9001 14.05 9.3501C14.05 9.2501 13.95 9.2001 13.85 9.2001L12 9.5001C11.9 9.5001 11.85 9.6001 11.8 9.7001C11.8 9.9501 11.7 10.1501 11.55 10.3501C11.4 10.5501 11.2 10.7001 10.95 10.7501C10.85 10.7501 10.8 10.8501 10.8 10.9501L12 22.1001L16.45 21.6501L25.35 20.7001L29.8 20.2501L28.6 9.0501C28.65 8.9501 28.6 8.8501 28.5 8.8501Z" fill="white"/>
                        <path d="M10 13.1001V26.4001C10 26.7001 10.15 27.0001 10.4 27.2001L18.8 33.2001C19.5 33.7001 20.45 33.7001 21.15 33.2001L29.55 27.2001C29.8 27.0001 29.95 26.7001 29.95 26.4001V13.1001C29.95 12.8001 29.7 12.6001 29.45 12.6001H10.5C10.25 12.6001 10 12.8501 10 13.1001Z" fill="#28BF73"/>
                        <path d="M20 34.1001C19.5 34.1001 18.95 33.9501 18.55 33.6501L10.15 27.6501C9.75 27.3501 9.5 26.9001 9.5 26.4501V13.1501C9.5 12.6001 9.95 12.1501 10.5 12.1501H29.5C30.05 12.1501 30.5 12.6001 30.5 13.1501V26.4501C30.5 26.9501 30.25 27.4001 29.85 27.6501L21.45 33.6501C21.05 33.9501 20.5 34.1001 20 34.1001ZM10.5 13.1001V26.4001C10.5 26.5501 10.6 26.7001 10.7 26.8001L19.1 32.8001C19.65 33.2001 20.35 33.2001 20.9 32.8001L29.3 26.8001C29.45 26.7001 29.5 26.5501 29.5 26.4001V13.1001H10.5Z" fill="#187747"/>
                        <path d="M10 16.3503C10 16.3503 10.75 16.5003 11.9 16.7503L10 16.3503Z" fill="#28BF73"/>
                        <path d="M11.9001 17.2502C11.8501 17.2502 11.8001 17.2502 11.8001 17.2502C10.7001 17.0002 9.95006 16.8502 9.95006 16.8502C9.70006 16.8002 9.50006 16.5502 9.55006 16.2502C9.60006 16.0002 9.85006 15.8002 10.1501 15.8502C10.2001 15.8502 10.9501 16.0002 12.0501 16.2502C12.3001 16.3002 12.5001 16.6002 12.4001 16.8502C12.3001 17.1002 12.1001 17.2502 11.9001 17.2502Z" fill="#187747"/>
                        <path d="M13.7501 17.2502C14.4001 17.4502 15.1001 17.6502 15.7501 17.9002L13.7501 17.2502Z" fill="#28BF73"/>
                        <path d="M15.7501 18.3503C15.7001 18.3503 15.6501 18.3503 15.6001 18.3003C15.0001 18.1003 14.3001 17.8503 13.6001 17.7003C13.3501 17.6003 13.2001 17.3503 13.2501 17.1003C13.3001 16.8503 13.6001 16.7003 13.8501 16.7503C14.5501 16.9503 15.2501 17.1503 15.9001 17.4003C16.1501 17.5003 16.3001 17.8003 16.2001 18.0503C16.1501 18.2503 15.9501 18.3503 15.7501 18.3503Z" fill="#187747"/>
                        <path d="M30.0002 16.3503C30.0002 16.3503 29.2502 16.5003 28.1002 16.7503L30.0002 16.3503Z" fill="#28BF73"/>
                        <path d="M28.1001 17.2502C27.8501 17.2502 27.6501 17.1002 27.6001 16.8502C27.5501 16.6002 27.7001 16.3002 27.9501 16.2502C29.1001 16.0002 29.8501 15.8502 29.8501 15.8502C30.1001 15.8002 30.4001 16.0002 30.4501 16.2502C30.5001 16.5002 30.3001 16.8002 30.0501 16.8502C30.0501 16.8502 29.3001 17.0002 28.2001 17.2502C28.2001 17.2502 28.1501 17.2502 28.1001 17.2502Z" fill="#187747"/>
                        <path d="M26.25 17.2502C25.6 17.4502 24.9 17.6502 24.25 17.9002L26.25 17.2502Z" fill="#28BF73"/>
                        <path d="M24.25 18.3501C24.05 18.3501 23.85 18.2001 23.8 18.0001C23.7 17.7501 23.85 17.4501 24.1 17.3501C24.75 17.1001 25.45 16.9001 26.15 16.7001C26.4 16.6001 26.7 16.8001 26.75 17.0501C26.85 17.3001 26.65 17.6001 26.4 17.6501C25.7 17.8501 25.05 18.0501 24.4 18.2501C24.35 18.3501 24.3 18.3501 24.25 18.3501Z" fill="#187747"/>
                        <path d="M20.0001 19.9501C19.3501 19.4501 18.5501 19.0001 17.7001 18.6501L20.0001 19.9501Z" fill="#28BF73"/>
                        <path d="M20 20.4502C19.9 20.4502 19.8 20.4002 19.7 20.3502C19.1 19.9002 18.4 19.5002 17.5 19.1002C17.25 19.0002 17.15 18.7002 17.25 18.4502C17.35 18.2002 17.65 18.1002 17.9 18.2002C18.85 18.6502 19.65 19.1002 20.3 19.6002C20.5 19.7502 20.55 20.1002 20.4 20.3002C20.3 20.4002 20.15 20.4502 20 20.4502Z" fill="#187747"/>
                        <path d="M21.3 19.1502C21.65 18.9502 21.95 18.8002 22.3 18.6502L22.7 18.4502" fill="#28BF73"/>
                        <path d="M21.3 19.6502C21.1 19.6502 20.95 19.5502 20.85 19.4002C20.7 19.1502 20.8 18.8502 21.05 18.7002C21.35 18.5002 21.75 18.3502 22.1 18.2002L22.5 18.0002C22.75 17.9002 23.05 18.0002 23.15 18.2502C23.25 18.5002 23.15 18.8002 22.9 18.9002L22.5 19.1002C22.15 19.2502 21.8 19.4002 21.5 19.6002C21.45 19.6502 21.35 19.6502 21.3 19.6502Z" fill="#187747"/>
                        <path d="M17.3001 18.4502C18.3001 18.9002 19.2501 19.4002 20.0001 19.9502L17.3001 18.4502Z" fill="#28BF73"/>
                        <path d="M20 20.4502C19.9 20.4502 19.8 20.4002 19.7 20.3502C19.05 19.8502 18.15 19.3502 17.1 18.9002C16.85 18.8002 16.75 18.5002 16.85 18.2502C16.95 18.0002 17.25 17.9002 17.5 18.0002C18.65 18.5002 19.6 19.0002 20.3 19.5502C20.5 19.7002 20.55 20.0502 20.4 20.2502C20.3 20.4002 20.15 20.4502 20 20.4502Z" fill="#187747"/>
                        <path d="M18.8501 21.1002C18.5001 21.5502 18.3001 22.1002 18.3001 22.6502C18.3001 23.6002 19.0501 24.3502 20.0001 24.3502C20.9501 24.3502 21.7001 23.6002 21.7001 22.6502C21.7001 21.6502 21.0501 20.7502 20.0001 20.0002" fill="#28BF73"/>
                        <path d="M20.0001 24.8002C18.8001 24.8002 17.8001 23.8002 17.8001 22.6002C17.8001 21.9502 18.0001 21.3502 18.4001 20.8002C18.5501 20.5502 18.8501 20.5002 19.1001 20.7002C19.3501 20.8502 19.4001 21.1502 19.2001 21.4002C18.9001 21.8002 18.7501 22.2002 18.7501 22.6502C18.7501 23.3002 19.3001 23.8502 19.9501 23.8502C20.6001 23.8502 21.1501 23.3002 21.1501 22.6502C21.1501 21.9002 20.6501 21.1002 19.6501 20.4002C19.4501 20.2502 19.4001 19.9002 19.5501 19.7002C19.7001 19.5002 20.0501 19.4502 20.2501 19.6002C21.5001 20.5502 22.1501 21.6002 22.1501 22.6502C22.2001 23.8502 21.2001 24.8002 20.0001 24.8002Z" fill="#187747"/>
                        </svg>
                </Link>

                  {/* Core Search Section */}
                  {type === "topic" && activeLevel &&
                        <>
                  <div className = "flex-grow xl:max-w-[848px] flex flex-col md:flex-row bg-white rounded-[6px] md:items-center border-[#ECE9FC] border-[1px]">
                    {/* Search Input */}
                    <div className = 'flex-grow rounded-l-[6px] border-r-[1px] border-b-[1px] md:border-b-0'>
                        <input type="search" className="w-full rounded-l-[6px] py-3  pl-6 pr-3 font-[500] text-[14px] leading-[16px] tracking-[0.05em] text-[#0C1135] border-0 custom_placeholder" placeholder="Sisesta otsingusõna"
                            value = {searchInput} onChange = {(event) => setSearchInput(event.target.value)} onKeyDown = {(e) => e.key === 'Enter' && handleSearch()}/>
                    </div>

                    <div className="flex h-full items-center">


                        {/* Grades */}
                        <div className="border-r-[1px] relative w-[45%] md:w-fit" ref = {gradesDropdownRef}>
                            <div className="flex justify-between px-3 py-3 items-center text-sm w-full md:w-[180px] font-[500] border-0 select_container cursor-pointer" 
                                onClick={() => toggleSearchOptions('grades')}>
                                <p className="text-[#0C1135] font-[500] text-[14px] leading-[16px] tracking-[0.05em]">{activeLevel.name}</p>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="z-[2] select_options_grades select_options hidden rounded-b-[8px] shadow-shadow3 flex flex-col absolute left-0 top-[40px] sm:top-[43px] bg-white w-full">
                                {levels.map((level, index) => {
                                    return(
                                        <button key = {index} onClick = {() => changeActiveLevel(level)} className = 'w-full text-left px-2 sm:px-4 py-3 text-[#57546d] hover:text-[#6d53e4] font-[500] text-[14px] hover:bg-[#ebeefe] leading-[16px] tracking-[0.05em]' title = {level.description}>
                                            {level.name}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                        {/* Subjects */}
                        <div className="border-r-[1px] relative w-[45%] md:w-fit" ref = {subjectDropdownRef} >
                            <div className="flex justify-between px-3 py-3 items-center text-sm w-full md:w-[180px] font-[500] border-0 select_container cursor-pointer" 
                                onClick={() => toggleSearchOptions('subjects')}>
                                <p className="text-[#0C1135] font-[500] text-[14px] leading-[16px] tracking-[0.05em]">{subject.name}</p>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="z-[2] select_options_subjects select_options hidden rounded-b-[8px] shadow-shadow3 flex flex-col absolute left-0 top-[40px] sm:top-[43px] bg-white w-full">
                                {activeLevel.subjects.map((subject, index) => {
                                    return(
                                        <button key = {index} onClick={() => changeSubject(subject)} className = 'w-full text-left px-2 sm:px-4 py-3 text-[#57546d] hover:text-[#6d53e4] font-[500] text-[14px] hover:bg-[#ebeefe] leading-[16px] tracking-[0.05em]'>
                                            {subject.name}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="">
                            {/* Search Button */}
                            <button className="w-10 h-10 flex justify-center items-center hover:bg-primary topic_search_btn" onClick={handleSearch}>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.57327 9.08464C2.1673 10.4824 3.29224 11.5869 4.70063 12.1552C6.10903 12.7235 7.68549 12.7091 9.08323 12.1151C10.481 11.521 11.5855 10.3961 12.1538 8.98769C12.7221 7.5793 12.7077 6.00283 12.1136 4.60509C11.5196 3.20735 10.3947 2.10283 8.98628 1.53452C7.57789 0.966206 6.00142 0.980652 4.60368 1.57468C3.20594 2.1687 2.10143 3.29365 1.53311 4.70204C0.964801 6.11043 0.979247 7.6869 1.57327 9.08464V9.08464Z" stroke="#6D53E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.8926 10.8926L15.3338 15.3344" stroke="#6D53E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                  </div>
                  </>
                  }

                <button onClick = {openMenu} type="button" className="inline-flex items-center ml-3 text-sm text-white rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="mobile-menu" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>                    
                    <svg className="menu_open_icon w-8 h-8" viewBox="0 0 32 32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(1.3333333333333333,0,0,1.3333333333333333,0,0)"><path d="M2.25 18.003L21.75 18.003" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><path d="M2.25 12.003L21.75 12.003" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><path d="M2.25 6.003L21.75 6.003" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path></g></svg>

                    <svg className="menu_close_icon hidden w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>                  

                <MobileHeader />
                <ul className="hidden md:flex flex-col mt-4 md:flex-row md:mt-0 text-[16px] font-[500] leading-[1rem]">
                    {user.loaded && settings.navHeader &&
                        <>
                        <li className='flex items-center mb-4 md:mb-0 text-[16px]'>
                            <a href={settings.navHeader[0].linkTarget} target = '_blank' rel="noreferrer" className = 'px-2 md:py-2 mr-6 hover:opacity-80'>
                                {langContent.header.school ? langContent.header.school : 'Koolidele'}
                            </a>
                        </li>
                    </>
                    }
                    {
                    user.loaded && user.loggedIn === true ? (
                        <>
                        {user.showOPINav === true &&
                            <>
                            <li className='flex items-center mb-4 md:mb-0 text-[14px]'>
                                <a href="https://www.taskutark.ee/opi-start/" className='mr-2 px-6 py-2.5 rounded-[24px] bg-[#EBEEFE] border-[2px] border-transparent font-[500] text-primary leading-[1rem] hover:bg-primary hover:text-white hover:border-primary'>
                                    {langContent.header.desktop ? langContent.header.desktop : 'ÕPI töölaud'}
                                </a>
                            </li>
                            </>
                        }
                        {user.showAdminNav === true &&
                            <>
                            <li className='flex items-center mb-4 md:mb-0 text-[14px]'>
                                <a href="https://www.taskutark.ee/wp-admin/index.php" className='mr-2 px-6 py-2.5 rounded-[24px] bg-[#EBEEFE] border-[2px] border-transparent font-[500] text-primary leading-[1rem] hover:bg-primary hover:text-white hover:border-primary'>
                                    Administratsioon
                                </a>
                            </li>
                            </>
                        }
                        <li className={`flex items-center mb-4 md:mb-0 loggedin_dropdown ${userDropVisible ? 'showUserDrop' : ''}`} ref = {userDropdownRef}>
                            <div className={`flex items-center ml-3 my-2 ${type==='Home'?'text-white':'text-[#0C1135]'}`}>
                                <p onClick={showUserDrop} className="flex mr-2 text-[16px] font-[500] hover:opacity-80 cursor-pointer loggedin_dropdown leading-[1rem]">{user.name}<svg className= {`ml-2`} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none"><path className= {`${type==='Home'?'stroke-white':'stroke-[#0C1135]'}`} stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M6.9 1.6v10.5M1.6 7.1 7 12.4 12 7"/></svg></p>
                                <div className="hidden mr-2 bg-[#28BF73] rounded-[40px] flex justify-center items-center px-2 py-1">
                                    <p className="text-[14px] text-white font-[500] leading-4">{user.notifications}</p>
                                </div>
                                <div className='hidden loggedin_dropdown_options relative flex items-center cursor-default'>
                                    <div className=' absolute right-0 top-[30px] w-[304px] bg-white shadow-shadow41 text-sm rounded-b text-[#0C1135]'>
                                        <ul>
                                            <li class="package px-4 py-4">
                                                <span class="dropdown-item block py-4 px-4 bg-[#e9fbf2] rounded-[8px]">
                                                    <div class="pkg text-[20px] leading-6 text-[#57546d]">
                                                    <span class="role">{user.role}</span>
                                                    </div>
                                                </span>
                                            </li>
                                            <li class="px-4">
                                                <a class="dropdown-item group flex py-3 px-4 hover:text-[#6d53e4]" href="https://www.taskutark.ee/user/">
                                                    <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                    <circle cx="17.8" cy="17" r="1.4" fill="none" class="group-hover:stroke-[#6d53e4]" stroke="#57546D" stroke-linecap="round" strokeLinejoin="round" stroke-width="2"></circle>
                                                    <path class="group-hover:stroke-[#6d53e4]" fill="none" stroke="#57546D" stroke-linecap="round" stroke-width="2" strokeLinejoin="round" d="m19 11.5.4 1.4a1 1 0 0 0 1.1.6l1.4-.3a1.3 1.3 0 0 1 1.3 2.1l-1 1a1 1 0 0 0 0 1.4l1 1a1.3 1.3 0 0 1-1.3 2.2l-1.4-.4a1 1 0 0 0-1.1.7l-.4 1.4a1.3 1.3 0 0 1-2.5 0l-.4-1.4a1 1 0 0 0-1.1-.7l-1.4.4a1.3 1.3 0 0 1-1.3-2.2l1-1a1 1 0 0 0 0-1.3l-1-1a1.3 1.3 0 0 1 1.3-2.2l1.4.3A1 1 0 0 0 16 13l.4-1.4a1.3 1.3 0 0 1 2.5 0Z"></path>
                                                    <circle cx="7.5" cy="4.8" r="4.3" fill="none" stroke="#57546D" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"></circle>
                                                    <path class="group-hover:stroke-[#6d53e4]" fill="none" stroke="#57546D" stroke-linecap="round" stroke-width="2" strokeLinejoin="round" d="M9.5 17.5h-9a7 7 0 0 1 10.8-5.9"></path>
                                                    </svg>{langContent.header.myaccount?langContent.header.myaccount:'Minu kasutaja'} </a>
                                            </li>
                                            <li class="logout pb-4 px-4">
                                                <span class="dropdown-item group flex py-3 px-4 hover:text-[#6d53e4] cursor-pointer" onClick={handleLogOut}>
                                                    <svg class="mr-3" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g class="group-hover:stroke-[#6d53e4]" clipPath="url(#clip0)" stroke="#57546D" stroke-width="2" stroke-linecap="round" strokeLinejoin="round">
                                                        <path d="M7.5 12.004h15.75M19.5 15.754l3.75-3.75-3.75-3.75M15.75 16.5V21a1.437 1.437 0 01-1.364 1.5H2.113A1.437 1.437 0 01.75 21V3a1.436 1.436 0 011.363-1.5h12.273A1.437 1.437 0 0115.75 3v4.5"></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0">
                                                        <path fill="#fff" d="M0 0h24v24H0z"></path>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>{langContent.header.logout?langContent.header.logout:'Logi välja'} 
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        </>
                    ) : user.loaded && user.loggedIn === false ? (
                        <>
                            <li className='flex items-center mb-4 md:mb-0 text-[14px]'>
                                <a href="https://www.taskutark.ee/auth.php" className='mr-4 px-6 py-2.5 rounded-[24px] bg-[#EBEEFE] border-[2px] border-transparent font-[500] text-primary leading-[1rem] hover:bg-primary hover:text-white hover:border-primary'>
                                    {langContent.header.login ? langContent.header.login : 'Logi sisse'}
                                </a>
                            </li>
                        </>
                    ) : (
                        <></>
                    )
                    }
                               
                </ul>
               
            </div>
        </nav>
    )
}

export default Navbar;