import Parser from 'html-react-parser';
import './SubjectCard.css';

const SubjectCard = ({subject, onClick, active, extraClass}) => {
    return(
        <div className={`w-full h-full w-[148px] shrink-0 md:w-auto min-h-[130px] md:min-h-[160px] p-4 md:min-w-[180px] text-center xl:min-w-[187px] xl:min-w-[163px] rounded-[8px] ${active ? 'active' : ''}
        flex flex-col justify-center items-center bg-[#FFFFFF] hover:bg-[#6D53E4] text-[#6D53E4] hover:text-white transition-all duration-300 cursor-pointer subject_card ${extraClass}`}
        onClick = {onClick}>
            <div className = "flex justify-center items-center rounded-[50%] w-16 h-16 mb-4 bg-[#EBEEFE] subject_icon">
                {subject.icon && Parser(subject.icon)}
            </div>
            <h2 className="md:text-base text-xs font-[500] tracking-[0.05em] leading-[120%]">{subject.name}</h2>
        </div>
    )
}

export default SubjectCard;