
import {
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
import ListPage from './pages/ListPage/ListPage';
import TopicPage from './pages/TopicPage/TopicPage';
import SinglePage from './pages/SinglePage/SinglePage';
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import Home from './pages/Home/Home';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import SingleEbookViewPage from "./pages/SingleEbookViewPage/SingleEbookViewPage";
import CustomEpubPage from "./pages/CustomEpubPage/CustomEpubPage";

const AnimatedRoutes = () => {
    const location = useLocation();
    
    return (
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key = {location.pathname}> 
                <Route exact path="/" element={<Home />} title="TaskuTark on õppimisel abiks" /> 
                {/* <Route exact path="/" element={<Navigate to='/search' />} title="TaskuTark on õppimisel abiks" /> REDIRECT TO SEARCH */}
                <Route path="/search" element={<ListPage />} title="TaskuTark on õppimisel abiks" />
                <Route path='/topic/:topicslug' element={<TopicPage />} title="TaskuTark on õppimisel abiks" />
                <Route path='/ebook/:id/:slug' element={<SingleEbookViewPage />} title="TaskuTark on õppimisel abiks" />
                <Route path='/epub/:id' element={<CustomEpubPage />} title="TaskuTark on õppimisel abiks" />
                <Route path='/content/:singleslug' element={<SinglePage />} title="TaskuTark on õppimisel abiks" />
                <Route path="*" element = {<NotFoundPage />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;