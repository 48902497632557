import Single from "../../layouts/Single/Single";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import Footer from '../../components/Footer/Footer';

const SinglePage = () => {
    return(
        <>
            <ScrollToTopOnMount />
            <Single />
            <Footer />            
        </>
       
    )
}

export default SinglePage;