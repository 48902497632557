import React, { useEffect, useState } from "react";

import EbookThemeContext from "./EbookThemeContext";

function EbookThemeContextProvider(props) {
  
    const [theme, setTheme] = useState('light');
    const [fontSize, setFontSize] = useState(18);
    const [lineHeight, setLineHeight] = useState(1.5);
    const [letterSpacing, setLetterSpacing] = useState(0);
    const [fontFamily, setFontFamily] = useState('Roboto');
    const [fontWeight, setFontWeight] = useState('400');

    const [pageView, setPageView] = useState('spread')

  
    return (
        <EbookThemeContext.Provider
        value={{
            theme,
            fontSize,
            lineHeight,
            letterSpacing,
            fontFamily,
            fontWeight,
            pageView,
            setTheme,
            setFontSize,
            setLineHeight,
            setLetterSpacing,
            setFontFamily,
            setFontWeight,
            setPageView
        }}
        >
        {props.children}
        </EbookThemeContext.Provider>
    );
}

export default EbookThemeContextProvider;
