
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useState } from "react";
import Parser from 'html-react-parser';

const BookInfoLoading = ({}) => {


    return(
        <div className="max-w-[1200px] mx-auto">
            <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-[37%] max-w-[440px] flex justify-center">
                    <div className="w-[400px] h-[400px] shimmerBG"/>
                </div>

                <div className="w-full md:w-[56%] max-w-[676px] mt-10">

                    <div className="flex flex-col">
                        <div className="flex flex-col mb-12">
                            <div className="w-[80%] h-[20px] shimmerBG" />
                            <div className="w-[40%] h-[20px] shimmerBG" />
                            <div className="w-[100%] h-[20px] shimmerBG" />

                            <div className="py-4 px-8 rounded-[64px] w-[100px] shimmerBG h-[20px]" />
                        
                        </div>

                        <div className="flex flex-col">
                        <div className="w-[80%] h-[20px] shimmerBG" />
                        <div className="w-[40%] h-[20px] shimmerBG" />

                        <div className="py-4 px-8 rounded-[64px] w-[100px] shimmerBG h-[20px]" />
                                     

                        </div>  

                    </div>

                </div>
            </div>

        </div>
    )
}

export default BookInfoLoading;