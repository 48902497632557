import { Link } from 'react-router-dom';

const Breadcrumb = ({ item1, item2, item2ID, item3, item4 }) => {
    return(
    <nav className="flex leading-[16px] text-[#938FA8]">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li aria-current="page">
                <div className="flex items-center leading-4">                       
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none"><path fill="currentColor" d="M9.4 0H2a.8.8 0 0 0-.6.8v8.7a.9.9 0 0 0 .6.8L6 12a.9.9 0 0 0 .8 0 .9.9 0 0 0 .4-.8V2.6a.9.9 0 0 0-.6-.8l-1.4-.6a.1.1 0 0 1-.1-.1.1.1 0 0 1 .1-.1h4a.1.1 0 0 1 0 .1v8.3h-1a.5.5 0 1 0 0 1h1a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1Z"/></svg>
                    <Link to={`/search?type=ebook`} className="ml-1 text-sm md:ml-2">{item1}</Link>
                </div>
            </li>
            <li aria-current="page">
                <div className="flex items-center leading-4">         
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75 5.98151C9.75 6.33061 9.60028 6.62984 9.35074 6.82932L3.76105 11.7665C3.36179 12.1156 2.7629 12.0658 2.46345 11.6668C2.164 11.2678 2.164 10.7193 2.56327 10.3702L7.45424 6.08125C7.50415 6.03138 7.50415 5.98151 7.45424 5.88177L2.56327 1.59287C2.164 1.24377 2.164 0.645316 2.51336 0.296219C2.86271 -0.0528776 3.4117 -0.102749 3.81096 0.196477L9.40064 5.08383C9.60028 5.33319 9.75 5.63242 9.75 5.98151Z" fill="currentColor"/>
                    </svg>
                    <Link to={`/search?type=ebook&ebookCat=${item2ID}`} className="ml-1 text-sm t md:ml-2.5">{item2}</Link>
                </div>
            </li>
        </ol>
    </nav>
    )
}

export default Breadcrumb;