const css = `
body,html{
    padding: 0;
    margin: 0;
    overflow: hidden;
 }
 body{
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    background: linear-gradient(37.19deg, #EBEFFF 27.48%, rgba(245, 247, 255, 0) 48.16%);
 }
 #wrapper{
    height: 100vh;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://www.taskutark.ee/wp-content/themes/taskutark_4/dist/img/errors/404.png);
 }
 .content{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    text-align: center;
    padding-top: 80px;
 }
 h1{
    margin-bottom: 32px;
    font-weight: 500;
 }
 .btn{
    display: inline-block;
    padding: 16px 32px;
    color: white;
    background: #6D53E4;
    border-radius: 64px;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
 }
 h1{
    font-weight:500;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
 }
 .btn:hover{
    opacity: .9;
 }
`
const NotFoundPage = () => {
    return (
        
   <div id="wrapper">
      <style>{css}</style>
      <div className="content">
         <h1>Seda lehek&uuml;lge ei leitud</h1>
         <div className="description"><a className="btn focus" href="https://www.taskutark.ee/">Mine esilehele</a></div>
      </div>
   </div>
   
    )
}

export default NotFoundPage;