import Header from "./Header/Header";
import Cover from './Cover/Cover';

import { useParams } from "react-router-dom";
import {  useState } from "react";
import { Navigate } from "react-router-dom";




const Single = () => {
    const {topicslug} = useParams();
    const [topicData] = useState();

    


    if(topicData === ''){
        return <Navigate to = {`/search?q=${topicslug}`} />
    }
    else if(topicData === undefined){
        return <div className="h-[100vh] w-[100vw] flex justify-center items-center">
            <div></div>
        </div>
    }
    else{
        return(
            <>
                <div className="bg-white sticky top-0 z-[9]">
                    <div className="max-w-[1440px] mx-auto px-[3vw] lg:px-[5vw] xl:px-20 w-full">
                        <Header active_level = {topicData.active_level} active_subject = {topicData.active_subject} />
                    </div>
                </div>
                <div className="topic_page_cover" style={{backgroundImage: `url(${topicData.image})`}}>
                    <div className="max-w-[1440px] h-[280px] mx-auto px-[3vw] lg:px-[5vw] xl:px-20 w-full">
                        <Cover topicData = {topicData} />
                    </div>
                </div>
                <div className="bg-[#F5F7FF]">
                    
                </div>
            </>
        )
    }
}

export default Single;