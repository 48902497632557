import {Helmet} from 'react-helmet-async';
// import Header from "./Header/Header";
import Header from "../../components/Header/Header";
import Cover from './Cover/Cover';
import Content from "./Content/Content";
import Topbar from "../../components/Topbar/Topbar";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import API from "../../API/API";
import Banners from "./Banners/Banners";
import LanguageContext from "../../context/LanguageContext/LanguageContext";
import DataContext from "../../context/DataContext";

const moveUp = {
    hidden: {
        y: "30%",
        opacity: 0,
    },
    visible:{
        y: 0,
        opacity: 1,
        transition: {duration: 0.3}
    },
    exit: {
        y: "0%",
        opacity: 0,
        transition: {duration: 0.3}
    }
}



const Topic = () => {
    const {topicslug} = useParams();
    const [topicData, setTopicData] = useState();
    const [items, setItems] = useState([]);
    const [activeType, setActiveType] = useState({id: 0});
    const {language} = useContext(LanguageContext);
    const {langContent} = useContext(LanguageContext);
    const {settings} = useContext(DataContext);
   
    const changeActiveType = (type) => {
        setActiveType(type);
        if(type.slug === 'all'){
            setItems(topicData.items);
        }
        else if(type.slug === 'test'){
            setItems(topicData.items.filter(
                (item) => {
                    if(item.properties.is_test === true){
                        return true;
                    }
                    return false;
                }
            ));
        }
        else{
            setItems(topicData.items.filter(
                (item) => {
                    for(let t of item.types){
                        if(t.id === type.id){
                            return true;
                        }
                    }
                    return false;
                }
            ));
        }
    }


    useEffect(() => {
        API.getTopic(topicslug, language)
        .then(res => {
            setTopicData(res.data)
            setItems(res.data.items)
        })
        .catch(err => console.log(err))
    }, [topicslug, language])

    let metaTitle = langContent.home.title ? langContent.home.title : 'TaskuTark on õppimisel abiks.';

    if(topicData === ''){
        return <Navigate to = {`/search?q=${topicslug}&lang=${language}`} />
    }
    else if(topicData === undefined){
        return <div className="h-[100vh] w-[100vw] flex justify-center items-center">
            <div></div>
        </div>
    }
    else{
        return(
            <>
                <Helmet>
                    <title>{topicData.title} | {metaTitle}</title>
                </Helmet>
                {settings.showTopGreenBar === true && <>
                    <div className="bg-[#28BF73]">
                        <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                            <Topbar link= {settings.showTopGreenBar_link} text = {settings.showTopGreenBar_label} />
                        </div>
                    </div>
                </>}
                <div className="bg-white sticky top-0 z-[9]">
                    <div className="max-w-[1504px] mx-auto w-full">
                        <Header type = 'Topic' className='type_2 w-full sm:py-3 md:bg-transparent text-black mx-auto px-[3vw] lg:px-[32px] xl:px-[32px]'  active_level = {topicData.active_level} active_subject = {topicData.active_subject} />
                    </div>
                </div>
                <div className="topic_page_cover" style={{backgroundImage: `url(${topicData.image})`}}>
                    <div className="max-w-[1504px] mx-auto w-full">
                        <div className="h-[350px] md:h-[280px] mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                            <Cover topicData = {topicData} />
                        </div>
                    </div>
                </div>
                <div className="bg-[#F5F7FF]">
                    <div className="max-w-[1504px] mx-auto w-full">
                        <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                            {/* //type filters */}
                            <div className="flex flex-wrap md:flex-nowrap mt-8">
                                {topicData.types && topicData.types.length > 2 && topicData.types.map((type, index) => {
                                    return(
                                        <button key = {index} onClick = {() => changeActiveType(type)} className={`mb-4 home_subject border-b-2 border-[#f5f7ff] text-[#0C1135] text-[14px] font-[500] tracking-[0.05em] leading-4 mr-6 pb-1.5 ${activeType.id === type.id ? 'active' : ''}`}>
                                            {type.name}
                                        </button>
                                    )
                                })}
                            </div>
                            <div className="w-full xl:flex justify-between mb-10 md:mb-20">
                                <motion.div className="xl:w-full mt-4 md:mr-8" variants={moveUp} initial="hidden" animate="visible" exit="exit">
                                    <Content items = {items}/>
                                </motion.div>
                                    <div className="xl:w-[320px] mt-8 sm:mt-4 shrink-0">
                                        <div className="flex flex-col">
                                            {topicData.banner_text &&
                                                <div className="group transition-bottom duration-400 mb-6 h-[300px] max-w-[307px] rounded-[8px] md:h-[345px] px-8 py-8 md:px-10 md:py-12 bg-[#E2DDFA] relative overflow-hidden">
                                                    <h2 className="mb-4 text-[#0C1135] text-[26px] md:text-[32px] leading-[38px] font-[500] max-w-[14ch]">
                                                        {topicData.banner_text}
                                                    </h2>
                                                    <a href={topicData.button_link} className="bg-[#6D53E4] text-white mr-4 px-6 py-3 rounded-[24px] text-[14px] font-[500] tracking-[0.05em] leading-[1rem] hover:opacity-90">
                                                        {topicData.button_label}
                                                    </a>
                                                    <div className="finger absolute bottom-[-12%] group-hover:bottom-[-8%] left-0 w-[331px] rotate-[-10deg] pointer-events-none transition-bottom duration-400 ease-in-out">
                                                        <img src = {require('../../assets/images/topic/handup.png')} alt = 'hand pointing' className="w-full"/>
                                                    </div>
                                                </div>
                                            }
                                            <Banners topicslug = {topicslug} />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Topic;