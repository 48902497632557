import Cover from "./Cover/Cover";
import React, { useContext, useState, useEffect } from "react";
import {Helmet} from 'react-helmet-async';
import Header from "../../components/Header/Header";
import Content from "./Content/Content";
import ContentEbook from "./Content/Content_ebook";
import Topbar from "../../components/Topbar/Topbar";
import { motion } from "framer-motion";
import Banners from "./Banners/Banners";
import useQuery from '../../hooks/useQuery';
import LanguageContext from "../../context/LanguageContext/LanguageContext";
import DataContext from "../../context/DataContext";
import SearchContext from "../../context/SearchContext/SearchConext";
import { AnimatePresence } from "framer-motion";
import MoreFilters from "./MoreFilters/MoreFilters";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";

const moveUp = {
    hidden: {
        y: "20%",
        opacity: 0,
    },
    visible:{
        y: 0,
        opacity: 1,
        transition: {duration: 0.3}
    },
    exit: {
        y: "0",
        opacity: 0,
        transition: {duration: 0.3}
    }
}

const List = () => {

    const query = useQuery();
    const {langContent} = useContext(LanguageContext);
    const {linkCopied, settings} = useContext(DataContext);
    const {searchInput, searchResultCounts, type, grade, subject} = useContext(SearchContext);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    let metaTitle = langContent.list.metaTitle ? langContent.list.metaTitle : 'Otsi TaskuTargast';
    let typeSlug = query.get("type");
   
    if(subject && subject.slug != "all"){
        let subjectName = subject.name;
        metaTitle = subjectName+ ' | ' + metaTitle;
    }

    if(grade && grade.slug != "all"){
        let gradeName = grade.name;
        metaTitle = gradeName+ ' | ' + metaTitle;
    }

    if(type.slug === 'ebook'){
        metaTitle = 'E-raamatud | TaskuTark';
    }

    return (
        <>
            <Helmet>
                <title>{
                    searchInput ? searchInput + " - " + metaTitle : metaTitle }
                </title>
            </Helmet>
            {settings.showTopGreenBar === true && <>
                <div className="bg-[#28BF73]">
                    <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                        <Topbar link= {settings.showTopGreenBar_link} text = {settings.showTopGreenBar_label} />
                    </div>
                </div>
            </>}
            <div className="relative">
                <div className="absolute z-[99] w-full navbar_container type_2">
                    <div className="max-w-[1504px] mx-auto w-full">
                        <Header type = 'List' className='type_2 w-full sm:py-3 md:bg-transparent text-black mx-auto px-[3vw] lg:px-[32px] xl:px-[32px]' />
                    </div>
                </div>
                <div style={{background: "linear-gradient(37.19deg, #EBEFFF 27.48%, rgba(245, 247, 255, 0) 48.16%)"}}>

                    <div className="max-w-[1504px] mx-auto w-full">
                        <div className="px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                            <div className="xl:w-[75%] pt-20 md:pt-32 pb-4 sm:pb-10">
                            {/* <div className="w-full pt-24 md:pt-32 pb-4 sm:pb-8"> */}
                                <Cover setShowMoreFilters = {setShowMoreFilters} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#F5F7FF]">
                    <div className="max-w-[1504px] mx-auto w-full">
                        <div className="px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                            <div className="w-full xl:flex justify-between mb-10 md:mb-20">
                                {typeSlug !== 'ebook' ?
                                    <motion.div className={`w-full mt-4 sm:mt-6 xl:mr-8`}  variants={moveUp} initial="hidden" animate="visible" exit="exit">
                                        <Content />
                                        {searchResultCounts.pages > 1 && 
                                            <PaginationComponent totalPages = {searchResultCounts.pages} />
                                        }
                                    </motion.div>
                                :
                                    <motion.div className={`w-full mt-4 sm:mt-6 xl:mr-8 grid grid-cols-2 sm:grid-cols-4 gap-6`}  variants={moveUp} initial="hidden" animate="visible" exit="exit">
                                        <ContentEbook />
                                        {searchResultCounts.pages > 1 && 
                                            <PaginationComponent totalPages = {searchResultCounts.pages} />
                                        }
                                    </motion.div>
                                }
                                <div className="xl:w-[320px] mt-8 sm:mt-6 shrink-0">
                                    <Banners />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MoreFilters showMoreFilters = {showMoreFilters} setShowMoreFilters = {setShowMoreFilters}/>
                
                <AnimatePresence>
                    {linkCopied && (

                    alert(langContent.copylink_confirm ? langContent.copylink_confirm : 'Link kopeeritud!')
                    // <motion.div className="copy_confirm fixed top-[7%] inline-flex my-0 mx-auto justify-center pointer-events-none text-white bg-[#6D53E4]"
                    //     initial={{ opacity: 0 }}
                    //     animate={{ opacity: 1 }}
                    //     exit={{ opacity: 0 }}
                    // >
                    //     <p>{langContent.copylink_confirm ? langContent.copylink_confirm : 'Link kopeeritud!'}</p>
                    // </motion.div>
                    )}
                </AnimatePresence>

            </div>
        </>
        
    )

}

export default List;