import Parser from 'html-react-parser';

const Cover = ({topicData}) => {

    return (
        <div className="flex flex-col justify-center h-full max-w-[800px]">
            <h1 className="text-white font-[500] text-[36px] md:text-[40px] leading-[120%] mb-3">
                {topicData.title}
            </h1>
            <div className="text-white text-[14px] md:text-[16px] leading-6 mb-3">{Parser(topicData.description)}</div>
            {topicData.hero_button_text && !topicData.has_access ? (
            <>
            <a href = {topicData.hero_button_link} target='_blank' rel='noreferrer' className="max-w-[300px] text-center bg-[#6D53E4] text-white mt-2 mr-4 px-6 py-3 rounded-[24px] text-[14px] font-[500] tracking-[0.05em] leading-[1rem] hover:opacity-90">
                {topicData.hero_button_text}
            </a>
            </>
            ) : false}
        </div>
    )
}

export default Cover;