
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import { useRef } from 'react';
// import LanguageContext from '../../../context/LanguageContext/LanguageContext';
// import DataContext from '../../../context/DataContext';
// import useQuery from '../../../hooks/useQuery';
// import Parser from 'html-react-parser';
// import { ReplaceText } from '../ReplaceText';

const ContentCard5 = ({content, className=''}) => {


    // const {langContent} = useContext(LanguageContext);
    // const {showLinkCopied} = useContext(DataContext);
    // const searchInput = useQuery().get('q');
    const contentcarddropdownRef = useRef(null);

    // const toggleSearchOptions = (className) => {
    //     document.querySelector(`.select_options_${className}`).classList.toggle('hidden');
    // }
    const hideSearchOptions = (ref) => {
        ref.current.children[1].classList.add('hidden');
    }
    useOutsideAlerter(contentcarddropdownRef, hideSearchOptions);

    // const copyLinkToClipboard = () => {
    //     navigator.clipboard
    //     .writeText(content.link)
    //     .then(() => {
    //         showLinkCopied();
    //     })
    //     .catch(() => {
    //         console.log('Link not copied.');
    //     });
    //     hideSearchOptions(contentcarddropdownRef);
    // }

    const addListScrollReminder = () => {
        window.history.pushState(null,null,'#list-'+content.id);
    }

    const truncate = (input, size) => input.length > size ? `${input.substring(0, size)}...` : input;

    return (
        <a onClick = {addListScrollReminder} href = {content.link} rel="noreferrer"  className={`flex ebook-card content-card cc2 w-full mb-4 rounded-[8px] cursor-pointer relative listCard card-type-5 w-full ${className}`} id = {`list-${content.book_id}`}>
            <div className="flex flex-col w-full justify-start pointer-events-none">

                <div className="flex items-start justify-start flex-col">
                    {content.medium_picture &&
                    <div className="flex justify-center items-center mb-3 shrink-0 bookCover relative">
                        <img src = {content.medium_picture} alt="ebook" className="w-full h-[280px] xl:h-[300px] 2xl:h-[350px] " />
                        <span className="shad"></span>
                    </div>
                    }
                    <div className="flex flex-col w-full">     
                        <h2 className="text-base font-[500] text-[#0C1135]">
                            <span className="block sm:hidden">{truncate(content.title, 50)}</span>
                            <span className="hidden sm:block">{content.title}</span>
                        </h2>
                        <p className="text-sm mt-0.5 truncate">
                            {content.author_line}
                        </p> 
                    </div>
                </div>
                
            </div>

        </a>
    )
}

export default ContentCard5;