
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import { useContext, useRef } from 'react';
import LanguageContext from '../../../context/LanguageContext/LanguageContext';
import DataContext from '../../../context/DataContext';
import useQuery from '../../../hooks/useQuery';
import Parser from 'html-react-parser';
import { ReplaceText } from '../ReplaceText';
import { Link } from 'react-router-dom';
import BookIcon from '../../Icons/Types/Book';

const ContentCard2 = ({content}) => {

    const {langContent} = useContext(LanguageContext);
    const {showLinkCopied} = useContext(DataContext);
    const searchInput = useQuery().get('q');
    const contentcarddropdownRef = useRef(null);

    const toggleSearchOptions = (className) => {
        document.querySelector(`.select_options_${className}`).classList.toggle('hidden');
    }
    const hideSearchOptions = (ref) => {
        ref.current.children[1].classList.add('hidden');
    }
    useOutsideAlerter(contentcarddropdownRef, hideSearchOptions);

    const copyLinkToClipboard = () => {
        navigator.clipboard
        .writeText(content.link)
        .then(() => {
            showLinkCopied();
        })
        .catch(() => {
            console.log('Link not copied.');
        });
        hideSearchOptions(contentcarddropdownRef);
    }

    const addListScrollReminder = () => {
        window.history.pushState(null,null,'#list-'+content.id);
    }

    return (
        <div id = {`list-${content.id}`} className='listCard card-type-2 relative shadow-shadowCard'>
            <a onClick = {addListScrollReminder} href = {content.link} rel="noreferrer" className={`flex content-card cc2 bg-white w-full mb-4 p-4 md:p-6 rounded-[8px] cursor-pointer border-l-4 border-white ${content.has_access && 'border-[#28BF73]'}`}>
                <div class="bg-bookCardGradient absolute left-0 top-0 z-[1] w-[400px] h-full"></div>
                <div className="flex flex-col w-full relative z-[2]">

                    <div className="md:flex items-start ">
            
                        {content.book_image &&
                            <div className="flex md:justify-center items-center mb-3 sm:mb-0 shrink-0 bookCover relative mr-10">
                                <img src = {content.book_image} alt="book" className="w-auto h-[180px] " />
                                <span className="shad"></span>
                            </div>
                        }
                        <div className="flex flex-col h-full flex-grow">
                            <div>
                                <Link to="/search?type=course" className="h-[32px] rounded-[64px] bg-[#E9FBF2] inline-flex w-auto justify-center text-sm text-[#187747] font-[500] items-center shrink-0 px-2 mb-1">
                                    <BookIcon className="text-[#187747] w-4 h-4" />
                                    <span className="ml-1.5">{content.post_type ==='ebook' ? 'E-RAAMATUD' : 'ÕPIKUD'}</span>
                                </Link>
                            </div>
                            <div className="flex flex-col md:justify-between h-full md:flex-grow">        
                                <h2 className="text-[18px] sm:text-[24px] font-[500] text-[#0C1135] leading-[150%]">{Parser(ReplaceText(content.title, searchInput))}</h2>
                                
                                {(content.text && content.post_type ==='ebook') && <div className="text-[14px] sm:text-[16px] my-1 mb-4 text-[#414668] leading-[150%]">{content.text !== undefined && Parser(ReplaceText(content.text, searchInput))}</div>}
                                <div className="flex justify-between items-end"> 
                                    {content.price &&
                                        <p className={`text-[14px] sm:text-[14px] font-[500] rounded-[4px] leading-3.5 tracking-0 py-1 px-1.5 mr-4 bg-[#EBEEFE] uppercase ${content.price_color === '28BF73' ? 'text-[#323449] ' : 'text-primary'}`}>
                                            {content.price.replace('Õpik', '')}
                                        </p>     
                                    }
                                    <div className='max-w-[124px] ml-auto mt-4'>
                                        {content.pub_image && 

                                                <img title={content.publisher && content.publisher} src = {content.pub_image} alt = 'publisher' className='w-full h-auto' />

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </a>
            {/* {content.options && 
                <div ref={contentcarddropdownRef} className="absolute z-[4] top-0 right-2">
                    <button className="flex px-4 py-6 items-center text-sm w-full font-[500] border-0 select_container cursor-pointer"
                        onClick={() => toggleSearchOptions(`contentcarddropdown${content.id}`)}>
                        <span className="teacher_menu p-2 -mt-2 -mr-2 hover:bg-[#0c1135] text-[#938FA8] hover:text-white rounded-[50%] bg-[#ece9fc]">

                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_708_935)">
                            <path d="M7.99992 4.33333C9.19654 4.33333 10.1666 3.36328 10.1666 2.16667C10.1666 0.97005 9.19654 0 7.99992 0C6.8033 0 5.83325 0.97005 5.83325 2.16667C5.83325 3.36328 6.8033 4.33333 7.99992 4.33333Z" fill="currentcolor"/>
                            <path d="M7.99992 10.1673C9.19654 10.1673 10.1666 9.19727 10.1666 8.00065C10.1666 6.80403 9.19654 5.83398 7.99992 5.83398C6.8033 5.83398 5.83325 6.80403 5.83325 8.00065C5.83325 9.19727 6.8033 10.1673 7.99992 10.1673Z" fill="currentcolor"/>
                            <path d="M7.99992 15.9993C9.19654 15.9993 10.1666 15.0293 10.1666 13.8327C10.1666 12.6361 9.19654 11.666 7.99992 11.666C6.8033 11.666 5.83325 12.6361 5.83325 13.8327C5.83325 15.0293 6.8033 15.9993 7.99992 15.9993Z" fill="currentcolor"/></g><defs><clipPath id="clip0_708_935"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>

                        </span>
                    </button>
                        <div className={`z-[2] shadow-shadow4 w-[180px] select_options_contentcarddropdown${content.id} select_options hidden rounded-[8px] shadow-shadow3 flex flex-col absolute right-0 top-[50px] sm:top-[52px] bg-white`}>
                            <button onClick = {copyLinkToClipboard} className = 'w-full flex items-center text-left px-4 py-3.5 text-[#57546d] hover:text-[#6d53e4] font-[500] hover:bg-[#ebeefe] text-[14px] leading-[16px] tracking-[0.05em]'>
                                <svg xmlns="http://www.w3.org/2000/svg" className='mr-2' width="16" height="16"><g fill="none" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path strokeWidth="1.000005" d="M13.5 5.5v-2a1 1 0 0 0-1-1H9.8M4.2 2.5H1.5a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h4"/><path strokeWidth="1.000005" d="M9.6 3.2a.5.5 0 0 1-.5.3H5a.5.5 0 0 1-.5-.3l-.7-2a.5.5 0 0 1 .5-.7h5.6a.5.5 0 0 1 .5.7ZM7.5 7.5h8v8h-8ZM9.5 9.5h4M9.5 11.5h4M9.5 13.5H11"/></g></svg>
                                {langContent.resultItem.copylink ? langContent.resultItem.copylink : "Kopeeri link"}
                            </button>                               
                            
                            {content.options.map((option, index) => {
                                return(
                                    <a href={decodeURI(option.link)} key={index} className = 'w-full flex items-center text-left px-4 py-3.5 text-[#57546d] hover:text-[#6d53e4] font-[500] hover:bg-[#ebeefe] text-[14px] leading-[16px] tracking-[0.05em]'>                            
                                        <img src={option.icon} alt = {option.label} className = 'w-[16px] h-[16px] mr-2' />
                                        {option.label}
                                    </a> 
                                )
                            })}  
                        </div>
                </div>
            } */}
        </div>

    )
}

export default ContentCard2;