import { useContext, useEffect} from "react";
import CardsLoading from "../../../components/CardsLoading/CardsLoading";
import ContentCard from "../../../components/List/ContentCard/ContentCard";
import ContentCard2 from "../../../components/List/ContentCard2/ContentCard2";
import ContentCard3 from "../../../components/List/ContentCard3/ContentCard3";
import ContentCard4 from "../../../components/List/ContentCard4/ContentCard4";
import ContentCard5 from "../../../components/List/ContentCard5/ContentCard5";
import LanguageContext from "../../../context/LanguageContext/LanguageContext";
import SearchContext from "../../../context/SearchContext/SearchConext";
import useQuery from '../../../hooks/useQuery';

const Content = () => {

    const {langContent} = useContext(LanguageContext);
    const {searchResults, type, loading, searchResultCounts} = useContext(SearchContext);

    const query = useQuery();
  
    var currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
    var typeQuery = query.get('type') ? query.get('type') : false;

    useEffect(() => {
        if(searchResults.item === undefined){
            
        }
    }, [searchResults])

    if(loading){
        return <CardsLoading width={'100%'} height={'100%'} n = {8} type = {typeQuery} />
    }
    else{
        return(
            <> 
                {currentPage > 1 && searchResults.items !== undefined && 
                    <div className="w-full col-span-2 sm:col-span-4 text-center text-[#766da9] uppercase text-base tracking-[.2em] mb-4">{langContent.list.page ? langContent.list.page : 'Leht'} {currentPage}{searchResultCounts.pages && ' / '+searchResultCounts.pages}</div>
                }   

                {searchResults.items === undefined ? (
                    <p className="text-center text-[#766da9] uppercase text-base tracking-[.2em] mb-5">{langContent.list.noresults ? langContent.list.noresults : 'Otsingule vasted puuduvad. Palun proovi uuesti :)'}</p>    
                ):(
                    <>
                    {searchResults.fallbackSearch && (
                        // these two lines are for text before fallback search results
                        <div className="flex flex-col mb-5">
                            <p className="text-center text-[#766da9] uppercase text-base tracking-[.2em] mb-3">{langContent.list.noresults ? langContent.list.noresults : 'Otsingule vasted puuduvad. Palun proovi uuesti :)'}</p>    
                            <h2 className="text-[#0C1135] text-[16px] font-[500] tracking-[0.05em] leading-4 mb-1 mt-6 md:mt-8">{langContent.list.fallbackReadmore ? langContent.list.fallbackReadmore : 'Loe lisaks samal teemal'}:</h2>    
                        </div>
                    )}
                    {searchResults.items && searchResults.items.map((item, index) => {
                        if(item === null) return null
                        else {
                            if(item.bg_image !== undefined) {
                                return <ContentCard3 content = {item} key={index} />
                            }
                            else if(item.medium_picture !== undefined && item.book_id !== undefined){
                                return <ContentCard5 content = {item} key={index} />
                            }
                            else if(item.publisher !== undefined){
                                return <ContentCard2 content = {item} key={index} />
                            }
                            else if(item.image !== undefined){
                                return <ContentCard4 content = {item} key={index} />
                            }
                            else{
                                return(
                                    <ContentCard content = {item} key={index} />
                                )   
                            }
                        }
                    })
                    }
                    </>
                )
                }
            </>
        )
    }
}

export default Content;