
import { useContext, useEffect, useState } from "react";
import API from "../../../API/API";
import BannerCard from "../../../components/Home/BannerCard/BannerCard";
import LanguageContext from "../../../context/LanguageContext/LanguageContext";

const Banners = () => {

    const [banners, setBanners] = useState([]);
    const {language} = useContext(LanguageContext);

    useEffect(() => {
        API.getBanners('front', language)
        .then(res => setBanners(res.data))
        .catch(err => console.log(err))
    }, [language])

    return(
        <div className="mt-10 mb-8 md:mb-16">
            <div className="banners_container flex flex-wrap lg:flex-nowrap justify-between">
                {banners.map((item, index) => {
                    return <BannerCard banner = {item} key= {index} />
                })}
            </div>
        </div>
    )
}

export default Banners;