import { useContext } from "react";
import DataContext from "../../context/DataContext";
import LanguageContext from "../../context/LanguageContext/LanguageContext";

const Footer = () => {

    const {settings} = useContext(DataContext);
    const {langContent} = useContext(LanguageContext);
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };

    return(
        <footer className="max-w-[1504px] mx-auto w-full">
            <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] mb-4 w-full box-border">
                <div className="w-full flex flex-col items-center flex-col-reverse md:flex-row justify-between flex-wrap text-[#0C1135] leading-6 text-center sm:text-left">
                    <div className="text-[12px] sm:text-sm flex flex-col flex-col-reverse md:flex md:flex-row flex-wrap sm:flex-nowrap w-full">
                        <div className="flex-col leading-6 flex-1 mb-4 md:mb-0">

                            TaskuTark OÜ 
                            <p>Reg kood: 12268475</p>
                            <p>Uustalu 11, 13516</p>
                            <p>Tallinn, Eesti</p>    

                        </div>
                        <div className="flex-col leading-6 flex-1 mb-4 md:mb-0">

                            <a className='block mr-4 lg:mr-8 hover:text-primary' href={settings.navFooter[1].linkTarget}>
                                {langContent.footer.termsofuse ? langContent.footer.termsofuse : 'Kasutustingimused'}
                            </a>
                            <a className='block mr-4 lg:mr-8 hover:text-primary' href={settings.navFooter[2].linkTarget}>
                                {langContent.footer.privacypolicy ? langContent.footer.privacypolicy : 'Isikuandmete töötlemine'}
                            </a>
                            <a className='block mr-4 lg:mr-8 hover:text-primary' href={settings.navFooter[0].linkTarget}>
                                {langContent.footer.ofus ? langContent.footer.ofus : 'Meist'}
                            </a>
                            <a className='block mr-4 lg:mr-8 hover:text-primary' href='https://www.taskutark.ee/toetajad/'>
                                {langContent.footer.supporters ? langContent.footer.supporters : 'Toetajad'}
                            </a>
                            {settings.navFooter[3] && 
                            <>
                                <a className='mr-4 lg:mr-8 hover:text-primary' href={settings.navFooter[3].linkTarget}>
                                    {langContent.footer.feedback ? langContent.footer.feedback : 'Tagasiside'}
                                </a>
                            </>
                            }

                        </div>
                        <div className="flex-1 leading-6 mb-4 md:mb-0">
                            
                        <p>Vajad abi? Kirjuta <br></br><a  className='hover:text-primary' href="mailto:abi@taskutark.ee">abi@taskutark.ee</a></p>
                        <p>&nbsp;</p>
                        <p><a className='hover:text-primary' href="https://www.taskutark.ee/kontakt/">Kontakt</a></p>

                        </div>
                        <div className="flex justify-center md:justify-end flex-1 mb-4 md:mb-0">
                            
                        <a title="Rahastatakse liidu COVID-19 pandeemiale reageerimise raames." className="text-end" href="https://www.taskutark.ee/toetajad/" aria-label="Rahastatakse liidu COVID-19 pandeemiale reageerimise raames.">
                            <img className='w-[224px] h-[65px]' width = '224px' height = '65px' src={require('../../assets/images/banners/EAS_SF_RGB_EST_sm.webp')} alt="Rahastatakse liidu COVID-19 pandeemiale reageerimise raames." />
                        </a> 
                        </div>
                    </div>
                </div>
                <div className="w-full border-t pt-4 mt-4 flex items-center justify-between flex-wrap text-[#0C1135] leading-6 text-center sm:text-left">
                    <p className="text-[12px] sm:text-sm mr-4 lg:mr-8">© TaskuTark {new Date().getFullYear()}</p>
                    <p className="mb-4 md:mb-0"><a className='text-black' target="_blank" rel="noreferrer" href={settings.fbLink} aria-label="TaskuTark Facebookis">
                            <svg xmlns="http://www.w3.org/2000/svg" fill = 'currentColor' width = '24px' height = '24px' viewBox="0 0 512 512">
                                <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                            </svg>
                        </a></p>
                </div>
                <div className="up_link_container">
                    <button className="up_link btn" onClick={scrollToTop} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M.625 14.324l8.933-8.932a.624.624 0 01.884 0l8.933 8.932" fill="none" stroke="#6d53e4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></svg></button>
                </div>
            </div>
        </footer>
    )
}

export default Footer;