
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
// import { useState } from "react";
// import Tooltip from '../../components/Tooltip/Tooltip';

const BookInfo = ({book}) => {

    // const [tabs] = useState(['Kirjeldus', 'Täpsemalt']);
    // const [tabs] = useState(['Kirjeldus']);
    // const [activeTab, setActiveTab] = useState('Kirjeldus');

    const hasAccess = book.has_access;

    return(
        <div className="max-w-[1200px] mx-auto">
            <div className="flex flex-col md:flex-row justify-between">
                <div className="h-full w-[60%] md:w-[37%] max-w-[440px] relative bookCover mx-auto">
                    <img src = {book.medium_picture} 
                        className="w-full drop-shadow-[1px_2px_7px_rgba(0,0,0,0.15)] rounded-l-[2px] rounded-r-[1px]" alt = 'bookcover'/>
                    <span className="shad"></span>
                </div>

                <div className="w-full md:w-[56%] max-w-[676px] mt-10">

                    <div className="flex flex-col">
                        <div className="flex flex-col mb-4 md:mb-12">
                            <Breadcrumb item1 = 'E-raamatud' item2 = {book.active_subject} item2ID = {book.activeCat} item3 = {book.active_level} />
                            <h1 className="my-2 font-[500] text-[32px] md:text-[40px] leading-[120%]">
                                {book.title}
                            </h1>
                            <p className='leading-[150%] mb-6'>
                                {book.author_line} <span className='ml-1'>{book.year}</span>
                            </p>


                            <div className="flex w-full">
                            {book.book_id && !hasAccess ? (
                                <div className="flex flex-wrap w-full">

                                    <Link to={`/epub/${book.book_id}?preview=true`} className="py-4 px-8 mr-4  w-full bg-white rounded-[64px]  border-[2px] border-[#EBEEFE] md:w-fit font-[500] text-[14px] leading-4 tracking-[0.05em] text-[#6D53E4] hover:text-white hover:bg-[#6D53E4] hover:border-[#6D53E4] shrink-0 text-center mb-6 md:mb-0">Tutvu sisuga</Link>

                                    <a href={`${book.buy}`} className="py-4 px-8 mr-4 w-full border-[2px] border-[#6D53E4] bg-[#6D53E4] rounded-[64px] md:w-fit font-[500] text-[14px] leading-4 tracking-[0.05em] text-white shrink-0 flex-wrap text-center hover:opacity-90">Laenuta 30 päevaks<span className="py-1 px-2 ml-3 bg-[#EBEEFE] rounded-[24px] text-[#6D53E4]">1.90€</span></a>

                                </div>
                            ):(
                                <Link to={`/epub/${book.book_id}`} className="py-4 px-8 bg-[#6D53E4] rounded-[64px] w-fit font-[500] text-[14px] leading-4 tracking-[0.05em] text-white hover:opacity-90 shrink-0">
                                    Loe raamatut
                                </Link>
                            )}
                            </div>

                        </div>

                        <div className="flex flex-col">
                            {/* <div className="flex flex-wrap lg:flex-nowrap mb-6">
                                {tabs.map(tab => {
                                    return(
                                        <button onClick={() => setActiveTab(tab)} 
                                        className={`mb-6 text-[14px] font-[500] tracking-[0.05em] mr-6 pb-2 hover:[#6D53E4] border-b-[2px] ${activeTab === tab ? 'border-[#6D53E4]' : 'border-transparent'}`}>
                                           {tab} 
                                        </button> 
                                    )
                                })}
                            </div> */}
                            <div className="mt-6">
                                <p className="text-base lg:text-[18px] xl:text-[20px] leading-[150%] mb-6">
                                    {book.description && Parser(book.description.replace("<br>", ""))}
                                </p>
                                
                            </div>

                            {/* <button className="py-4 px-8 text-[#6D53E4] bg-[#E2DDFA] hover:text-white hover:bg-[#6D53E4] rounded-[64px] w-fit font-[500] text-[14px] leading-4 tracking-[0.05em]">
                                Laenuta 30 päevaks
                            </button> */}
                                     

                        </div>  

                    </div>

                </div>
            </div>

        </div>
    )
}

export default BookInfo;