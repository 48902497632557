
import Book from "epubjs";

import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {Helmet} from 'react-helmet-async';
import Sidebar from "./Sidebar";
// import LanguageContext from "../../context/LanguageContext/LanguageContext";
import {useSwipeable} from 'react-swipeable'
import API from "../../API/API";
import EbookThemeContext from "../../context/EbookThemeContent/EbookThemeContext";
import useThemeSettings from "./useThemeSettings";
import useFullScreen from "../../hooks/useFullScreen";
import Tooltip from '../../components/Tooltip/Tooltip';
import useQuery from '../../hooks/useQuery';

const CustomEpub = () =>  {
  
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const isRunned = useRef(false);
    const [toc, setToc] = useState([]);
    const [currentLocation, setCurrentLocation] = useState({start: {}, atStart: true, atEnd: true});
    const [bookmarks, setBookmarks] = useState([]);

    const [pageBookmarked, setPageBookmarked] = useState(null);
    const [currentTopic, setCurrentTopic] = useState(-1);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [bookLoading, setBookLoading] = useState(true)
    const [activeSidebarTab, setActiveSidebarTab] = useState("toc");
    // const {langContent} = useContext(LanguageContext);
    const previewEpub = useQuery().get('preview');
    const {setTheme, theme, setFontSize, fontSize } = useContext(EbookThemeContext);

    const bookRef = useRef(null);
    const renditionRef = useRef(null)
    
    const prevButtonRef = useRef(null)
    const nextButtonRef = useRef(null)
    const prevButtonMobileRef = useRef(null)
    const nextButtonMobileRef = useRef(null)

    const viewerRef = useRef(null)
    const ebookContainerRef = useRef(null)
    const userRef = useRef(null)
    const timeoutRef = useRef(null)

    /*
    these are controller refs
    purpose of these refs are to avoid the bugs.
    sectionControllerRef helps to figure out if sections is changed by the TOC (sections changes throught TOC may get us wrong current page).
    pageControllerRef helps to figure out if page is changed by the user himself. or by page resizing (page resizing renders the view again which changes current page).
    actualPageRef keeps track of the actual current page. (if on resize the currentpage changes, we need render the actual correct page instead of wrong currentpage)
    */
    const sectionControllerRef = useRef(null)
    const pageControllerRef = useRef(true)
    const actualPageRef = useRef(null)
    
    const {isFullScreen, toggleFullScreen} = useFullScreen();

    useThemeSettings(renditionRef.current);
    const handlers = useSwipeable({
        onSwipedLeft: () => goToNextPage(),
        onSwipedRight: () => goToPrevPage(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    const navigate = useNavigate();

    // let metaTitle = langContent.ebook.metaTitle ? langContent.ebook.metaTitle : 'TaskuTark';
    let metaTitle = 'TaskuTark';
    let metaTitle2 = 'E-raamat';

    // const [ebook, setEbook] = useState(null);
    const {id} = useParams()
    const [slug, setBookSlug] = useState(null);
    const [buyLink, setBuyLink] = useState(null);
    const [hasAccess, setHasAccess] = useState(false);

    const mobileTouchToChangePage = true;

    useEffect(() => {

        function _base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }

        if(!isRunned.current){
           
            
            API.getEpub(id, previewEpub)
            .then(res => {  
                // console.log(res)
                if(!res.data.has_access){
                    localStorage.setItem('epubInfo', 1)
                    return navigate(`/ebook/${id}/${res.data.slug}`)
                }
                else{
                    setHasAccess(true)
                    if(res.data.user_id) userRef.current = res.data.user_id;
                    let base64string = (res.data.epub.split(process.env.REACT_APP_SALT_START)[1]).split(process.env.REACT_APP_SALT_END)[0]
        
                    let arrbuff = _base64ToArrayBuffer(base64string)
                    let book = new Book(arrbuff);
                    setBookSlug(res.data.slug);
                    setBuyLink(res.data.buy);

                    book.ready.then(function(){
                        //render the book
                        let rendition = book.renderTo("area", {width: '100%', height: '100%'})
                        renditionRef.current = rendition;
                        rendition.display();
                        rendition.themes.default({
                            body: {
                                
                            },
                            p: {
                                'font-size': `18px !important`,
                                'line-height': `1.5 !important`,
                                'font-family': 'Roboto !important',
                                'text-align': 'left!important'
                            },
                            img:{
                                'background-color': 'white'
                            }
                        })
                
                        // rendition.hooks.content.register((contents, view) => {
                        //     let doc = contents.document;
                        //     setTimeout(() => {
                        //         var style = document.createElement("style");
                        //         style.innerHTML = "*{-webkit-transition: transform {t} ease;-moz-transition: tranform {t} ease;-o-transition: transform {t} ease;-ms-transition: transform {t} ease;transition: transform {t} ease;}";
                        //         style.innerHTML = style.innerHTML.split("{t}").join("0.5s");
                        //         doc.body.appendChild(style);
                        //         console.log(contents.document.documentElement)
                        //     }, 100)
                            
                        //     console.log(view)
                           
                        // })
                
                        rendition.on("relocated", function(location){
                            //set Current Location
                            if(pageControllerRef.current){
                                setCurrentLocation(location)    
                                pageControllerRef.current = false
                            }
                            else{
                                rendition.display(actualPageRef.current.start.cfi);
                                pageControllerRef.current = true
                            }
                            
                        })

                        //get all the topics/chapters of the book
                        book.loaded.navigation.then(toc => {
                            const getCfiFromHref = async (href) => {
                                const id= href.split('#')[1]
                                const item = book.spine.get(href)
                                await item.load(book.load.bind(book))
                                const el = id ? item.document.getElementById(id) : item.document.body
                                return item.cfiFromElement(el)
                            }
                            let tocswithCfi = []
                            toc.toc.forEach(item => {
                                getCfiFromHref(item.href).then(cfi => {
                                    tocswithCfi.push({...item, cfi})
                                })
                            })
                            setToc(tocswithCfi)
                        }).catch(function(err){console.log(err)});

                        return book.locations.generate(1600)

                    })
                    .then(locations => {
                        bookRef.current = book;
                        setBookLoading(false)
                        if(res.data.bookmark){
                            pageControllerRef.current = true
                            renditionRef.current.display(res.data.bookmark)
                        }
                    })
                }
        
            })
            .catch(err => console.log(err))
     
        }

        return () => {
            isRunned.current = true;
        };
    }, [id, navigate])


    const goToNextPage = () => {
        pageControllerRef.current = true
        renditionRef.current.next();
    }


    const goToPrevPage = () => {
        pageControllerRef.current = true
        renditionRef.current.prev();
    }

  // add keyboard key listener to the window to go to next and prev page with keyboard
    useEffect(() => {
    //move to next or prev pages with keyboard
        const onKeyDown = (event) => {    
            if (event.repeat){ return; }
            if(event.key === 'ArrowRight' || event.key === 'Enter' || event.key === ' '){
                goToNextPage()
            }
            else if(event.key === 'ArrowLeft'){
                goToPrevPage()
            }
            else if(event.key === 'Escape'){
                setSidebarOpen(false)
            }
            
        }

        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown)
        };
    }, [])
    
    //find if the current location is already bookmarked
    //also hide prev and next button for the first and last page
    useEffect(() => {
        //check if page is already in booksmarks
        let pagebookmarked = false
        for(let i of bookmarks){
            if(i.cfi === currentLocation.start.cfi){
                pagebookmarked = true
                break
            }
        }
        setPageBookmarked(pagebookmarked)

        //set the current Topic of the page if allowed
        if(bookRef.current && currentLocation.start.cfi){
            if(sectionControllerRef.current === null){
                let currentTopic = -1
                for(let i = 0; i < toc.length; i++){
                    if(bookRef.current.locations.percentageFromCfi(currentLocation.start.cfi) >= bookRef.current.locations.percentageFromCfi(toc[i].cfi)){
                        currentTopic = i
                    }
                }
                setCurrentTopic(currentTopic)
            }
            else{
                setCurrentTopic(sectionControllerRef.current)
                sectionControllerRef.current = null
            }
        }
    
        //Check if the current page is the first or last page of the book
        if(currentLocation){

            //find the current page number and page progress in percentage
            if(bookRef.current && currentLocation.start.cfi){
                setCurrentPageNumber(bookRef.current.locations.locationFromCfi(currentLocation.start.cfi))
                setCurrentProgress(bookRef.current.locations.percentageFromCfi(currentLocation.start.cfi))
            }

            actualPageRef.current = currentLocation;
        }


        //save the current page location for a user, if user has spent more than 3 seconds on the page
        
        clearTimeout(timeoutRef.current)
        if(!bookLoading){
            timeoutRef.current = setTimeout(() => {
                API.saveBookmark(id, currentLocation.start.cfi, userRef.current)
                clearTimeout(timeoutRef.current)
            }, 3000);
        }

        return () => {
            clearTimeout(timeoutRef.current)
        }

    }, [currentLocation, bookmarks, toc, id, bookLoading])

    //the effect resizes the page when sidebar is opened
    useEffect(() => {
        if(renditionRef.current){
            renditionRef.current.resize('100%', '100%');
        }
    }, [sidebarOpen, isFullScreen])


    //add a new bookmark
    const addBookmark = useCallback(() => {
        if(currentLocation.start.cfi){
            let newBookmark = {
                cfi: currentLocation.start.cfi,
                href: currentLocation.start.href,
                label: (toc[currentTopic] && toc[currentTopic].label) || currentLocation.start.cfi,
            }
            setBookmarks([...bookmarks, newBookmark])
            setPageBookmarked(true)
        }
    }, [currentLocation, currentTopic, bookmarks, setPageBookmarked, toc])

    //remove a bookmark
    // const removeBookmark = () => {
    //     let newBookmarks = bookmarks.filter(bookmark => bookmark.cfi !== currentLocation.start.cfi)
    //     setBookmarks(newBookmarks)
    //     setPageBookmarked(false)
    // }

    //the useEffect is used to add page to bookmark if user has been to the page for more than 10 minutes
    useEffect(() => {
        let timeoutId = window.setTimeout(() => {
            if(!pageBookmarked) {
                addBookmark()
            }
        }, 600000);
        return () => {
            window.clearTimeout(timeoutId);
        }
    }, [currentLocation, pageBookmarked, addBookmark])

    const openSidebar = (tab) => {
        setActiveSidebarTab(tab)
        setSidebarOpen(true);
    }

    const closeSidebar = (tab) => {
        setSidebarOpen(false);
    }

    const changeSection = (href, index) => {
        pageControllerRef.current = true
        sectionControllerRef.current = index
        renditionRef.current.display(href)
    }

    const changeSectionWithDeplay = (href, index) => {
        setSidebarOpen(false)
        sectionControllerRef.current = index
        setTimeout(() => {
            pageControllerRef.current = true
            renditionRef.current.display(href)
        }, 500)
    }

    function loadingIcon() {
        return (
            <svg
            xmlns='http://www.w3.org/2000/svg'
            width='64'
            height='64'
            display='block'
            preserveAspectRatio='xMidYMid'
            style={{ margin: "auto", background: "0 0" }}
            viewBox='0 0 100 100'
            >
            <circle
                cx='50'
                cy='50'
                r='35'
                fill='none'
                stroke='#6d53e4'
                strokeDasharray='164.93361431346415 56.97787143782138'
                strokeWidth='10'
            >
                <animateTransform
                attributeName='transform'
                dur='1s'
                keyTimes='0;1'
                repeatCount='indefinite'
                type='rotate'
                values='0 50 50;360 50 50'
                ></animateTransform>
            </circle>
            </svg>
        );
    }
    

    return (
    <>
      <Helmet>
        <title>{!bookLoading ? bookRef.current.packaging.metadata.title + " - " + metaTitle2 + " - " + metaTitle : metaTitle }</title>
      </Helmet>
      <div ref = {ebookContainerRef} className='w-full h-[100vh] bg-white ebookcontainer'>
        <Sidebar book={bookRef.current} toc = {toc} bookmarks = {bookmarks} rendition = {renditionRef.current} currentLocation = {currentLocation} viewerRef={viewerRef} open={sidebarOpen} setOpen={setSidebarOpen} activeTab={activeSidebarTab} changeSection={changeSectionWithDeplay} currentTopic = {currentTopic}/>
        <div className={`fixed w-full h-full flex flex-col`}>
            <div className={`flex w-full max-w-[1148px] px-1 md:px-2 2xl:px-3 mx-auto min-h-[100px] h-[20%] md:h-[20%] md:h-[12%] mb-[1%]`}>
                <div className={`flex flex-col w-full max-w-[1148px] items-center justify-end lg:justify-start lg:mt-[24px] 2xl:mt-[48px]`}>
                    {bookLoading ?
                        false
                        :
                        <h1 className="text-[20px] md:text-[24px] font-[500] text-center text-ellipsis overflow-hidden whitespace-nowrap w-[90vw]">
                            {bookRef && bookRef.current.packaging && bookRef.current.packaging.metadata.title }
                        </h1>
                    }
                    {!previewEpub ?
                        currentTopic !== -1 && !previewEpub ?
                            <div className="flex items-center">
                                {currentTopic > 0 &&
                                    <button className="text-[#938FA8] hover:text-[#6D53E4] outline-blue-500" onClick={() => changeSection(toc[currentTopic-1].href, currentTopic-1)}>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.354981 6.01849C0.354981 5.66939 0.504704 5.37016 0.754243 5.17068L6.34393 0.233452C6.74319 -0.115644 7.34208 -0.0657731 7.64153 0.333195C7.94098 0.732163 7.94098 1.28074 7.54172 1.62984L2.65074 5.91875C2.60084 5.96862 2.60084 6.01849 2.65074 6.11823L7.54172 10.4071C7.94098 10.7562 7.94098 11.3547 7.59162 11.7038C7.24227 12.0529 6.69328 12.1027 6.29402 11.8035L0.704336 6.91617C0.504705 6.66681 0.354981 6.36758 0.354981 6.01849Z" fill="currentColor"/>
                                        </svg>
                                    </button>
                                }
                                <p className="topicTitle text-base mx-4 text-ellipsis overflow-hidden whitespace-nowrap max-w-[75vw]">
                                    {toc[currentTopic].label}
                                </p>
                                {currentTopic < toc.length - 1 &&
                                    <button className="text-[#938FA8] hover:text-[#6D53E4] outline-blue-500" onClick={() => changeSection(toc[currentTopic+1].href, currentTopic+1)}>                                        
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.85498 5.98151C9.85498 6.33061 9.70526 6.62984 9.45572 6.82932L3.86604 11.7665C3.46677 12.1156 2.86788 12.0658 2.56843 11.6668C2.26898 11.2678 2.26898 10.7193 2.66825 10.3702L7.55922 6.08125C7.60913 6.03138 7.60913 5.98151 7.55922 5.88177L2.66825 1.59287C2.26898 1.24377 2.26898 0.645316 2.61834 0.296219C2.96769 -0.0528776 3.51668 -0.102749 3.91594 0.196477L9.50563 5.08383C9.70526 5.33319 9.85498 5.63242 9.85498 5.98151Z" fill="currentColor"/>
                                        </svg>
                                    </button>
                                }
                            </div>
                            : <p className="text-base mx-4 h-[24px]"></p>
                        : <p className="text-base mx-4 h-[24px]">
                            {!bookLoading && previewEpub && currentProgress !== 1 ? 
                                <a href= {buyLink} className = {`${theme === 'light'&&'text-white'} flex showTOC hover:opacity-80  font-medium tracking-wider items-center mt-2 mr-4 px-4 py-1 border-[2px] border-[#6D53E4] hover:border-[#6D53E4] rounded-[64px] bg-[#6D53E4]`}>     
                                        <p className="text-[14px] leading-[20px] ">Laenuta 30 päevaks</p>
                                    </a>
                            : false
                            }</p>
                    }
                </div>
            </div>

            {/* main area */}
            {/* <div className={`flex ${open?'justify-center md:justify-start 3xl:justify-center':'justify-center'} items-center max-w-[1440px] px-1 md:px-2 2xl:px-3 mx-auto`} > */}
            <div {...handlers} className={`flex justify-center lg:justify-between items-center w-full px-1 md:px-8 mx-auto h-[75%] ${sidebarOpen?'scale-[0.7] h-[130%] xl:scale-[0.7] xl:h-[120%]':''} transition-all duration-500 ease-in-out origin-top-left`} >
                
                <div className="w-[20px] lg:w-[64px] hidden lg:block shrink-0">
                    <button aria-label="eelmine leht" ref={prevButtonRef} className={`${currentLocation?.atStart && 'hidden'} mobilePrevArrow mb-[32px] text-[#938FA8] hover:text-[#6D53E4] flex justify-center items-center lg:w-[64px] lg:h-[64px] outline-blue-500 lg:hover:bg-[#e2ddfa] rounded-[50%]`} onClick={goToPrevPage}>      
                            <svg className="w-[20px] h-[21px] lg:w-[33px] h-[32px]" viewBox="0 0 33 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.1484 14.0343H7.33371C7.26622 14.0337 7.20051 14.0126 7.14519 13.974C7.08988 13.9353 7.04754 13.8808 7.02375 13.8176C6.99996 13.7545 6.99582 13.6856 7.01187 13.6201C7.02793 13.5545 7.06343 13.4953 7.11371 13.4503L18.6297 3.31699C18.9918 2.97564 19.2071 2.50708 19.2305 2.01004C19.2538 1.51301 19.0833 1.02632 18.7548 0.652561C18.4263 0.2788 17.9656 0.0471534 17.4697 0.00644165C16.9738 -0.0342701 16.4814 0.119133 16.0964 0.434321L1.18838 13.553C0.847878 13.8533 0.575173 14.2226 0.388379 14.6364C0.201586 15.0502 0.10498 15.499 0.10498 15.953C0.10498 16.407 0.201586 16.8558 0.388379 17.2696C0.575173 17.6834 0.847878 18.0527 1.18838 18.353L16.0937 31.469C16.479 31.7802 16.9698 31.9305 17.4633 31.8884C17.9568 31.8463 18.415 31.6151 18.7421 31.2431C19.0691 30.8711 19.2398 30.3871 19.2183 29.8923C19.1969 29.3974 18.985 28.93 18.627 28.5877L7.11105 18.4543C7.06076 18.4093 7.02526 18.3501 7.00921 18.2846C6.99315 18.219 6.99729 18.1502 7.02108 18.087C7.04488 18.0238 7.08721 17.9693 7.14253 17.9307C7.19784 17.892 7.26356 17.871 7.33105 17.8703H30.1484C30.6487 17.8571 31.124 17.6491 31.4732 17.2906C31.8224 16.9321 32.0178 16.4514 32.0178 15.951C32.0178 15.4505 31.8224 14.9699 31.4732 14.6113C31.124 14.2528 30.6487 14.0448 30.1484 14.0317V14.0343Z" fill="currentColor"/>
                            </svg>
                    </button>
                </div>

                {/* Loading loading loading */}
                {bookLoading && <div className={`w-full top-0 left-0 right-0 h-full bg-white rounded-lg absolute z-[3] flex justify-center items-center text-[#938FA8]`}>
                        <div className="loadingIcon flex flex-col justify-center items-center">
                            {loadingIcon()}
                            <span className="block mt-6 ">Laadin raamatut</span>
                        </div>    
                </div>}

                <div className="w-[90%] 2xl:w-full max-w-[1240px] h-full relative">
                    <div id="area" ref = {viewerRef}
                        className={`w-full h-full pointer-events-none relative z-[0]`}>
                    </div>
                    {mobileTouchToChangePage && !bookLoading && 
                    <>
                        <button className="md:hidden w-[38%] absolute h-full top-0 left-0 z-[1] outline-hidden" onClick={goToPrevPage}>       
                        </button>
                        <button className="md:hidden w-[38%] absolute h-full top-0 right-0 z-[1] outline-hidden" onClick={goToNextPage}>  
                        </button>
                    </>}
                </div>
    
                    <div className="w-[20px] lg:w-[64px] hidden lg:block shrink-0">
                        <button aria-label="järgmine leht" ref={nextButtonRef} className={`${currentLocation?.atEnd && 'hidden'} mobileNextArrow mb-[32px] text-[#938FA8] hover:text-[#6D53E4] flex justify-center items-center lg:w-[64px] outline-blue-500 lg:h-[64px] lg:hover:bg-[#e2ddfa] rounded-[50%]`} onClick={goToNextPage}>            
                            <svg className="w-[20px] h-[21px] lg:w-[33px] h-[32px]" viewBox="0 0 33 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32.0716 15.953C32.0705 15.499 31.9731 15.0504 31.7861 14.6367C31.599 14.2231 31.3264 13.8537 30.9863 13.553L16.081 0.434321C15.696 0.119133 15.2036 -0.0342701 14.7077 0.00644165C14.2118 0.0471534 13.751 0.2788 13.4226 0.652561C13.0941 1.02632 12.9236 1.51301 12.9469 2.01004C12.9702 2.50708 13.1856 2.97564 13.5476 3.31699L25.0637 13.4503C25.1142 13.4951 25.1499 13.5542 25.166 13.6198C25.1822 13.6853 25.1781 13.7542 25.1543 13.8174C25.1304 13.8806 25.0879 13.935 25.0325 13.9735C24.977 14.012 24.9112 14.0327 24.8436 14.033H2.02498C1.51576 14.033 1.02741 14.2353 0.667335 14.5953C0.307266 14.9554 0.10498 15.4438 0.10498 15.953C0.10498 16.4622 0.307266 16.9506 0.667335 17.3106C1.02741 17.6707 1.51576 17.873 2.02498 17.873H24.8396C24.9071 17.8736 24.9729 17.8947 25.0282 17.9333C25.0835 17.972 25.1258 18.0265 25.1496 18.0897C25.1734 18.1528 25.1775 18.2217 25.1615 18.2873C25.1454 18.3528 25.1099 18.412 25.0596 18.457L13.5436 28.5903C13.3422 28.753 13.1758 28.9547 13.0544 29.1834C12.933 29.412 12.859 29.6629 12.837 29.9208C12.815 30.1788 12.8454 30.4385 12.9264 30.6844C13.0073 30.9303 13.1372 31.1573 13.3081 31.3517C13.4791 31.5462 13.6876 31.704 13.9211 31.8158C14.1546 31.9276 14.4083 31.991 14.667 32.0022C14.9256 32.0134 15.1839 31.9721 15.4262 31.881C15.6685 31.7898 15.8899 31.6506 16.077 31.4717L30.985 18.353C31.3255 18.0525 31.5984 17.6832 31.7857 17.2695C31.973 16.8558 32.0705 16.4071 32.0716 15.953V15.953Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                
            </div>
        
        
     {!bookLoading &&
            <div className="flex justity-center items-center fixed top-[20px] lg:top-[24px] 2xl:top-[48px] right-[16px] lg:right-[32px] z-3">

                <button onClick={()=>setTheme('dark')} className = {`${theme === 'dark' && 'hidden'} lg:hidden mr-4 darkThemeToggler text-[#0C1135] hover:text-[#6D53E4] font-medium tracking-wider flex items-center py-1`}>     
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20"><defs></defs><title>astronomy-moon</title><path d="M16.5,21.75a9.75,9.75,0,0,1,0-19.5.264.264,0,0,0,.109-.5A11.138,11.138,0,0,0,12,.75a11.25,11.25,0,0,0,0,22.5,11.138,11.138,0,0,0,4.609-1A.264.264,0,0,0,16.5,21.75Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></path><line x1="17.25" y1="6.75" x2="17.25" y2="9.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="15.75" y1="8.25" x2="18.75" y2="8.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="21.75" y1="12.75" x2="21.75" y2="15.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="20.25" y1="14.25" x2="23.25" y2="14.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="14.25" y1="14.25" x2="14.25" y2="17.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="12.75" y1="15.75" x2="15.75" y2="15.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line></svg>
                </button>

                <button onClick={()=>setTheme('light')} className = {`${theme === 'light' && 'hidden'} lg:hidden mr-4 lightThemeToggler text-[#ffffff] hover:text-[#6D53E4] font-medium tracking-wider flex items-center py-1`}>     
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20"><defs></defs><title>light-mode-sunny</title><circle cx="12" cy="12" r="4.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></circle><line x1="12" y1="0.75" x2="12" y2="4.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="12" y1="19.5" x2="12" y2="23.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="23.25" y1="12" x2="19.5" y2="12" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="4.5" y1="12" x2="0.75" y2="12" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="20.25" y1="3.75" x2="17.25" y2="6.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="6.75" y1="17.25" x2="3.75" y2="20.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="20.25" y1="20.25" x2="17.25" y2="17.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="6.75" y1="6.75" x2="3.75" y2="3.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line></svg>
                </button>

                {!sidebarOpen ?    
                    <button aria-label="sisukord" onMouseDown={(e)=>e.preventDefault()} onClick={()=>openSidebar('toc')} className = {`${theme === 'light'&&'text-[#0C1135]'} showTOC hover:text-[#6D53E4] font-medium tracking-wider flex items-center px-4 py-1 border-[1px] border-[#E2DDFA] hover:border-[#6D53E4] rounded-[64px]`}>     
                        <svg className="w-[17px] h-[16px]" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_342_1173)">
                            <path d="M1.77165 4.33333C2.69212 4.33333 3.43831 3.58714 3.43831 2.66667C3.43831 1.74619 2.69212 1 1.77165 1C0.851173 1 0.10498 1.74619 0.10498 2.66667C0.10498 3.58714 0.851173 4.33333 1.77165 4.33333Z" fill="currentColor"/>
                            <path d="M5.77165 3.33333H15.4383C15.6151 3.33333 15.7847 3.2631 15.9097 3.13807C16.0347 3.01305 16.105 2.84348 16.105 2.66667C16.105 2.48986 16.0347 2.32029 15.9097 2.19526C15.7847 2.07024 15.6151 2 15.4383 2H5.77165C5.59484 2 5.42527 2.07024 5.30024 2.19526C5.17522 2.32029 5.10498 2.48986 5.10498 2.66667C5.10498 2.84348 5.17522 3.01305 5.30024 3.13807C5.42527 3.2631 5.59484 3.33333 5.77165 3.33333Z" fill="currentColor"/>
                            <path d="M1.77165 9.66665C2.69212 9.66665 3.43831 8.92045 3.43831 7.99998C3.43831 7.07951 2.69212 6.33331 1.77165 6.33331C0.851173 6.33331 0.10498 7.07951 0.10498 7.99998C0.10498 8.92045 0.851173 9.66665 1.77165 9.66665Z" fill="currentColor"/>
                            <path d="M15.4383 7.33331H5.77165C5.59484 7.33331 5.42527 7.40355 5.30024 7.52858C5.17522 7.6536 5.10498 7.82317 5.10498 7.99998C5.10498 8.17679 5.17522 8.34636 5.30024 8.47138C5.42527 8.59641 5.59484 8.66665 5.77165 8.66665H15.4383C15.6151 8.66665 15.7847 8.59641 15.9097 8.47138C16.0347 8.34636 16.105 8.17679 16.105 7.99998C16.105 7.82317 16.0347 7.6536 15.9097 7.52858C15.7847 7.40355 15.6151 7.33331 15.4383 7.33331Z" fill="currentColor"/>
                            <path d="M1.77165 15C2.69212 15 3.43831 14.2538 3.43831 13.3334C3.43831 12.4129 2.69212 11.6667 1.77165 11.6667C0.851173 11.6667 0.10498 12.4129 0.10498 13.3334C0.10498 14.2538 0.851173 15 1.77165 15Z" fill="currentColor"/>
                            <path d="M15.4383 12.6667H5.77165C5.59484 12.6667 5.42527 12.7369 5.30024 12.8619C5.17522 12.987 5.10498 13.1565 5.10498 13.3334C5.10498 13.5102 5.17522 13.6797 5.30024 13.8048C5.42527 13.9298 5.59484 14 5.77165 14H15.4383C15.6151 14 15.7847 13.9298 15.9097 13.8048C16.0347 13.6797 16.105 13.5102 16.105 13.3334C16.105 13.1565 16.0347 12.987 15.9097 12.8619C15.7847 12.7369 15.6151 12.6667 15.4383 12.6667Z" fill="currentColor"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_342_1173">
                            <rect width="16" height="16" fill="white" transform="translate(0.10498)"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <p className="ml-3 text-[14px] leading-[20px] mt-[2px]">Sisukord</p>
                    </button>
                    :
                    <button aria-label="sisukord" onMouseDown={(e)=>e.preventDefault()} onClick={()=>closeSidebar('toc')} className = {`${theme === 'light'&&'text-[#0C1135]'} showTOC hover:text-[#6D53E4] font-medium tracking-wider flex items-center px-4 py-1 border-[1px] border-[#E2DDFA] hover:border-[#6D53E4] rounded-[64px]`}>     
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><g clipPath="url(#aclosetoc)"><path fill="currentColor" d="M2.3 13.7a1 1 0 0 0 1.5 0l4-4.2a.2.2 0 0 1 .3 0l4.1 4.2a1 1 0 0 0 1.5-1.5l-4.2-4a.2.2 0 0 1 0-.3l4.2-4.1a1 1 0 0 0-1.5-1.5l-4 4.2a.2.2 0 0 1-.3 0L3.8 2.3a1 1 0 1 0-1.5 1.5l4.2 4a.2.2 0 0 1 0 .3l-4.2 4.1a1 1 0 0 0 0 1.5Z"/></g><defs><clipPath id="aclosetoc"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>
                        <p className="ml-3 text-[14px] leading-[20px] mt-[2px]">Sisukord</p>
                    </button>
                }

                    
                    {/* <button onClick={()=>openSidebar('settings')} className={`text-[#0C1135] hover:text-[#6D53E4]`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><g clipPath="url(#a92)"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m15 2.6-2.3 2.2h-1.5V3.3L13.4 1A3.8 3.8 0 0 0 8 5.7l-7 6.9a1.7 1.7 0 1 0 2.4 2.3L10.3 8A3.8 3.8 0 0 0 15 2.6h0Z"/></g><defs><clipPath id="a92"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>
                    </button>

                     <button className={`text-[#938FA8] hover:text-[#6D53E4]`} onClick={() => openSidebar('bookmarks')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill='currentColor' className='w-[20px] h-[20px]' viewBox="0 0 384 512">
                            <path d="M384 48V512l-192-112L0 512V48C0 21.5 21.5 0 48 0h288C362.5 0 384 21.5 384 48z"/>
                        </svg>
                    </button> */}
                
            </div>

        }

            <Link aria-label="tagasi raamatu lehele" to={`/ebook/${id}/${slug}`} className="relative z-[100]">
                <Tooltip placement = {'bottom-start'} arrowcolor='#0c1135' 
                    content={
                        <div className="tooltip bg-[#0c1135] text-sm text-white max-w-[200px] p-2 rounded-[8px]">
                            Tagasi raamatu lehele
                        </div>
                    }>
                    <button className="fixed top-[5px] lg:top-[16px] 2xl:top-[16px] left-0 lg:left-[32px] flex justify-center items-center text-[#938FA8] hover:text-[#6D53E4] w-[64px] h-[64px] hover:bg-[#e2ddfa] rounded-[50%]">            
                        <svg className="w-[20px] h-[20px] md:w-[25px] md:h-[24px]" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.791248 23.3137C1.16632 23.6888 1.67503 23.8995 2.20546 23.8995C2.73589 23.8995 3.2446 23.6888 3.61967 23.3137L11.8693 15.0641C11.9318 15.0016 12.0166 14.9665 12.105 14.9665C12.1934 14.9665 12.2781 15.0016 12.3407 15.0641L20.5902 23.3137C20.9653 23.6888 21.474 23.8995 22.0045 23.8995C22.5349 23.8995 23.0436 23.6888 23.4187 23.3137C23.7937 22.9386 24.0045 22.4299 24.0045 21.8995C24.0045 21.3691 23.7937 20.8604 23.4187 20.4853L15.1691 12.2357C15.1066 12.1732 15.0715 12.0884 15.0715 12C15.0715 11.9116 15.1066 11.8268 15.1691 11.7643L23.4187 3.51472C23.7937 3.13965 24.0045 2.63094 24.0045 2.10051C24.0045 1.57007 23.7937 1.06136 23.4187 0.68629C23.0436 0.311218 22.5349 0.100504 22.0045 0.100504C21.474 0.100504 20.9653 0.311218 20.5902 0.68629L12.3407 8.93587C12.2781 8.99838 12.1934 9.0335 12.105 9.0335C12.0166 9.0335 11.9318 8.99838 11.8693 8.93587L3.61967 0.68629C3.2446 0.311218 2.73589 0.100504 2.20546 0.100504C1.67503 0.100504 1.16632 0.311218 0.791248 0.68629C0.416175 1.06136 0.205461 1.57007 0.205461 2.1005C0.205461 2.63094 0.416175 3.13965 0.791248 3.51472L9.04083 11.7643C9.10334 11.8268 9.13846 11.9116 9.13846 12C9.13846 12.0884 9.10334 12.1732 9.04083 12.2357L0.791248 20.4853C0.416175 20.8604 0.205461 21.3691 0.205461 21.8995C0.205461 22.4299 0.416175 22.9386 0.791248 23.3137V23.3137Z" fill="currentColor"/>
                        </svg>
                    </button>
                </Tooltip>

            </Link>

            <div className = 'h-[10%] flex flex-col items-center justify-center relative lg:flex-row mb-4 lg:mb-2 lg:justify-between lg:mx-[64px] lg:items-center'>
                {previewEpub && !bookLoading && currentProgress === 1 &&
                <>
                    <a href= {buyLink} className = {`absolute left-1/2 transform -translate-x-1/2 text-white top-[-64px] flex showTOC hover:opacity-80  font-medium tracking-wider items-center mt-2 mr-4 px-8 py-4 border-[2px] border-[#6D53E4] hover:border-[#6D53E4] rounded-[64px] bg-[#6D53E4]`}>     
                        <p className="text-[14px] leading-[20px] ">Laenuta 30 päevaks</p>
                    </a>
                </>
                }
                {currentPageNumber !== -1 && !bookLoading &&
                <>
                <div className = 'flex flex-col lg:flex-row lg:items-center'>
                    <div className="text-[#938FA8] h-[32px] items-center justify-between lg:justify-center flex w-[320px] sm:w-[370px] lg:w-auto"> 
                        <div className="w-[32px] block lg:hidden">

                            <button aria-label="eelmine leht" ref={prevButtonMobileRef} className={`${currentLocation?.atStart && 'hidden'} w-[32px] h-[32px] text-[#938FA8] flex justify-center items-center bg-[#e2ddfa] rounded-[50%] ${theme === 'dark' && 'text-[#6D53E4] bg-[#ffffff]'}`}  onClick={goToPrevPage}>      
                                    <svg className="w-[20px] h-[21px]" viewBox="0 0 33 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.1484 14.0343H7.33371C7.26622 14.0337 7.20051 14.0126 7.14519 13.974C7.08988 13.9353 7.04754 13.8808 7.02375 13.8176C6.99996 13.7545 6.99582 13.6856 7.01187 13.6201C7.02793 13.5545 7.06343 13.4953 7.11371 13.4503L18.6297 3.31699C18.9918 2.97564 19.2071 2.50708 19.2305 2.01004C19.2538 1.51301 19.0833 1.02632 18.7548 0.652561C18.4263 0.2788 17.9656 0.0471534 17.4697 0.00644165C16.9738 -0.0342701 16.4814 0.119133 16.0964 0.434321L1.18838 13.553C0.847878 13.8533 0.575173 14.2226 0.388379 14.6364C0.201586 15.0502 0.10498 15.499 0.10498 15.953C0.10498 16.407 0.201586 16.8558 0.388379 17.2696C0.575173 17.6834 0.847878 18.0527 1.18838 18.353L16.0937 31.469C16.479 31.7802 16.9698 31.9305 17.4633 31.8884C17.9568 31.8463 18.415 31.6151 18.7421 31.2431C19.0691 30.8711 19.2398 30.3871 19.2183 29.8923C19.1969 29.3974 18.985 28.93 18.627 28.5877L7.11105 18.4543C7.06076 18.4093 7.02526 18.3501 7.00921 18.2846C6.99315 18.219 6.99729 18.1502 7.02108 18.087C7.04488 18.0238 7.08721 17.9693 7.14253 17.9307C7.19784 17.892 7.26356 17.871 7.33105 17.8703H30.1484C30.6487 17.8571 31.124 17.6491 31.4732 17.2906C31.8224 16.9321 32.0178 16.4514 32.0178 15.951C32.0178 15.4505 31.8224 14.9699 31.4732 14.6113C31.124 14.2528 30.6487 14.0448 30.1484 14.0317V14.0343Z" fill="currentColor"/>
                                    </svg>
                            </button>
                        </div>

                        <span className={`${theme === 'dark' && 'text-[#ffffff]'}`}>{currentPageNumber === 0 ? 1 : currentPageNumber}/{bookRef.current&&bookRef.current.locations.total}</span>

                        <div className="w-[32px] block lg:hidden">
                            <button aria-label="järgmine leht" ref={nextButtonMobileRef} className={`${currentLocation?.atEnd && 'hidden'} w-[32px] h-[32px] text-[#938FA8] flex justify-center items-center bg-[#e2ddfa] rounded-[50%] ${theme === 'dark' && 'text-[#6D53E4] bg-[#ffffff]'}`} onClick={goToNextPage}>            
                                <svg className="w-[20px] h-[21px]" viewBox="0 0 33 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32.0716 15.953C32.0705 15.499 31.9731 15.0504 31.7861 14.6367C31.599 14.2231 31.3264 13.8537 30.9863 13.553L16.081 0.434321C15.696 0.119133 15.2036 -0.0342701 14.7077 0.00644165C14.2118 0.0471534 13.751 0.2788 13.4226 0.652561C13.0941 1.02632 12.9236 1.51301 12.9469 2.01004C12.9702 2.50708 13.1856 2.97564 13.5476 3.31699L25.0637 13.4503C25.1142 13.4951 25.1499 13.5542 25.166 13.6198C25.1822 13.6853 25.1781 13.7542 25.1543 13.8174C25.1304 13.8806 25.0879 13.935 25.0325 13.9735C24.977 14.012 24.9112 14.0327 24.8436 14.033H2.02498C1.51576 14.033 1.02741 14.2353 0.667335 14.5953C0.307266 14.9554 0.10498 15.4438 0.10498 15.953C0.10498 16.4622 0.307266 16.9506 0.667335 17.3106C1.02741 17.6707 1.51576 17.873 2.02498 17.873H24.8396C24.9071 17.8736 24.9729 17.8947 25.0282 17.9333C25.0835 17.972 25.1258 18.0265 25.1496 18.0897C25.1734 18.1528 25.1775 18.2217 25.1615 18.2873C25.1454 18.3528 25.1099 18.412 25.0596 18.457L13.5436 28.5903C13.3422 28.753 13.1758 28.9547 13.0544 29.1834C12.933 29.412 12.859 29.6629 12.837 29.9208C12.815 30.1788 12.8454 30.4385 12.9264 30.6844C13.0073 30.9303 13.1372 31.1573 13.3081 31.3517C13.4791 31.5462 13.6876 31.704 13.9211 31.8158C14.1546 31.9276 14.4083 31.991 14.667 32.0022C14.9256 32.0134 15.1839 31.9721 15.4262 31.881C15.6685 31.7898 15.8899 31.6506 16.077 31.4717L30.985 18.353C31.3255 18.0525 31.5984 17.6832 31.7857 17.2695C31.973 16.8558 32.0705 16.4071 32.0716 15.953V15.953Z" fill="currentColor"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="w-[320px] sm:w-[390px] h-[2px] bg-[#ECE9FC] mt-2 lg:mt-0 lg:ml-8">
                        <div className={`h-[100%] bg-[#938FA8] rounded-r-lg`} style={{width:`${currentProgress*100}%`}}></div>     
                    </div>
                </div>
                <div className="hidden lg:flex">
                    <Tooltip placement = {'top-end'} arrowcolor='#0c1135' 
                    content={
                        <div className="tooltip bg-[#0c1135] text-sm text-white max-w-[200px] p-2 rounded-[8px]">
                            Tume režiim
                        </div>
                    }>
                    <button onClick={()=>setTheme('dark')} className = {`${theme === 'dark' && 'hidden'} ml-4 darkThemeToggler text-[#938FA8] hover:text-[#6D53E4] font-medium tracking-wider flex items-center py-1`}>     
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M13.8 18.1a8.1 8.1 0 1 1 0-16.2.2.2 0 0 0 0-.4A9.3 9.3 0 0 0 3.4 3.4a9.4 9.4 0 0 0 10.4 15.1.2.2 0 0 0 0-.4v0ZM14.4 5.6v2.5M13.1 6.9h2.5M18.1 10.6v2.5M16.9 11.9h2.5M11.9 11.9v2.5M10.6 13.1h2.5"/></svg>
                    </button>
                    </Tooltip> 

                    <Tooltip placement = {'top-end'} arrowcolor='#ffffff' 
                    content={
                        <div className="tooltip bg-[#ffffff] text-[#0c1135] text-sm max-w-[200px] p-2 rounded-[8px]">
                            Hele režiim
                        </div>
                    }>
                        <button onClick={()=>setTheme('light')} className = {`${theme === 'light' && 'hidden'} ml-4 lightThemeToggler text-[#ffffff] hover:text-[#6D53E4] font-medium tracking-wider flex items-center py-1`}>     
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20"><defs></defs><title>light-mode-sunny</title><circle cx="12" cy="12" r="4.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></circle><line x1="12" y1="0.75" x2="12" y2="4.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="12" y1="19.5" x2="12" y2="23.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="23.25" y1="12" x2="19.5" y2="12" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="4.5" y1="12" x2="0.75" y2="12" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="20.25" y1="3.75" x2="17.25" y2="6.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="6.75" y1="17.25" x2="3.75" y2="20.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="20.25" y1="20.25" x2="17.25" y2="17.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line><line x1="6.75" y1="6.75" x2="3.75" y2="3.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px"></line></svg>
                        </button>
                    </Tooltip>

                    {/* <button className={`text-[#938FA8] hover:text-[#6D53E4]`} onClick={() => setFontSize(30)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill='currentColor' className='w-[20px] h-[20px]' viewBox="0 0 384 512">
                            <path d="M384 48V512l-192-112L0 512V48C0 21.5 21.5 0 48 0h288C362.5 0 384 21.5 384 48z"/>
                        </svg>
                    </button> */}

                    <Tooltip placement = {'top-end'} arrowcolor='#0c1135' 
                    content={
                        <div className="tooltip bg-[#0c1135] text-sm text-white max-w-[200px] p-2 rounded-[8px]">
                            Täisekraani vaade
                        </div>
                    }>
                        <button aria-label="täisekraani vaade" onClick={()=>toggleFullScreen()} className = {`${theme==='light'&&'text-[#938FA8]'} ${isFullScreen&&'rotate-180'} ml-4 hover:text-[#6D53E4] font-medium tracking-wider hidden md:flex items-center py-1`}>     
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#fs_epub)"><path d="M19.4 6.3V.5h-5.6M19.4.6l-7.5 7.5M8.1 10.6H2c-.7 0-1.3.6-1.3 1.3V18c0 .7.6 1.3 1.3 1.3H8c.7 0 1.3-.6 1.3-1.3V12c0-.7-.6-1.3-1.3-1.3Z"/></g><defs><clipPath id="fs_epub"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>
                        </button>
                    </Tooltip> 
                    
                </div>
                </>
                }
            </div>

        </div>


      </div>
    </>
    );
  }
  
export default CustomEpub;          