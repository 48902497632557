
import List from "../../layouts/List/List";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import Footer from '../../components/Footer/Footer';

const ListPage = () => {
    return(
        <>
            <ScrollToTopOnMount />
            <List />
            <Footer />
        </>
    )
}

export default ListPage;