import Topic from "../../layouts/Topic/Topic";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import Footer from '../../components/Footer/Footer';

const TopicPage = () => {
    return(
        <>
            <ScrollToTopOnMount />
            <Topic />
            <Footer />
        </>
       
    )
}

export default TopicPage;