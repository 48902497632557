
const CrossIcon = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_387_1196)">
            <path d="M2.44836 13.6569C2.63589 13.8444 2.89025 13.9497 3.15547 13.9497C3.42068 13.9497 3.67504 13.8444 3.86257 13.6569L7.98736 9.53206C8.01862 9.50081 8.06101 9.48325 8.10521 9.48325C8.14942 9.48325 8.19181 9.50081 8.22306 9.53206L12.3479 13.6569C12.5354 13.8444 12.7897 13.9497 13.055 13.9497C13.3202 13.9497 13.5745 13.8444 13.7621 13.6569C13.9496 13.4693 14.055 13.215 14.055 12.9497C14.055 12.6845 13.9496 12.4302 13.7621 12.2426L9.63728 8.11785C9.60602 8.0866 9.58846 8.0442 9.58846 8C9.58846 7.9558 9.60602 7.9134 9.63728 7.88215L13.7621 3.75736C13.9496 3.56982 14.055 3.31547 14.055 3.05025C14.055 2.78504 13.9496 2.53068 13.7621 2.34315C13.5745 2.15561 13.3202 2.05025 13.055 2.05025C12.7897 2.05025 12.5354 2.15561 12.3479 2.34315L8.22306 6.46794C8.19181 6.49919 8.14942 6.51675 8.10521 6.51675C8.06101 6.51675 8.01862 6.49919 7.98736 6.46794L3.86257 2.34315C3.67504 2.15561 3.42068 2.05025 3.15547 2.05025C2.89025 2.05025 2.63589 2.15561 2.44836 2.34315C2.26082 2.53068 2.15546 2.78504 2.15546 3.05025C2.15546 3.31547 2.26082 3.56982 2.44836 3.75736L6.57315 7.88215C6.6044 7.91341 6.62196 7.9558 6.62196 8C6.62196 8.0442 6.6044 8.08659 6.57315 8.11785L2.44836 12.2426C2.26082 12.4302 2.15546 12.6845 2.15546 12.9497C2.15546 13.215 2.26082 13.4693 2.44836 13.6569V13.6569Z" fill="currentColor"/>
            </g>
            <defs>
            <clipPath id="clip0_387_1196">
            <rect width="16" height="16" fill="white" transform="translate(0.10498)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default CrossIcon;