const CheckIcon = ({className}) => {
    return(        
    <svg className={className} viewBox="0 0 13 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_387_931)">
    <path d="M11.68 2.69998L10.28 1.29998C10.2332 1.25417 10.1704 1.22852 10.105 1.22852C10.0395 1.22852 9.97669 1.25417 9.92996 1.29998L4.02996 7.19998C3.98323 7.24579 3.9204 7.27144 3.85496 7.27144C3.78952 7.27144 3.72669 7.24579 3.67996 7.19998L2.27996 5.79998C2.23323 5.75417 2.1704 5.72852 2.10496 5.72852C2.03952 5.72852 1.97669 5.75417 1.92996 5.79998L0.52996 7.19998C0.484154 7.24671 0.458496 7.30954 0.458496 7.37498C0.458496 7.44042 0.484154 7.50325 0.52996 7.54998L3.67996 10.7C3.72669 10.7458 3.78952 10.7714 3.85496 10.7714C3.9204 10.7714 3.98323 10.7458 4.02996 10.7L11.68 3.04998C11.7258 3.00325 11.7514 2.94042 11.7514 2.87498C11.7514 2.80954 11.7258 2.74671 11.68 2.69998Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_387_931">
    <rect width="12" height="12" fill="white" transform="translate(0.10498)"/>
    </clipPath>
    </defs>
    </svg>
    )
}

export default CheckIcon;