import '../CardsLoading/CardsLoading.css';

const TypesLoading = ({className, n}) => {

    const generateCards = () => {
        const cards = [];
        for(let i = 0; i < n; i++){
            cards.push(
                <div key={i} className={`card_loading min-w-[150px] md:min-w-[176px] p-2 md:p-4 mr-3 mb-3 flex justify-center items-center rounded-[8px] leading-6 tracking-[0.05em] shadow-shadow0 ${className}`}>
                    <p className={`shimmerBG w-[85%] h-[20px] text-[12px] sm:text-[14px] font-[500] mr-2 leading-[5px] text-inherit`}></p>
                    <p className={`shimmerBG w-[15%] h-[20px] text-[12px] font-[500] px-[6px] py-1 rounded-[24px] text-white leading-3 tracking-[0.05em] w-[42px]`}></p>   
                </div>
            );
        }
        return cards;
    }
   
    return (
        <div className='flex flex-wrap'>
            {generateCards()}
        </div>
    );
}

export default TypesLoading;
 
 