import { useContext } from "react";
import {Helmet} from 'react-helmet-async';
import Banners from "./Banners/Banners";
import Cover from "./Cover/Cover";
import Subjects from "./Subjects/Subjects";
import Header from '../../components/Header/Header';
import { motion } from "framer-motion";
import Topbar from "../../components/Topbar/Topbar";
import LanguageContext from '../../context/LanguageContext/LanguageContext';
import DataContext from "../../context/DataContext";

const containerVariant = {
    hidden: { 
    },
    visible: {
    },
    exit: {
        background: '#f5f7ff',
    },
}
const moveUp = {
    hidden: {
        opacity: 1,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {duration: 2},
    },
    exit: {
        y: "-30%",
        opacity: 0,
    },
}
const navbartranstion = {
    exit: {
        background: '#f5f7ff',
        color: '#0C1135',
    }
}


const Home = () => {

    const {langContent} = useContext(LanguageContext);
    const {settings} = useContext(DataContext);

    return(
        <>
            <Helmet>
                <title>{langContent.home.title?langContent.home.title:'TaskuTark on õppimisel abiks.'}</title>
            </Helmet>

            {settings.showTopGreenBar === true && <>
                <div className="bg-[#28BF73]">
                    <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                        <Topbar link= {settings.showTopGreenBar_link} text = {settings.showTopGreenBar_label} />
                    </div>
                </div>
            </>}

            <div className="relative">
                <motion.div className="absolute z-[99] w-full navbar_container bg-[#0c1135] lg:bg-transparent text-[#EBEEFE]"
                    variants={navbartranstion} exit="exit"> 
                    <div className="max-w-[1504px] mx-auto w-full">
                        <Header type = 'Home' className='w-full sm:py-3 mx-auto px-[3vw] lg:px-[32px] xl:px-[32px]' />
                    </div>
                </motion.div>
                <motion.div className="bg-[#0C1135] text-[#0C1135] home_cover" 
                    variants={containerVariant} initial="hidden" animate="visible" exit="exit"> 
                    <div className="max-w-[1504px] mx-auto w-full">
                        <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                            <Cover />
                        </div>
                    </div>
                </motion.div>
            </div>
            <div className="max-w-[1504px] mx-auto w-full">
                <div className="mx-auto px-[3vw] lg:px-[32px] xl:px-[32px] w-full">
                    <motion.div variants={moveUp} initial="hidden" animate="visible" exit="exit">
                        <Subjects />
                    </motion.div>
                    <Banners />
                </div>
            </div>
        </>
    )
}

export default Home;