
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import useQuery from '../../hooks/useQuery';
import { useNavigate } from 'react-router-dom';

const PaginationComponent = ({totalPages}) => {

    const query = useQuery();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(query.get('page') ? parseInt(query.get('page')) : 1);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'auto' })
        }, 500)
    }, [query])


    const changeCurrentPage = (newpage) => {
        setCurrentPage(newpage);
        query.set('page', newpage);
        navigate(`/search?${query.toString()}`, {state: 'dontloadtypecounts'});
    }

    return (
        <div className="w-full col-span-2 sm:col-span-4">
             <Pagination
                activePage={currentPage}
                itemsCountPerPage={1}
                prevPageText={
                    <svg width="17" height="12" className='rotate-90 mt-[5px]' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                }
                firstPageText={
                    <div className='flex'>
                        <svg width="17" height="17" className='rotate-90 mt-[3px]' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <svg width="17" height="17" className='rotate-90 mt-[3px]' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                }
                nextPageText={
                    <svg width="17" height="12" className='rotate-[-90deg] mt-[5px]' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                }
                lastPageText={
                    <div className='flex'>
                        <svg width="17" height="17" className='rotate-[-90deg] mt-[3px]' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <svg width="17" height="17" className='rotate-[-90deg] mt-[3px]' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3335 5.16666L8.50446 11.9957C8.46014 12.0401 8.40751 12.0753 8.34958 12.0993C8.29165 12.1233 8.22955 12.1357 8.16683 12.1357C8.10411 12.1357 8.04201 12.1233 7.98408 12.0993C7.92614 12.0753 7.87352 12.0401 7.8292 11.9957L1.00016 5.16666" stroke="#0C1135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                }
                totalItemsCount={totalPages}
                pageRangeDisplayed={3}
                onChange={changeCurrentPage}
                innerClass = "pagination_ul flex mt-10 justify-center"
                itemClass='pagination_li bg-white px-4 py-4 cursor-pointer hover:bg-[#EBEEFE] text-center w-[56px] color-[#6D53E4] font-medium'
                activeClass='active bg-primary text-white border-primary hover:bg-primary'
                />
            
        </div>
    )
}


export default PaginationComponent;