import React, { useEffect, useState } from "react";
import API from "../../API/API";

import LanguageContext from "./LanguageContext";


function LanguageContextProvider(props) {
  const [langContent, setLangContent] = useState({"header" : {}, "footer" : {}, "home" : {}, "list": {"moreFilters": ""}, "resultItem": {}, "search": {}});
  const [language, setLanguage] = useState('et');
  
  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
    setLanguage(lang);
    API.getTranslation(lang)
    .then(res => setLangContent(res.data))
    .catch(err => console.log(err));
  } 

  useEffect(() => {
    const lang = localStorage.getItem('language');
    if(lang !== null){
      changeLanguage(lang);
    }
  }, []);


  return (
    <LanguageContext.Provider
      value={{
        language,
        langContent,
        changeLanguage
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
}

export default LanguageContextProvider;
