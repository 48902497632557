

import { Link } from 'react-router-dom';
import './BannerCard.css';

const BannerCard = ({banner}) => {
    return(
        <>
            {banner.linkType === 'internal' ? (
                <Link to = {banner.link} rel="noreferrer" className={`banner_card w-full w-[94vw] h-[80vw] sm:w-[350px] sm:h-[350px] md:w-[40vw] md:h-[40vw] lg:h-[403px] 
                    p-5 sm:p-9 flex flex-col justify-between text-white rounded-[8px] ${banner.overlay && 'overlay'}`}
                    style={{backgroundImage: `url(${banner.image})`}}
                >
                    <div>
                        <h2 className="font-[500] text-[24px] md:text-[28px] leading-tight mb-4">{banner.title}</h2>
                        <p className="text-sm md:text-base">{banner.text}</p>
                    </div>
                    <div className = 'banner_card_link bg-white w-12 h-12 rounded-[50%] flex justify-center items-center'>                
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1222 3.33331L9.85689 4.5902L12.6018 7.33509H0V9.10931H12.6018L9.85689 11.8542L11.1222 13.1111L16 8.2222L11.1222 3.33331Z" fill="#6D53E4"/>
                        </svg>
                    </div>

                </Link>
            ):(
                <a href = {banner.link} target={banner.new_window ? '_blank' : '_self'} rel="noreferrer" className={`banner_card w-full w-[94vw] h-[80vw] sm:w-[350px] sm:h-[350px] md:w-[40vw] md:h-[40vw] lg:h-[403px] p-5 sm:p-9 flex flex-col justify-between text-white rounded-[8px] ${banner.overlay && 'overlay'}`}
                    style={{backgroundImage: `url(${banner.image})`}}
                >
                    <div>
                        <h2 className="font-[500] text-[24px] md:text-[28px] leading-tight mb-4">{banner.title}</h2>
                        <p className="text-sm md:text-base">{banner.text}</p>
                    </div>
                    <div className = 'banner_card_link bg-white w-12 h-12 rounded-[50%] flex justify-center items-center'>                
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1222 3.33331L9.85689 4.5902L12.6018 7.33509H0V9.10931H12.6018L9.85689 11.8542L11.1222 13.1111L16 8.2222L11.1222 3.33331Z" fill="#6D53E4"/>
                        </svg>
                    </div>

                </a>
            )}
        </>
    )
}

export default BannerCard;